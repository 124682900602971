import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../theme';
import { MediaQuery, formatPrice } from '../helpers';
import { Col, Row, Visible } from 'react-grid-system';
import { WarningIcon } from '../atoms/icons/WarningIcon';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/Spacers';
import Body from '../atoms/typography/Body';
import TextLinkButton from '../atoms/buttons/TextLinkButton';
import { CheckmarkIcon } from '../atoms/icons/CheckmarkIcon';
import { CrossIcon } from '../atoms/icons/CrossIcon';
import BodySmall from '../atoms/typography/BodySmall';
import dayjs from 'dayjs';

const WarningContainer = styled.div`
    padding: 8px;
    border-radius: 4px;
    border: 2px dashed ${theme.colors.error};
`;

const SummaryContainer = styled.div`
    padding: 8px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.white};

    ${MediaQuery.XS} {
        width: 100%;
    }
`;

const SettlementsSummary = ({ settlements, filteredSettlements, selectedDate, onSettlementClick }) => {
    const failedSettlements = settlements.filter((settlement) => settlement.exportStatus === 'EXPORT_FAILED');

    return (
        <div>
            <Row justify="between" nogutter>
                <Col lg={6} xs={12}>
                    {failedSettlements.length > 0 && (
                        <WarningContainer>
                            <Row nogutter align="center">
                                <WarningIcon color={theme.colors.error} />
                                <VerticalSpacer spacing={0.5} />
                                <Body color="white">Vissa fakturor kunde inte bokföras automatiskt</Body>
                            </Row>

                            <HorizontalSpacer spacing={0.5} />

                            <BodySmall color="white">
                                Du har aktiverat automatisk bokföring men vi har upptäckt att några av dina fakturor inte kunde bokföras. Du ser vilka fakturor
                                det gäller och kan korrigerar dem genom att använda datum-menyn ovan eller klicka på snabblänken nedan.
                            </BodySmall>

                            <HorizontalSpacer spacing={0.5} />
                            <Row nogutter>
                                {failedSettlements.map((settlement, index) => (
                                    <>
                                        <TextLinkButton
                                            key={index}
                                            color="white"
                                            title={dayjs(settlement.createdAt).format('YYYY-MM-DD')}
                                            onClick={() => onSettlementClick(settlement)}
                                        />
                                        <VerticalSpacer spacing={1} />
                                    </>
                                ))}
                            </Row>
                        </WarningContainer>
                    )}
                    <Visible xs sm md>
                        <HorizontalSpacer spacing={1} />
                    </Visible>
                </Col>

                {filteredSettlements.length > 0 && (
                    <div>
                        <SummaryContainer>
                            <Body color="white" fontweight={700}>
                                Sammanställning {selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : ''}
                            </Body>
                            <HorizontalSpacer spacing={0.5} />
                            {filteredSettlements.map((settlement, index) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {settlement.exportStatus !== 'EXPORT_FAILED' ? (
                                        <CheckmarkIcon color={theme.colors.success} />
                                    ) : settlement.exportStatus === 'EXPORT_FAILED' ? (
                                        <CrossIcon color={theme.colors.error} />
                                    ) : null}
                                    <BodySmall color="white">
                                        {`Avräkning ${index + 1}`} - {formatPrice(settlement.payoutAmount)} kr
                                    </BodySmall>
                                </div>
                            ))}
                            <HorizontalSpacer spacing={0.5} />
                            <Body color="white">
                                <b>Totalt: {formatPrice(filteredSettlements.reduce((acc, settlement) => acc + settlement.payoutAmount, 0))} kr</b>
                            </Body>
                        </SummaryContainer>
                    </div>
                )}
            </Row>
        </div>
    );
};

export default SettlementsSummary;
