import * as React from 'react';

export const AutobookIcon = ({ color = '#0F5156' }) => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 6.98926V20.9893" stroke={color} stroke-width="1.5" stroke-linecap="round" />
        <path
            d="M5.98056 4.2747C9.32175 4.91086 11.3131 6.24157 12 7.00554C12.6869 6.24157 14.6782 4.91086 18.0194 4.2747C19.7121 3.95241 20.5584 3.79127 21.2792 4.4089C22 5.02653 22 6.02948 22 8.03538V15.2443C22 17.0784 22 17.9954 21.5374 18.568C21.0748 19.1405 20.0564 19.3344 18.0194 19.7223C16.2037 20.068 14.7866 20.6188 13.7608 21.1724C12.7516 21.717 12.247 21.9893 12 21.9893C11.753 21.9893 11.2484 21.717 10.2392 21.1724C9.21344 20.6188 7.79633 20.068 5.98056 19.7223C3.94365 19.3344 2.9252 19.1405 2.4626 18.568C2 17.9954 2 17.0784 2 15.2443V8.03538C2 6.02948 2 5.02653 2.72078 4.4089C3.44157 3.79127 4.2879 3.95241 5.98056 4.2747Z"
            stroke={color}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
