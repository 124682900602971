import * as React from 'react';
import { theme } from '../../theme';

export const DownloadIcon = ({ color = theme.colors.text }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.00195 13.3334H12.002" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
        <path d="M8.00195 10.6666V2.66663" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M10.6686 8C10.6686 8 8.7046 10.6667 8.00193 10.6667C7.2992 10.6667 5.33527 8 5.33527 8"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
