import { useQuery, gql } from '@apollo/client';

const GET_DEFAULT_ACCOUNT_PLAN = gql`
    query getDefaultAccountPlan {
        defaultAccountPlan {
            accountsReceivable
            businessAccount
            clearingAccountFactoring
            clearingAccountPledgedReceivable
            factoringCharges
            inputVat
            interestExpenseToCreditInstitutions
            otherCurrentLiabilities
            otherExternalServices
            pledgedReceivable
            receiptSeries
            roundingOff
            suspenseAccount
        }
    }
`;

export default GET_DEFAULT_ACCOUNT_PLAN;
