import * as React from 'react';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import styled from 'styled-components';
import Body from '../atoms/typography/Body';
import Title from '../atoms/typography/Title';
import { HorizontalSpacer } from '../atoms/Spacers';
import GreenSmokeImage from '../img/loginBG.jpg';
import { MediaQuery } from '../helpers';
import Subtitle from '../atoms/typography/Subtitle';

const BackgroundContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 32px;
    box-sizing: border-box;
    background-image: url(${GreenSmokeImage});
    border-radius: 12px;
`;

const StyledTitle = styled(Title)`
    font-size: 60;
    color: white;

    ${MediaQuery.XS} {
        font-size: 24px;
    }
`;

const FgBookBanner = () => {
    return (
        <>
            <BackgroundContainer>
                <div>
                    <Hidden xs>
                        <Subtitle color="#C7D6D4">Nyhet!</Subtitle>
                    </Hidden>
                    <Visible xs>
                        <Body color="#C7D6D4">Nyhet!</Body>
                    </Visible>
                    <StyledTitle>Anpassa din bokföring</StyledTitle>
                    <HorizontalSpacer spacing={1} />
                    <Body color="white">Nu kan du göra egna inställningar för kontoplanen när du laddar ner SIE-filen eller kör Automatisk Bokföring!</Body>
                </div>
            </BackgroundContainer>
        </>
    );
};

export default FgBookBanner;
