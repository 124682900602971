import * as React from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon } from '../icons/Chevrons';
import { theme } from '../../theme';

const ButtonContainer = styled.button`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${theme.colors.info20};
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        filter: opacity(0.6);
        box-shadow: ${theme.shadow.cardShadow};
    }

    &:active {
        box-shadow: none;
    }
`;

const BackButton = ({ onClick, disabled }) => {
    return (
        <ButtonContainer onClick={onClick} disabled={disabled}>
            <ChevronLeftIcon />
        </ButtonContainer>
    );
};

export default BackButton;
