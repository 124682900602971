import * as React from 'react';
import { theme } from '../../theme';

export const ChevronDownIcon = ({ color = '#4D4D4D' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 9C18 9 13.5811 15 12 15C10.4188 15 6 9 6 9" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export const ChevronUpIcon = ({ color = '#4D4D4D' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 15C6 15 10.4189 9 12 9C13.5812 9 18 15 18 15" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export const ChevronRightIcon = ({ color = theme.colors.text }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 6C9 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
export const ChevronLeftIcon = ({ color = theme.colors.text }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.002 18C15.002 18 9.00195 13.5811 9.00195 12C9.00195 10.4188 15.002 6 15.002 6"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
