import * as React from 'react';
import { theme } from '../../theme';

export const ActivateIcon = ({ color = theme.colors.text }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.6665 8C12.6665 9.1046 11.7711 10 10.6665 10C9.5619 10 8.6665 9.1046 8.6665 8C8.6665 6.8954 9.5619 6 10.6665 6C11.7711 6 12.6665 6.8954 12.6665 8Z"
            stroke={color}
            strokeWidth="1.5"
        />
        <path
            d="M10.6668 4H5.3335C3.12436 4 1.3335 5.79086 1.3335 8C1.3335 10.2091 3.12436 12 5.3335 12H10.6668C12.876 12 14.6668 10.2091 14.6668 8C14.6668 5.79086 12.876 4 10.6668 4Z"
            stroke={color}
            strokeWidth="1.5"
        />
    </svg>
);
