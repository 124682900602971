import { useMutation } from '@apollo/client';
import { RESYNC_FAILED_SETTLEMENT } from '../mutations/resyncSettlementReadyForBookKeepingWebhook';
import GET_SETTLEMENT_OVERVIEW from '../queries/getSettlementOverview';

export const useResyncFailedSettlement = ({ onCompleted, onError }) => {
    const [resyncFailedSettlement, { data, loading, error }] = useMutation(RESYNC_FAILED_SETTLEMENT, {
        onCompleted: onCompleted,
        onError: onError,
        refetchQueries: [GET_SETTLEMENT_OVERVIEW],
        awaitRefetchQueries: true,
    });

    return { resyncFailedSettlement, data, loading, error };
};
