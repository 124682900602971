import * as React from 'react';
import styled from 'styled-components';
import BodySmall from '../typography/BodySmall';
import { HorizontalSpacer, VerticalSpacer } from '../Spacers';
import Body from '../typography/Body';
import { theme } from '../../theme';

const TextFieldStyle = styled.input`
    width: ${(props) => (props.small ? '100%' : '100%')};

    font-family: Inter;
    color: ${(props) => theme.colors.text};
    background-color: white;
    font-size: ${(props) => (props.small ? '14px' : '16px')};
    outline: none;
    background-color: none;
    border: none;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #a1acbc;
    }
`;

const TextFieldContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: white;

    padding: 4px;
    border: ${(props) => (props.error ? '1px solid red' : props.small ? undefined : '1px solid #a1acbc')};
    border-bottom: ${(props) => (props.small ? '1px solid #a1acbc' : undefined)};
    border-radius: ${(props) => (props.small ? '0px' : '4px')};

    &:focus {
        border: ${(props) => (props.small ? undefined : '1px solid #6a7789')};
        color: #6a7789;
        outline: none;
    }
`;

const TextField = ({
    label,
    value,
    placeholder,
    errorMessage,
    onChange,
    disabled,
    defaultValue,
    small,
    type = 'text',
    minValue,
    maxValue,
    icon,
    color = theme.colors.text,
}) => {
    const hasError = !!errorMessage;

    return (
        <div>
            <BodySmall color={disabled ? '#6A7789' : color}>{label}</BodySmall>
            <HorizontalSpacer spacing={0.25} />
            {disabled ? (
                <Body color={color}>{value ?? defaultValue}</Body>
            ) : (
                <TextFieldContainer error={hasError} small={small}>
                    {icon}
                    {icon && <VerticalSpacer spacing={0.5} />}
                    <TextFieldStyle
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                        error={hasError}
                        small={small}
                        type="text"
                        inputMode="text"
                        pattern={type === 'date' ? '\\d{4}-\\d{2}-\\d{2}' : type === 'decimal' ? '-?\\d+(\\.\\d+)?' : type === 'integer' ? '-?\\d+' : undefined}
                        title={
                            type === 'date'
                                ? 'Datum format: ÅÅÅÅ-MM-DD'
                                : type === 'decimal'
                                ? 'Felaktigt format, använd punkt som avskiljare: 10.40'
                                : type === 'integer'
                                ? 'Felaktigt format, använd heltal: 10'
                                : undefined
                        }
                        min={minValue}
                        max={maxValue}
                    />
                </TextFieldContainer>
            )}
            {errorMessage && <BodySmall color="#E9033A">{errorMessage}</BodySmall>}
        </div>
    );
};

export default TextField;
