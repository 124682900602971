import React, { useState, useEffect, useMemo, useRef } from 'react';
import Settlement from '../../organisms/Settlement';
import SettlementsSummary from '../../molecules/SettlementsSummary';
import { HorizontalSpacer, VerticalSpacer } from '../../atoms/Spacers';
import { useSettlementOverview } from '../../graphql/hooks/useSettlementOverview';
import { useClientIssuerSettings } from '../../graphql/hooks/useClientIssuerSettings';
import SettlementsDateFilter from '../../organisms/SettlementsDateFilter';
import EmptyStateSettlement from '../../organisms/EmptyStateSettlement';
import Spinner from '../../global/Spinner';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import FAQQuestion from '../../molecules/FAQQuestion';
import styled from 'styled-components';
import BodySmall from '../../atoms/typography/BodySmall';
import { Row } from 'react-grid-system';
import { PhoneIcon } from '../../atoms/icons/PhoneIcon';
import Body from '../../atoms/typography/Body';
import { MailIcon } from '../../atoms/icons/MailIcon';

dayjs.locale('sv');

const ContactBox = styled.div`
    background: linear-gradient(181.89deg, rgba(255, 255, 255, 0.7) 27.48%, #fbfbfb 73.26%);
    box-shadow: 2px 4px 8px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    z-index: 10;
    padding: 1rem;
`;

const Settlements = () => {
    const commitment = JSON.parse(localStorage.getItem('commitment') ?? '{}');
    const clientIdentifier = commitment.organisationNumber ?? '';

    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [selectedDate, setSelectedDate] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const isInitialLoad = useRef(true);

    const createdFrom = selectedMonth.startOf('month').toDate();
    const createdTo = selectedMonth.endOf('month').toDate();

    const { clientSettings, loading: clientLoading, error: clientError } = useClientIssuerSettings(clientIdentifier);

    const isActive = clientSettings?.settlementAutoBookActive ?? false;

    const {
        settlementOverview,
        loading: settlementsLoading,
        error: settlementsError,
        refetch,
    } = useSettlementOverview(clientIdentifier, createdFrom, createdTo);

    const settlementItems = settlementOverview?.items ?? [];

    const latestSettlementDate = useMemo(
        () => (settlementItems.length ? dayjs(Math.max(...settlementItems.map((settlement) => dayjs(settlement.createdAt).valueOf()))) : null),
        [settlementItems]
    );

    useEffect(() => {
        if (!settlementsLoading && selectedDate === null) {
            if (settlementItems.length === 0) {
                setSelectedDate(dayjs());
            } else if (latestSettlementDate) {
                setSelectedDate(latestSettlementDate);
            }
        }
    }, [latestSettlementDate, selectedDate, settlementItems, settlementsLoading]);

    const filteredSettlements = useMemo(() => {
        if (!selectedDate || !settlementItems.length) return settlementItems;
        return settlementItems.filter((settlement) => dayjs(settlement.createdAt).isSame(selectedDate, 'day'));
    }, [selectedDate, settlementItems]);

    useEffect(() => {
        let timeout;

        if (!isInitialLoad.current) {
            if (clientLoading || settlementsLoading) {
                timeout = setTimeout(() => setShowSpinner(true), 300);
            } else {
                setShowSpinner(false);
            }
        } else {
            setShowSpinner(true);
            isInitialLoad.current = false;
        }

        return () => clearTimeout(timeout);
    }, [clientLoading, settlementsLoading]);

    useEffect(() => {
        refetch();
    }, [createdFrom, createdTo, refetch]);

    const handleDateChange = (date) => {
        setSelectedDate(dayjs(date));
        setSelectedMonth(dayjs(date));
    };

    const handleMonthChange = (month) => {
        setSelectedMonth(dayjs(month));
    };

    const handleSettlementClick = (settlement) => {
        setSelectedDate(dayjs(settlement.createdAt));
        setSelectedMonth(dayjs(settlement.createdAt));
    };

    if (settlementsError || clientError) {
        return <div>Kunde inte hämta avräkningar: {settlementsError.message || clientError.message || 'Unknown error'}</div>;
    }

    return (
        <div>
            <SettlementsDateFilter
                settlements={settlementItems}
                selectedMonth={selectedMonth}
                selectedDate={selectedDate}
                onDateChange={handleDateChange}
                onMonthChange={handleMonthChange}
            />
            {!settlementsLoading && !clientLoading && settlementItems.length > 0 && (
                <SettlementsSummary
                    settlements={settlementItems}
                    filteredSettlements={filteredSettlements}
                    selectedDate={selectedDate}
                    onSettlementClick={handleSettlementClick}
                />
            )}
            <HorizontalSpacer spacing={2} />

            {showSpinner ? (
                <div style={{ height: '400px', position: 'relative' }}>
                    <Spinner />
                </div>
            ) : filteredSettlements.length > 0 ? (
                filteredSettlements.map((settlement, index) => (
                    <Settlement key={settlement.id} settlement={settlement} autobookActivated={isActive} settlementName={`Avräkning ${index + 1}`} />
                ))
            ) : (
                <EmptyStateSettlement />
            )}

            <HorizontalSpacer spacing={2} />

            <FAQQuestion
                question="Vad är en SIE-fil?"
                answer="En SIE-fil innehåller information om varje kostnadspost i de avräkningar (kvitton) som du får ifrån oss i samband med att vi finansierar dina fakturor. SIE-formatet bygger på den svenska BAS-kontoplanen och är ett sätt att flytta informationen mellan olika program. Syftet med detta är att minska det administrativa arbetet med att bokföra tjänsten."
            />
            <HorizontalSpacer spacing={1} />

            <FAQQuestion
                question="Hur använder jag SIE-filen?"
                answer={
                    <>
                        1. Ladda ner den SIE-fil du vill använda. SIE-filerna för respektive avräkning finns att hämta under ”Avräkningar” när du är inloggad på
                        mitt.factoringgruppen.se.
                        <br />
                        <br />
                        2. Importera filen direkt till ditt affärssystem.
                        <br />
                        <br />
                        3. Kontrollera att alla avräkningsposter kontoförts korrekt. Oplacerade poster hamnar på ett konto som heter 2999. När poster har hamnat
                        på konto 2999 rekommenderar vi att du justerar bokföringsposten så att posterna passar till din kontoplan.
                    </>
                }
            />
            <HorizontalSpacer spacing={1} />

            <FAQQuestion question="Hur ändrar jag kontoinställningarna?" answer="Du kan ändra dina kontoinställningar under Inställningar i menyn." />
            <HorizontalSpacer spacing={1} />

            <FAQQuestion
                question="Jag ser inga avräkningar?"
                answer="Just nu finns det inga avräkningar att visa. Om du nyligen sålt fakturor till oss och saknar avräkningar så är du välkommen att höra av dig till oss så hjälper vi dig. Du når oss på 031-788 28 55 eller onboarding@fg.se."
            />
            <HorizontalSpacer spacing={1} />

            <FAQQuestion
                question="Vad är en avräkning?"
                answer="En avräkning fungerar som ett kvitto av er fakturaförsäljning och används som ett underlag för er bokföring. I avräkningen återfinns vilka fakturor som försäljningen avser, utbetalningsbeloppet, eventuella avgifter och tillhörande moms."
            />

            <HorizontalSpacer spacing={3} />
            <div style={{ width: 300 }}>
                <ContactBox>
                    <BodySmall fontWeight={700} color="black">
                        Behöver du support?
                    </BodySmall>
                    <HorizontalSpacer spacing={1} />
                    <a href="tel:0317882855">
                        <Row nogutter align="center">
                            <PhoneIcon />
                            <VerticalSpacer spacing={0.5} />
                            <Body>031 788 28 55</Body>
                        </Row>
                    </a>
                    <HorizontalSpacer spacing={1} />
                    <a href="mailto:onboarding@fg.se">
                        <Row nogutter align="center">
                            <MailIcon />
                            <VerticalSpacer spacing={0.5} />
                            <Body>onboarding@fg.se</Body>
                        </Row>
                    </a>
                </ContactBox>
            </div>
        </div>
    );
};

export default Settlements;
