import * as React from 'react';
import styled from 'styled-components';
import Body from '../atoms/typography/Body';
import { ChevronUpIcon, ChevronDownIcon } from '../atoms/icons/Chevrons';
import { SlideOutHeight } from '../atoms/animations/SlideOutHeight';
import { Row, Col } from 'react-grid-system';
import { HorizontalSpacer } from '../atoms/Spacers';
import BodySmall from '../atoms/typography/BodySmall';

const FAQContainer = styled.div`
    padding: 8px;
    border-bottom: 1px solid #0f5156;
`;

const TitleRow = styled(Row)`
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background-color: #0f5156;
    }
`;

const FAQQuestion = ({ question, answer }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <FAQContainer>
            <TitleRow nogutter justify="between" onClick={() => setOpen(!open)}>
                <Col xs={11}>
                    <Body color="white">{question}</Body>
                </Col>
                <Col xs={1} style={{textAlign: 'right'}}>
                    {open ? <ChevronUpIcon color="white" /> : <ChevronDownIcon color="white" />}
                </Col>
            </TitleRow>
            <SlideOutHeight isVisible={open}>
                <div>
                    <HorizontalSpacer spacing={0.5} />
                    <div style={{ padding: 8 }}>
                        <BodySmall color="white">{answer}</BodySmall>
                    </div>
                </div>
            </SlideOutHeight>
        </FAQContainer>
    );
};

export default FAQQuestion;
