import * as React from 'react';

export const EmptyStateIllustration = () => (
    <svg width="1170" height="450" viewBox="0 0 1170 450" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4380_26549)">
            <path
                d="M1002.03 185.635H393.424C390.84 185.635 388.361 186.661 386.534 188.489C384.707 190.316 383.68 192.794 383.68 195.379C383.68 197.963 384.707 200.441 386.534 202.268C388.361 204.096 390.84 205.122 393.424 205.122H410.663V438.222C410.663 440.807 411.689 443.285 413.517 445.112C415.344 446.94 417.822 447.966 420.407 447.966C422.991 447.966 425.469 446.94 427.297 445.112C429.124 443.285 430.15 440.807 430.15 438.222L430.298 205.122H918.769L963.807 438.222C963.807 440.807 964.834 443.285 966.661 445.112C968.488 446.94 970.967 447.966 973.551 447.966C976.135 447.966 978.614 446.94 980.441 445.112C982.268 443.285 983.295 440.807 983.295 438.222L986.13 205.122H1002.03C1004.62 205.122 1007.1 204.096 1008.92 202.268C1010.75 200.441 1011.78 197.963 1011.78 195.379C1011.78 192.794 1010.75 190.316 1008.92 188.489C1007.1 186.661 1004.62 185.635 1002.03 185.635Z"
                fill="#CCCCCC"
            />
            <path
                d="M498.922 142.22V183.482H395.545C392.836 183.576 390.134 183.136 387.595 182.186C385.056 181.236 382.729 179.796 380.746 177.946C378.764 176.097 377.165 173.876 376.041 171.409C374.917 168.942 374.29 166.278 374.196 163.568C374.102 160.859 374.542 158.158 375.492 155.618C376.442 153.079 377.882 150.752 379.731 148.77C381.58 146.787 383.802 145.189 386.269 144.065C388.736 142.941 391.4 142.314 394.109 142.22C394.588 142.203 395.066 142.203 395.545 142.22L498.922 142.22Z"
                fill="#F2F2F2"
            />
            <path
                d="M501.461 183.163C501.461 183.583 501.294 183.987 500.996 184.285C500.699 184.582 500.295 184.749 499.874 184.75H393.704C387.94 184.745 382.414 182.453 378.34 178.376C374.266 174.299 371.977 168.771 371.977 163.008C371.977 157.244 374.266 151.716 378.34 147.639C382.414 143.562 387.94 141.27 393.704 141.266H499.874C500.083 141.265 500.29 141.306 500.483 141.385C500.676 141.465 500.851 141.582 500.999 141.729C501.147 141.876 501.264 142.052 501.344 142.244C501.424 142.437 501.465 142.644 501.465 142.853C501.465 143.061 501.424 143.268 501.344 143.461C501.264 143.654 501.147 143.829 500.999 143.976C500.851 144.124 500.676 144.241 500.483 144.32C500.29 144.4 500.083 144.44 499.874 144.44H393.704C388.784 144.447 384.069 146.407 380.592 149.888C377.116 153.369 375.164 158.088 375.164 163.008C375.164 167.927 377.116 172.646 380.592 176.127C384.069 179.609 388.784 181.568 393.704 181.575H499.874C500.295 181.576 500.699 181.743 500.996 182.041C501.294 182.338 501.461 182.742 501.461 183.163Z"
                fill="#0F5156"
            />
            <path
                d="M498.921 151.742H387.831C387.747 151.743 387.667 151.71 387.607 151.651C387.547 151.592 387.513 151.512 387.512 151.428C387.511 151.344 387.544 151.263 387.603 151.203C387.661 151.143 387.741 151.109 387.825 151.107H498.921C499.005 151.108 499.085 151.142 499.144 151.201C499.203 151.261 499.236 151.341 499.236 151.425C499.236 151.508 499.203 151.589 499.144 151.648C499.085 151.708 499.005 151.741 498.921 151.742Z"
                fill="#CCCCCC"
            />
            <path
                d="M498.921 159.359H387.831C387.747 159.36 387.667 159.327 387.607 159.268C387.547 159.209 387.513 159.129 387.512 159.045C387.511 158.961 387.544 158.88 387.603 158.82C387.661 158.76 387.741 158.726 387.825 158.725H498.921C499.005 158.725 499.085 158.759 499.144 158.819C499.203 158.878 499.236 158.958 499.236 159.042C499.236 159.126 499.203 159.206 499.144 159.265C499.085 159.325 499.005 159.359 498.921 159.359Z"
                fill="#CCCCCC"
            />
            <path
                d="M498.921 166.977H387.831C387.747 166.977 387.667 166.944 387.607 166.885C387.547 166.826 387.513 166.746 387.512 166.662C387.511 166.578 387.544 166.497 387.603 166.437C387.661 166.377 387.741 166.343 387.825 166.342H498.921C499.005 166.343 499.085 166.376 499.144 166.436C499.203 166.495 499.236 166.576 499.236 166.659C499.236 166.743 499.203 166.823 499.144 166.882C499.085 166.942 499.005 166.976 498.921 166.977Z"
                fill="#CCCCCC"
            />
            <path
                d="M498.921 174.598H387.831C387.747 174.598 387.667 174.565 387.607 174.506C387.547 174.447 387.513 174.367 387.512 174.283C387.511 174.199 387.544 174.118 387.603 174.058C387.661 173.998 387.741 173.964 387.825 173.963H498.921C499.005 173.964 499.085 173.998 499.144 174.057C499.203 174.116 499.236 174.197 499.236 174.28C499.236 174.364 499.203 174.444 499.144 174.504C499.085 174.563 499.005 174.597 498.921 174.598Z"
                fill="#CCCCCC"
            />
            <path
                d="M479.429 98.7469V140.009H376.052C370.58 140.199 365.257 138.208 361.254 134.474C357.25 130.739 354.894 125.567 354.703 120.096C354.513 114.624 356.504 109.301 360.239 105.297C363.973 101.293 369.145 98.9372 374.617 98.7469C375.095 98.7302 375.574 98.7302 376.052 98.7469H479.429Z"
                fill="#F2F2F2"
            />
            <path
                d="M481.976 139.694C481.976 140.115 481.809 140.518 481.511 140.816C481.214 141.113 480.81 141.281 480.389 141.281H374.219C368.453 141.281 362.923 138.99 358.845 134.913C354.768 130.835 352.477 125.305 352.477 119.539C352.477 113.772 354.768 108.242 358.845 104.165C362.923 100.088 368.453 97.7969 374.219 97.7969H480.389C480.598 97.7969 480.804 97.8379 480.997 97.9177C481.189 97.9974 481.364 98.1143 481.512 98.2617C481.659 98.409 481.776 98.584 481.856 98.7766C481.935 98.9691 481.976 99.1755 481.976 99.3839C481.976 99.5923 481.935 99.7986 481.856 99.9912C481.776 100.184 481.659 100.359 481.512 100.506C481.364 100.653 481.189 100.77 480.997 100.85C480.804 100.93 480.598 100.971 480.389 100.971H374.219C369.294 100.971 364.572 102.927 361.089 106.409C357.607 109.891 355.651 114.614 355.651 119.539C355.651 124.463 357.607 129.186 361.089 132.668C364.572 136.15 369.294 138.107 374.219 138.107H480.389C480.81 138.107 481.214 138.274 481.511 138.572C481.809 138.869 481.976 139.273 481.976 139.694Z"
                fill="#3F3D56"
            />
            <path
                d="M479.429 108.27H368.339C368.255 108.27 368.174 108.237 368.114 108.178C368.054 108.119 368.02 108.039 368.02 107.955C368.019 107.871 368.051 107.79 368.11 107.73C368.169 107.67 368.249 107.636 368.333 107.635H479.429C479.512 107.636 479.592 107.669 479.651 107.729C479.71 107.788 479.743 107.869 479.743 107.952C479.743 108.036 479.71 108.116 479.651 108.175C479.592 108.235 479.512 108.269 479.429 108.27Z"
                fill="#CCCCCC"
            />
            <path
                d="M479.429 115.887H368.339C368.255 115.887 368.174 115.854 368.114 115.795C368.054 115.736 368.02 115.656 368.02 115.572C368.019 115.488 368.051 115.407 368.11 115.347C368.169 115.287 368.249 115.253 368.333 115.252H479.429C479.512 115.253 479.592 115.287 479.651 115.346C479.71 115.405 479.743 115.486 479.743 115.569C479.743 115.653 479.71 115.733 479.651 115.793C479.592 115.852 479.512 115.886 479.429 115.887Z"
                fill="#CCCCCC"
            />
            <path
                d="M479.429 123.508H368.339C368.255 123.508 368.174 123.475 368.114 123.416C368.054 123.358 368.02 123.277 368.02 123.193C368.019 123.109 368.051 123.029 368.11 122.969C368.169 122.908 368.249 122.874 368.333 122.873H479.429C479.512 122.874 479.592 122.908 479.651 122.967C479.71 123.027 479.743 123.107 479.743 123.19C479.743 123.274 479.71 123.354 479.651 123.414C479.592 123.473 479.512 123.507 479.429 123.508Z"
                fill="#CCCCCC"
            />
            <path
                d="M479.429 131.125H368.339C368.255 131.125 368.174 131.093 368.114 131.034C368.054 130.975 368.02 130.895 368.02 130.811C368.019 130.727 368.051 130.646 368.11 130.586C368.169 130.526 368.249 130.491 368.333 130.49H479.429C479.512 130.491 479.592 130.525 479.651 130.584C479.71 130.644 479.743 130.724 479.743 130.808C479.743 130.891 479.71 130.972 479.651 131.031C479.592 131.09 479.512 131.124 479.429 131.125Z"
                fill="#CCCCCC"
            />
            <path
                d="M599.692 331.645V295.668C599.692 294.078 599.06 292.553 597.935 291.428C596.811 290.304 595.286 289.672 593.696 289.672H457.283C455.693 289.672 454.168 290.304 453.043 291.428C451.919 292.553 451.287 294.078 451.287 295.668V331.645H599.692Z"
                fill="#CCCCCC"
            />
            <path d="M599.692 345.139H451.287V391.609H599.692V345.139Z" fill="#CCCCCC" />
            <path
                d="M451.287 405.1V441.077C451.287 442.667 451.919 444.192 453.043 445.316C454.168 446.441 455.693 447.073 457.283 447.073H593.696C595.286 447.073 596.811 446.441 597.935 445.316C599.06 444.192 599.692 442.667 599.692 441.077V405.1H451.287Z"
                fill="#CCCCCC"
            />
            <path
                d="M524.744 316.654C527.228 316.654 529.241 314.641 529.241 312.157C529.241 309.674 527.228 307.66 524.744 307.66C522.26 307.66 520.247 309.674 520.247 312.157C520.247 314.641 522.26 316.654 524.744 316.654Z"
                fill="white"
            />
            <path
                d="M524.744 376.615C527.228 376.615 529.241 374.602 529.241 372.118C529.241 369.635 527.228 367.621 524.744 367.621C522.26 367.621 520.247 369.635 520.247 372.118C520.247 374.602 522.26 376.615 524.744 376.615Z"
                fill="white"
            />
            <path
                d="M524.98 435.307C527.463 435.307 529.477 433.293 529.477 430.81C529.477 428.326 527.463 426.312 524.98 426.312C522.496 426.312 520.482 428.326 520.482 430.81C520.482 433.293 522.496 435.307 524.98 435.307Z"
                fill="white"
            />
            <path
                d="M582.867 248.651V289.913H479.49C474.021 290.1 468.701 288.107 464.7 284.373C460.7 280.639 458.346 275.469 458.155 270C457.965 264.531 459.954 259.21 463.685 255.207C467.416 251.203 472.584 248.845 478.053 248.651C478.532 248.635 479.011 248.635 479.49 248.651H582.867Z"
                fill="#F2F2F2"
            />
            <path
                d="M585.404 289.596C585.403 290.017 585.236 290.42 584.939 290.718C584.641 291.016 584.238 291.183 583.817 291.183H477.646C471.881 291.185 466.352 288.896 462.274 284.822C458.195 280.747 455.902 275.219 455.899 269.454C455.895 263.689 458.182 258.159 462.255 254.079C466.328 250 471.855 247.705 477.62 247.699H583.817C584.025 247.699 584.232 247.739 584.425 247.819C584.618 247.898 584.794 248.015 584.942 248.163C585.089 248.31 585.207 248.485 585.287 248.678C585.367 248.871 585.408 249.077 585.408 249.286C585.408 249.495 585.367 249.702 585.287 249.894C585.207 250.087 585.089 250.262 584.942 250.41C584.794 250.557 584.618 250.674 584.425 250.754C584.232 250.833 584.025 250.874 583.817 250.873H477.646C472.726 250.88 468.009 252.839 464.532 256.32C461.056 259.802 459.103 264.521 459.103 269.441C459.103 274.361 461.056 279.081 464.532 282.562C468.009 286.044 472.726 288.003 477.646 288.009H583.817C584.238 288.009 584.641 288.176 584.939 288.474C585.236 288.772 585.403 289.175 585.404 289.596Z"
                fill="#3F3D56"
            />
            <path
                d="M582.866 258.174H471.776C471.692 258.174 471.611 258.141 471.551 258.083C471.491 258.024 471.457 257.943 471.457 257.859C471.456 257.775 471.488 257.695 471.547 257.635C471.606 257.574 471.686 257.54 471.77 257.539H582.866C582.949 257.54 583.029 257.574 583.088 257.633C583.147 257.693 583.18 257.773 583.18 257.856C583.18 257.94 583.147 258.02 583.088 258.08C583.029 258.139 582.949 258.173 582.866 258.174Z"
                fill="#CCCCCC"
            />
            <path
                d="M582.866 265.793H471.776C471.692 265.793 471.611 265.761 471.551 265.702C471.491 265.643 471.457 265.563 471.457 265.479C471.456 265.395 471.488 265.314 471.547 265.254C471.606 265.194 471.686 265.159 471.77 265.158H582.866C582.949 265.159 583.029 265.193 583.088 265.252C583.147 265.312 583.18 265.392 583.18 265.476C583.18 265.559 583.147 265.64 583.088 265.699C583.029 265.758 582.949 265.792 582.866 265.793Z"
                fill="#CCCCCC"
            />
            <path
                d="M582.866 273.406H471.776C471.692 273.407 471.611 273.374 471.551 273.315C471.491 273.256 471.457 273.176 471.457 273.092C471.456 273.008 471.488 272.927 471.547 272.867C471.606 272.807 471.686 272.773 471.77 272.771H582.866C582.949 272.772 583.029 272.806 583.088 272.866C583.147 272.925 583.18 273.005 583.18 273.089C583.18 273.173 583.147 273.253 583.088 273.312C583.029 273.372 582.949 273.405 582.866 273.406Z"
                fill="#CCCCCC"
            />
            <path
                d="M582.866 281.027H471.776C471.692 281.028 471.611 280.995 471.551 280.936C471.491 280.877 471.457 280.797 471.457 280.713C471.456 280.629 471.488 280.548 471.547 280.488C471.606 280.428 471.686 280.394 471.77 280.393H582.866C582.949 280.393 583.029 280.427 583.088 280.487C583.147 280.546 583.18 280.626 583.18 280.71C583.18 280.794 583.147 280.874 583.088 280.933C583.029 280.993 582.949 281.027 582.866 281.027Z"
                fill="#CCCCCC"
            />
            <path
                d="M461.451 84.9826H414.351C413.904 84.9823 413.463 85.0849 413.062 85.2825L401.174 91.2336C400.783 91.3999 400.45 91.6776 400.215 92.0321C399.981 92.3866 399.855 92.8023 399.855 93.2273C399.855 93.6524 399.981 94.068 400.215 94.4225C400.45 94.777 400.783 95.0547 401.174 95.221L413.062 101.172C413.463 101.37 413.904 101.472 414.351 101.472H461.451C462.1 101.51 462.737 101.29 463.226 100.861C463.714 100.431 464.013 99.8269 464.059 99.1784V87.2761C464.013 86.6276 463.714 86.0233 463.226 85.594C462.737 85.1647 462.1 84.9451 461.451 84.9826Z"
                fill="#3F3D56"
            />
            <path
                d="M464.066 87.2762V99.1785C464.02 99.827 463.721 100.431 463.232 100.861C462.744 101.29 462.106 101.51 461.457 101.472H452.074V84.9826H461.457C462.106 84.9451 462.744 85.1648 463.232 85.594C463.721 86.0233 464.02 86.6277 464.066 87.2762Z"
                fill="#0F5156"
            />
            <path
                d="M179.079 318.203C185.143 320.335 191.655 320.869 197.986 319.754C204.317 318.639 210.254 315.912 215.225 311.836C227.886 301.21 231.856 283.703 235.085 267.492C238.27 251.51 241.454 235.527 244.638 219.545L224.638 233.317C210.255 243.22 195.547 253.441 185.589 267.786C175.63 282.131 171.283 301.714 179.284 317.236"
                fill="#E6E6E6"
            />
            <path
                d="M180.791 376.252C178.767 361.507 176.685 346.572 178.105 331.669C179.366 318.433 183.404 305.506 191.625 294.906C195.989 289.29 201.298 284.479 207.316 280.688C208.885 279.697 210.329 282.184 208.767 283.17C198.355 289.75 190.302 299.466 185.769 310.918C180.762 323.653 179.958 337.535 180.821 351.063C181.343 359.244 182.449 367.371 183.563 375.488C183.651 375.855 183.596 376.242 183.41 376.57C183.223 376.898 182.919 377.143 182.559 377.256C182.192 377.355 181.8 377.305 181.469 377.117C181.138 376.929 180.894 376.619 180.791 376.252Z"
                fill="#F2F2F2"
            />
            <path
                d="M200.286 350.16C202.887 354.113 206.463 357.33 210.668 359.5C214.873 361.67 219.566 362.721 224.295 362.551C236.449 361.974 246.581 353.492 255.702 345.438L282.681 321.619L264.826 320.764C251.985 320.15 238.814 319.577 226.584 323.536C214.354 327.496 203.074 337.026 200.838 349.685"
                fill="#E6E6E6"
            />
            <path
                d="M175.068 384.731C184.812 367.491 196.113 348.33 216.307 342.206C221.922 340.509 227.809 339.901 233.652 340.415C235.494 340.574 235.034 343.413 233.196 343.254C223.401 342.441 213.626 345.027 205.515 350.578C197.71 355.89 191.633 363.276 186.489 371.109C183.339 375.906 180.517 380.908 177.694 385.902C176.792 387.498 174.156 386.346 175.068 384.731Z"
                fill="#F2F2F2"
            />
            <path
                d="M159.457 402.88C159.785 399.076 161.151 395.435 163.405 392.354C165.66 389.272 168.716 386.869 172.243 385.404V375.438H188.959V385.719C192.312 387.255 195.197 389.652 197.321 392.667C199.445 395.682 200.731 399.206 201.049 402.88L204.98 448.574H155.526L159.457 402.88Z"
                fill="#F2F2F2"
            />
            <path
                d="M727.966 184.703L722.993 182.609L719.581 157.68H674.152L670.454 182.507L666.005 184.732C665.792 184.838 665.621 185.014 665.52 185.229C665.42 185.445 665.394 185.688 665.449 185.92C665.504 186.151 665.635 186.358 665.822 186.505C666.008 186.653 666.239 186.733 666.477 186.733H727.556C727.8 186.733 728.036 186.649 728.225 186.495C728.413 186.34 728.543 186.125 728.591 185.886C728.64 185.647 728.604 185.398 728.489 185.183C728.375 184.967 728.19 184.798 727.966 184.703Z"
                fill="#E6E6E6"
            />
            <path
                d="M808.697 160.995H585.535C584.364 160.993 583.242 160.526 582.415 159.697C581.588 158.868 581.124 157.745 581.124 156.574V125.105H813.108V156.574C813.108 157.745 812.644 158.868 811.817 159.697C810.99 160.526 809.868 160.993 808.697 160.995Z"
                fill="#CCCCCC"
            />
            <path
                d="M813.339 139.976H580.917V5.32775C580.919 3.9147 581.481 2.55999 582.48 1.56082C583.48 0.561659 584.834 -0.000372006 586.247 -0.00195312H808.009C809.422 -0.000372032 810.777 0.56166 811.776 1.56082C812.775 2.55999 813.337 3.91469 813.339 5.32775L813.339 139.976Z"
                fill="#3F3D56"
            />
            <path
                d="M799.453 130.202H594.795C593.706 130.201 592.661 129.768 591.891 128.997C591.121 128.227 590.687 127.183 590.686 126.093V13.8786C590.687 12.7892 591.12 11.7448 591.891 10.9744C592.661 10.2041 593.706 9.77078 594.795 9.76953H799.453C800.543 9.77078 801.587 10.2041 802.357 10.9744C803.128 11.7448 803.561 12.7892 803.562 13.8786V126.093C803.561 127.183 803.128 128.227 802.357 128.997C801.587 129.768 800.543 130.201 799.453 130.202Z"
                fill="white"
            />
            <path
                d="M745.552 169.799C746.873 168.578 748.446 167.662 750.16 167.116C751.874 166.569 753.686 166.404 755.47 166.634C757.254 166.863 758.966 167.481 760.486 168.444C762.005 169.407 763.295 170.691 764.264 172.206L791.947 167.284L799.041 183.377L759.85 189.778C757.197 191.238 754.1 191.674 751.147 191.004C748.194 190.333 745.589 188.602 743.827 186.139C742.064 183.677 741.267 180.653 741.585 177.641C741.903 174.63 743.314 171.839 745.552 169.799Z"
                fill="#FFB6B6"
            />
            <path
                d="M771.182 190.345L800.041 189.986L803.694 189.936L862.41 189.196C862.41 189.196 878.355 173.985 892.239 158.324L890.293 144.248C888.395 130.435 881.542 117.781 871.011 108.643C851.126 121.93 839.594 148.23 839.594 148.23L803.188 156.938L799.546 157.809L776.925 163.214L771.182 190.345Z"
                fill="#0F5156"
            />
            <path
                d="M918.918 276.861H865.048C865.048 279.17 806.161 281.043 806.161 281.043C804.862 282.021 803.678 283.143 802.632 284.388C799.488 288.137 797.77 292.876 797.78 297.769V301.114C797.782 306.659 799.986 311.976 803.906 315.896C807.827 319.817 813.144 322.02 818.688 322.022H918.917C924.461 322.017 929.776 319.813 933.696 315.893C937.616 311.973 939.82 306.658 939.825 301.114V297.769C939.818 292.226 937.612 286.913 933.693 282.994C929.774 279.074 924.46 276.869 918.918 276.861Z"
                fill="#E4E4E4"
            />
            <path d="M879.746 321.191H858.002V446.699H879.746V321.191Z" fill="#E4E4E4" />
            <path
                d="M796.118 445.935C796.118 448.285 829.068 446.844 869.713 446.844C910.359 446.844 943.308 448.285 943.308 445.935C943.308 443.586 910.359 424.955 869.713 424.955C829.068 424.955 796.118 443.586 796.118 445.935Z"
                fill="#E4E4E4"
            />
            <path d="M781.507 431.594L765.324 431.593L757.625 369.174L781.509 369.175L781.507 431.594Z" fill="#FFB6B6" />
            <path
                d="M785.634 447.28L733.454 447.278V446.618C733.454 441.232 735.594 436.066 739.403 432.257C743.212 428.449 748.378 426.309 753.764 426.309H753.765L785.635 426.31L785.634 447.28Z"
                fill="#2F2E41"
            />
            <path d="M733.68 431.594L717.497 431.593L709.798 369.174L733.682 369.175L733.68 431.594Z" fill="#FFB6B6" />
            <path
                d="M737.807 447.28L685.627 447.278V446.618C685.627 441.232 687.767 436.066 691.576 432.257C695.385 428.449 700.551 426.309 705.937 426.309H705.938L737.808 426.31L737.807 447.28Z"
                fill="#2F2E41"
            />
            <path
                d="M878.637 72.2377C894.177 72.2377 906.774 59.6402 906.774 44.1003C906.774 28.5604 894.177 15.9629 878.637 15.9629C863.097 15.9629 850.499 28.5604 850.499 44.1003C850.499 59.6402 863.097 72.2377 878.637 72.2377Z"
                fill="#FFB6B6"
            />
            <path
                d="M924.177 236.888C924.177 236.888 933.618 286.81 897.696 293.167C861.775 299.524 809.922 303.992 809.922 303.992L786.636 412.634L755.352 409.841C755.352 409.841 759.513 279.061 769.745 268.829C779.976 258.597 844.645 227.961 844.645 227.961L924.177 236.888Z"
                fill="#2F2E41"
            />
            <path
                d="M854.018 215.963L838.308 231.723C838.308 231.723 724.87 249.995 720.494 276.195C713.887 315.752 706.083 401.769 706.083 401.769L740.189 399.211L753.564 301.433L808.133 289.02L870.218 255.185L854.018 215.963Z"
                fill="#2F2E41"
            />
            <path
                d="M905.714 27.3048C902.936 22.0642 899.407 11.6198 893.801 9.68279C890.688 8.65047 887.47 7.96498 884.206 7.63878L874.677 11.3038L879.863 7.27025C878.854 7.2101 877.844 7.16092 876.836 7.12272L870.406 9.59553L873.696 7.03716C867.702 6.97564 861.515 7.59888 856.87 11.2478C852.911 14.3585 848.988 26.1426 848.353 31.1376C847.675 35.916 847.913 40.7798 849.053 45.4695L850.681 47.0178C851.251 45.7118 851.679 44.3486 851.958 42.9516C852.542 40.3412 853.663 37.881 855.25 35.7279C856.838 33.5747 858.856 31.7758 861.177 30.4458L861.265 30.3958C863.996 28.8589 867.31 28.9297 870.441 29.0425L885.315 29.5777C888.893 29.7064 892.746 29.9329 895.536 32.1775C897.309 33.8333 898.703 35.8529 899.623 38.0978C901.009 40.8957 903.718 51.4485 903.718 51.4485C903.718 51.4485 905.251 49.4566 905.985 50.9392C907.163 47.0709 907.879 43.0769 908.119 39.0403C908.353 34.9858 907.524 30.9404 905.714 27.3048Z"
                fill="#2F2E41"
            />
            <path
                d="M1004.12 56.4561C1002.33 56.5743 1000.58 57.0846 999.006 57.951C997.429 58.8175 996.062 60.019 995 61.471C993.939 62.923 993.208 64.5901 992.861 66.355C992.513 68.1198 992.556 69.9394 992.988 71.6856L970.361 88.3761L977.151 104.6L1008.77 80.581C1011.66 79.6616 1014.11 77.7156 1015.65 75.1118C1017.2 72.5081 1017.74 69.4275 1017.16 66.4538C1016.59 63.48 1014.95 60.8198 1012.54 58.9772C1010.14 57.1346 1007.14 56.2375 1004.12 56.4561Z"
                fill="#FFB6B6"
            />
            <path
                d="M1000.49 90.5324L943.764 153.53C943.764 153.53 916.638 144.846 895.659 135.431L886.235 106.485C884.046 99.7696 883.112 92.7089 883.479 85.6556C912.562 77.9169 946.388 106.712 946.388 106.712L988.848 73.4531L1000.49 90.5324Z"
                fill="#0F5156"
            />
            <path
                d="M868.136 241.539C858.017 238.043 847.552 234.429 836.265 233.072L835.78 233.013L835.914 232.544C847.598 191.519 844.682 165.276 838.956 125.855C837.738 117.352 839.303 108.683 843.417 101.142C847.53 93.6014 853.972 87.593 861.781 84.0139L861.85 83.9826L894.087 81.9341L894.161 81.9297L917.606 88.8252C920.979 89.8245 923.931 91.902 926.01 94.7394C928.089 97.5769 929.18 101.018 929.117 104.535C928.863 117.499 929.401 131.973 929.972 147.297C931.251 181.675 932.574 217.224 925.067 240.289L925.028 240.41L924.927 240.488C918.026 246.109 909.38 249.146 900.479 249.076C889.956 249.076 879.217 245.366 868.136 241.539Z"
                fill="#0F5156"
            />
            <path
                d="M1102.16 449.998H67.8291C67.4315 449.998 67.0503 449.84 66.7691 449.559C66.488 449.278 66.3301 448.897 66.3301 448.499C66.3301 448.101 66.488 447.72 66.7691 447.439C67.0503 447.158 67.4315 447 67.8291 447H1102.16C1102.56 447 1102.94 447.158 1103.22 447.439C1103.51 447.72 1103.66 448.101 1103.66 448.499C1103.66 448.897 1103.51 449.278 1103.22 449.559C1102.94 449.84 1102.56 449.998 1102.16 449.998Z"
                fill="#CCCCCC"
            />
        </g>
        <defs>
            <clipPath id="clip0_4380_26549">
                <rect width="1037.33" height="450" fill="white" transform="translate(66.3335)" />
            </clipPath>
        </defs>
    </svg>
);
