import React, { useState } from 'react';
import styled from 'styled-components';
import BodySmall from '../typography/BodySmall';
import { theme } from '../../theme';

const checkedIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.50195 12C2.50195 7.52166 2.50195 5.28249 3.89319 3.89124C5.28444 2.5 7.52361 2.5 12.002 2.5C16.4803 2.5 18.7195 2.5 20.1108 3.89124C21.502 5.28249 21.502 7.52166 21.502 12C21.502 16.4783 21.502 18.7175 20.1108 20.1088C18.7195 21.5 16.4803 21.5 12.002 21.5C7.52361 21.5 5.28444 21.5 3.89319 20.1088C2.50195 18.7175 2.50195 16.4783 2.50195 12Z"
            fill="white"
            stroke="#4D4D4D"
            strokeWidth="1.5"
        />
        <path
            d="M8.00195 13.75C8.00195 13.75 9.60195 14.6625 10.402 16C10.402 16 12.802 10.75 16.002 9"
            stroke="#4D4D4D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const uncheckedIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.50195 12C2.50195 7.52166 2.50195 5.28249 3.89319 3.89124C5.28444 2.5 7.52361 2.5 12.002 2.5C16.4803 2.5 18.7195 2.5 20.1108 3.89124C21.502 5.28249 21.502 7.52166 21.502 12C21.502 16.4783 21.502 18.7175 20.1108 20.1088C18.7195 21.5 16.4803 21.5 12.002 21.5C7.52361 21.5 5.28444 21.5 3.89319 20.1088C2.50195 18.7175 2.50195 16.4783 2.50195 12Z"
            fill="white"
            stroke="#4D4D4D"
            strokeWidth="1.5"
        />
    </svg>
);

const checkedIconSmall = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.66669 7.99996C1.66669 5.0144 1.66669 3.52162 2.59418 2.59412C3.52168 1.66663 5.01446 1.66663 8.00002 1.66663C10.9856 1.66663 12.4784 1.66663 13.4059 2.59412C14.3334 3.52162 14.3334 5.0144 14.3334 7.99996C14.3334 10.9855 14.3334 12.4783 13.4059 13.4058C12.4784 14.3333 10.9856 14.3333 8.00002 14.3333C5.01446 14.3333 3.52168 14.3333 2.59418 13.4058C1.66669 12.4783 1.66669 10.9855 1.66669 7.99996Z"
            stroke="#4D4D4D"
            strokeWidth="1.5"
        />
        <path
            d="M5.33331 9.16667C5.33331 9.16667 6.39998 9.775 6.93331 10.6667C6.93331 10.6667 8.53331 7.16667 10.6666 6"
            stroke="#4D4D4D"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const uncheckedIconSmall = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.66669 7.99996C1.66669 5.0144 1.66669 3.52162 2.59418 2.59412C3.52168 1.66663 5.01446 1.66663 8.00002 1.66663C10.9856 1.66663 12.4784 1.66663 13.4059 2.59412C14.3334 3.52162 14.3334 5.0144 14.3334 7.99996C14.3334 10.9855 14.3334 12.4783 13.4059 13.4058C12.4784 14.3333 10.9856 14.3333 8.00002 14.3333C5.01446 14.3333 3.52168 14.3333 2.59418 13.4058C1.66669 12.4783 1.66669 10.9855 1.66669 7.99996Z"
            stroke="#4D4D4D"
            strokeWidth="1.5"
        />
    </svg>
);

const CheckboxWrapper = styled.label`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    filter: ${(props) => (props.disabled ? 'opacity(0.4)' : 'none')};
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    cursor: pointer;
`;

const Icon = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 4px;
    margin-right: 8px;
    color: #fff;
`;

const Checkbox = ({ checked, onChange, title, disabled, small, color = theme.colors.text }) => {
    const handleChange = (e) => {
        if (onChange) {
            onChange(e.target.checked);
        }
    };

    return (
        <CheckboxWrapper disabled={disabled}>
            <HiddenCheckbox checked={checked} onChange={handleChange} disabled={disabled} />
            {small ? (
                <Icon checked={checked}>{checked ? checkedIconSmall : uncheckedIconSmall}</Icon>
            ) : (
                <Icon checked={checked}>{checked ? checkedIcon : uncheckedIcon}</Icon>
            )}
            {small ? <BodySmall color={color}>{title}</BodySmall> : <BodySmall color={color}>{title}</BodySmall>}
        </CheckboxWrapper>
    );
};

export default Checkbox;
