import { useQuery, gql } from '@apollo/client';

const GET_ISSUER_SETTINGS = gql`
    query getIssuerSettings($clientIdentifier: String!) {
        issuerSettings(clientIdentifier: $clientIdentifier) {
            settlementAutoBookActive
            accountingSystem
        }
    }
`;

export default GET_ISSUER_SETTINGS;
