import { ApolloClient, InMemoryCache, ApolloProvider, gql, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => {
    const oidcUserStorageName = process.env.REACT_APP_OIDC_USER_STORAGE;
    const oidcUser = localStorage.getItem(oidcUserStorageName);
    const data = oidcUser ? JSON.parse(oidcUser) : null;
    const token = data?.access_token;

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const baseUri = process.env.REACT_APP_GRAPHQL_URL;

const httpLink = createHttpLink({
    uri: `${baseUri}/api/gateway/issuer/`,
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default client;
