import * as React from 'react';
import { theme } from '../../theme';

export const WarningIcon = ({ color = theme.colors.text }) => (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.54773 6.6353C5.15685 3.78801 5.9614 2.36435 7.06545 1.99788C7.67279 1.79628 8.32705 1.79628 8.93439 1.99788C10.0385 2.36435 10.843 3.78801 12.4521 6.6353C14.0613 9.48256 14.8658 10.9062 14.6245 12.0662C14.4917 12.7044 14.1645 13.2832 13.6899 13.7198C12.8273 14.5134 11.2182 14.5134 7.99992 14.5134C4.78169 14.5134 3.17257 14.5134 2.30989 13.7198C1.83531 13.2832 1.50818 12.7044 1.3754 12.0662C1.13405 10.9062 1.93861 9.48256 3.54773 6.6353Z"
            stroke={color}
            strokeWidth="1.5"
        />
        <path d="M7.99463 10.8467H8.00063" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 8.84674V6.18005" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
