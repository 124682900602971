import * as React from 'react';
import Body from '../atoms/typography/Body';
import styled from 'styled-components';
import Title from '../atoms/typography/Title';
import { Row } from 'react-grid-system';
import { HorizontalSpacer } from '../atoms/Spacers';
import { EmptyStateIllustration } from '../atoms/illustrations/EmptyStateIllustartion';

const IllustrationWrapper = styled(Row)`
    max-width: 900px;
    @media (max-width: 768px) {
        svg {
            max-height: 180px;
        }
    }
`;

const EmptyStateSettlement = () => {
    return (
        <div>
            <Row nogutter>
                <Title color="white">Här var det tomt!</Title>
            </Row>
            <HorizontalSpacer spacing={2} />
            <IllustrationWrapper nogutter>
                <EmptyStateIllustration />
            </IllustrationWrapper>
        </div>
    );
};

export default EmptyStateSettlement;
