import { useQuery } from '@apollo/client';
import GET_SETTLEMENT_DOCUMENT from '../queries/getSettlementDocument';

export const useSettlementDocument = (clientIdentifier, settlementId) => {
    const { data, error, loading, refetch } = useQuery(GET_SETTLEMENT_DOCUMENT, {
        variables: { clientIdentifier: clientIdentifier, settlementId: settlementId },
        onError: (error) => console.log('error: ', error),
    });
    return { settlementDocument: data?.settlementDocument, error, loading, refetch };
};
