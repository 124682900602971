import * as React from 'react';

export const EditIcon = ({ color = '#4d4d4d' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.1447 3.98822L11.0792 3.05371C11.5953 2.5376 12.4321 2.5376 12.9482 3.05371C13.4643 3.56982 13.4643 4.40661 12.9482 4.92272L12.0137 5.85723M10.1447 3.98822L4.65546 9.47749C3.95859 10.1744 3.61015 10.5228 3.37289 10.9474C3.13562 11.372 2.89691 12.3746 2.66864 13.3333C3.62737 13.105 4.62997 12.8663 5.05457 12.629C5.47917 12.3918 5.8276 12.0434 6.52447 11.3465L12.0137 5.85723M10.1447 3.98822L12.0137 5.85723"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M7.33527 13.3334H11.3353" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
);
