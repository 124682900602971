import * as React from 'react';
import BodySmall from '../atoms/typography/BodySmall';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/Spacers';
import { Row, Visible, Hidden } from 'react-grid-system';
import { CheckmarkIconLarge, CheckmarkIcon } from '../atoms/icons/CheckmarkIcon';
import Subtitle from '../atoms/typography/Subtitle';
import Body from '../atoms/typography/Body';
import Small from '../atoms/typography/Small';
import GradientButtonLight from '../atoms/buttons/GradientButtonLight';
import GradientButtonDark from '../atoms/buttons/GradientButtonDark';
import AutobookInfoModalContent from '../organisms/AutobookInfoModalContent';
import { theme } from '../theme';
import { ActivateIcon } from '../atoms/icons/ActivateIcon';
import ReactDOM from 'react-dom';
import MissingIntegrationModalContent from '../organisms/MissingIntegrationModalContent';
import { useClientIssuerSettings } from '../graphql/hooks/useClientIssuerSettings';
import { useUpdateClientSettings } from '../graphql/hooks/useUpdateClientSettings';
import Spinner from '../global/Spinner';
import styled from 'styled-components';

const ResponsiveSubtitle = styled(Subtitle)`
    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const ActivateAutobook = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [missingIntegrationModal, setMissingIntegrationModal] = React.useState(false);

    const commitment = JSON.parse(localStorage.getItem('commitment') ?? '{}');
    const clientIdentifier = commitment.organisationNumber ?? '';

    const { clientSettings, loading, error, refetch } = useClientIssuerSettings(clientIdentifier);

    const isActive = clientSettings?.settlementAutoBookActive;

    const hasFortnoxIntegration = clientSettings?.accountingSystem === 'Fortnox';

    const onCompletedUpdateClientSettings = () => {
        setTimeout(() => {
            refetch();
        }, 600);
    };
    const onErrorUpdateClientSettings = () => {};
    const {
        updateClientSettings,
        error: updateClientError,
        loading: updateClientLoading,
    } = useUpdateClientSettings({ onCompleted: onCompletedUpdateClientSettings, onError: onErrorUpdateClientSettings });

    const onClickActivateAutobook = async () => {
        try {
            updateClientSettings({
                variables: {
                    input: {
                        clientIdentifier: clientIdentifier,
                        settlementAutoBookActive: !isActive,
                    },
                },
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    const onOpenMossingIntegrationModal = () => {
        setMissingIntegrationModal(true);
    };

    if (loading) {
        return <Spinner />;
    }
    if (error) {
        alert('Error fetching client settings');
    }

    return (
        <div>
            <BodySmall color="white" style={{ fontWeight: 700 }}>
                NYHET
            </BodySmall>

            <HorizontalSpacer spacing={1} />
            <Row nogutter align="center">
                {isActive && (
                    <>
                        <Hidden xs>
                            <CheckmarkIconLarge color={theme.colors.success} />
                        </Hidden>
                        <Visible xs>
                            <CheckmarkIcon color={theme.colors.success} />
                        </Visible>
                        <VerticalSpacer spacing={1} />
                    </>
                )}
                <ResponsiveSubtitle color={theme.colors.white}>Automatisk bokföring</ResponsiveSubtitle>
            </Row>
            <HorizontalSpacer spacing={1} />
            <Body color="white">
                {isActive
                    ? 'Du har aktiverat automatisk bokföring, dina fakturor som går via Factoringgruppen bokförs automatiskt.'
                    : 'Om du aktiverar automatisk bokföring kommer dina fakturor som går via Factoringgruppen att bokföras automatiskt.'}{' '}
                Du har möjlighet att redigera kontoinställningarna ovan. Du har alltid åtkomst till dina kvitton och underlag via “Avräkningar” i menyn. 
            </Body>
            <HorizontalSpacer spacing={1} />
            <Small color="white">För nuvarande stödjer vi endast Fortnox.</Small>

            <HorizontalSpacer spacing={1} />
            <Row nogutter align="center">
                {updateClientLoading ? (
                    <Spinner />
                ) : (
                    <GradientButtonLight
                        title={isActive ? 'Stäng av automatisk bokföring' : 'Aktivera automatisk bokföring'}
                        icon={<ActivateIcon />}
                        onClick={hasFortnoxIntegration ? onClickActivateAutobook : onOpenMossingIntegrationModal}
                    />
                )}
                <VerticalSpacer spacing={2} />
                <div>
                    <Visible xs>
                        <HorizontalSpacer spacing={1} />
                    </Visible>
                    <GradientButtonDark title="Läs mer om Autobook" onClick={() => setModalOpen(true)} />
                </div>
            </Row>
            {updateClientError && (
                <div>
                    <Body color="red">Vi kunde inte uppdatera dina inställningar. Försök igen senare eller kontakta oss.</Body>
                </div>
            )}

            {modalOpen &&
                ReactDOM.createPortal(
                    <AutobookInfoModalContent open={modalOpen} onClose={() => setModalOpen(false)} onClickActivateAutobook={onClickActivateAutobook} />,
                    document.body
                )}
            {missingIntegrationModal &&
                ReactDOM.createPortal(
                    <MissingIntegrationModalContent open={missingIntegrationModal} onClose={() => setMissingIntegrationModal(false)} />,
                    document.body
                )}
        </div>
    );
};

export default ActivateAutobook;
