import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import { VerticalSpacer } from '../Spacers';

const GradientButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 8px;
    border: none;
    cursor: pointer;

    height: 56px;
    background: linear-gradient(262.62deg, #4d4d4d 0%, #3d7276 100%);

    box-shadow: 2px 4px 8px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    color: ${(props) => theme.colors.white};
    font-size: 16px;
    font-weight: 700;

    &:hover {
        background-color: ${(props) => theme.colors.primary};
        background: ${(props) => theme.colors.primary};
    }

    &:active {
        background-color: ${(props) => theme.colors.primary};
        background: ${(props) => theme.colors.primary};
        box-shadow: none;
    }
`;

const GradientButtonDark = ({ title, onClick, disabled, icon }) => {
    return (
        <GradientButton onClick={onClick} disabled={disabled}>
            {icon && (
                <div>
                    {icon} <VerticalSpacer spacing={0.5} />
                </div>
            )}
            {title}
        </GradientButton>
    );
};

export default GradientButtonDark;
