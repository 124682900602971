import React from 'react';
import Settlements from '../autobook/Settlements';
import { Container } from 'react-grid-system';
import { HorizontalSpacer } from '../../atoms/Spacers';
import Title from '../../atoms/typography/Title';
import { PageWrapper } from '../../atoms/PageWrapper';

const HistoryTransfercostsPage = () => {
    return (
        <PageWrapper>
            <Container>
                <HorizontalSpacer spacing={2} />
                <Title color="white">Avräkningar</Title>
                <HorizontalSpacer spacing={2} />
                <Settlements />
            </Container>
        </PageWrapper>
    );
};

export default HistoryTransfercostsPage;
