import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import { VerticalSpacer } from '../Spacers';

const ButtonContainer = styled.button`
    background-color: ${(props) => props.color};
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
    color: ${(props) => (props.color === theme.colors.warning ? theme.colors.text : theme.colors.white)};
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;

    &:hover {
        filter: ${(props) => !props.disabled && 'opacity(0.6)'};
        box-shadow: ${(props) => !props.disabled && '0px 4px 6px rgba(0, 0, 0, 0.1)'};
    }

    &:active {
        filter: opacity(1);
        box-shadow: none;
    }

    &:disabled {
        background-color: ${(props) => theme.colors.disabled};
        color: ${(props) => theme.colors.white};
    }
`;

const ActionButton = ({ onClick, disabled, title, color = theme.colors.warning, icon, type }) => {
    return (
        <ButtonContainer color={color} onClick={onClick} disabled={disabled} type={type}>
            {icon && (
                <>
                    {icon} <VerticalSpacer spacing={0.5} />
                </>
            )}
            {title}
        </ButtonContainer>
    );
};

export default ActionButton;
