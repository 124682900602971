import { useQuery, gql } from '@apollo/client';

const GET_SIE_FILE = gql`
    query getSieFile($clientIdentifier: String!, $settlementId: String) {
        sieFile(clientIdentifier: $clientIdentifier, settlementId: $settlementId) {
            isBalanced
            data
        }
    }
`;

export default GET_SIE_FILE;
