import React, { useState } from 'react';
import styled from 'styled-components';
import BodySmall from '../typography/BodySmall';
import { HorizontalSpacer } from '../Spacers';
import Body from '../typography/Body';
import { theme } from '../../theme';

const InputStyle = styled.input`
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--TextMuted, #a1acbc);
    font-size: 16px;
    font-weight: 500;
    color: ${theme.colors.text};
    padding: 4px;
    background-color: ${theme.colors.white};
    //min-width: 200px;

    &:focus {
        border: 1px solid #6a7789;
        color: #6a7789;
        outline: none;
    }
`;

const Dropdown = styled.ul`
    position: absolute;
    width: 100%;
    background-color: ${theme.colors.white};
    border: 1px solid var(--TextMuted, #a1acbc);
    border-radius: 4px;
    max-height: 250px;
    overflow-y: auto;
    margin: -2px 0 0;
    padding: 0;
    list-style: none;
    z-index: 100;
`;

const DropdownItem = styled.li`
    padding: 8px;
    cursor: pointer;
    color: ${theme.colors.textSecondary};
    &:hover {
        background-color: ${theme.colors.info20};
    }
`;

const AutocompleteSelect = ({ label, value, errorMessage, onChange, disabled, options, color = theme.colors.text }) => {
    const [inputValue, setInputValue] = useState(value || '');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    React.useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputChange = (e) => {
        const userInput = e.target.value;
        setInputValue(userInput);

        const filtered = options.filter((option) => option.label.toLowerCase().includes(userInput.toLowerCase()));
        setFilteredOptions(filtered);
        setIsDropdownOpen(true);
    };

    const handleOptionSelect = (selectedValue) => {
        const selectedOption = options.find((option) => option.value === selectedValue);
        if (selectedOption) {
            setInputValue(selectedOption.label);
            onChange(selectedOption.value);
            setIsDropdownOpen(false);
        }
    };

    const handleBlur = () => {
        // Close the dropdown on blur after a short delay to allow click events to register
        setTimeout(() => setIsDropdownOpen(false), 200);
    };

    return (
        <div style={{ position: 'relative' }}>
            <BodySmall color={disabled ? '#6A7789' : color}>{label}</BodySmall>
            <HorizontalSpacer spacing={0.25} />
            {disabled ? (
                <Body color={color}>{value}</Body>
            ) : (
                <>
                    <InputStyle
                        value={inputValue}
                        onChange={handleInputChange}
                        onFocus={() => setIsDropdownOpen(true)}
                        onBlur={handleBlur}
                        disabled={disabled}
                        placeholder="Select an option..."
                    />
                    {isDropdownOpen && filteredOptions.length > 0 && (
                        <Dropdown>
                            {filteredOptions.map((option) => (
                                <DropdownItem key={option.value} onClick={() => handleOptionSelect(option.value)}>
                                    {option.label}
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    )}
                </>
            )}
            {errorMessage && <BodySmall color="#E9033A">{errorMessage}</BodySmall>}
        </div>
    );
};

export default AutocompleteSelect;
