import { useQuery } from '@apollo/client';
import GET_SETTLEMENT_OVERVIEW from '../queries/getSettlementOverview';

export const useSettlementOverview = (clientIdentifier, createdFrom, createdTo) => {
    const { data, error, loading, refetch } = useQuery(GET_SETTLEMENT_OVERVIEW, {
        variables: { clientIdentifier: clientIdentifier, createdFrom: createdFrom, createdTo: createdTo },
        onError: (error) => console.log('error: ', error),
    });
    return { settlementOverview: data?.settlementOverview, error, loading, refetch };
};
