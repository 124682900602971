import { useQuery } from '@apollo/client';
import GET_SIE_FILE from '../queries/getSieFile';

export const useSieFile = (clientIdentifier, settlementId) => {
    const { data, error, loading, refetch } = useQuery(GET_SIE_FILE, {
        variables: { clientIdentifier: clientIdentifier, settlementId: settlementId },
        onError: (error) => console.log('error: ', error),
    });
    return { sieFile: data?.sieFile, error, loading, refetch };
};
