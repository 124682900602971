import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const Title = styled.div`
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    color: ${(props) => props.color ?? theme.colors.text};
    text-align: ${(props) => props.textalign ?? 'left'};
    font-weight: 700;
`;

export default Title;
