import { useQuery } from '@apollo/client';
import GET_ISSUER_SETTINGS from '../queries/getClientIssuerSettings';

export const useClientIssuerSettings = (clientIdentifier) => {
    const { data, error, loading, refetch } = useQuery(GET_ISSUER_SETTINGS, {
        variables: { clientIdentifier: clientIdentifier },
        fetchPolicy: 'network-only',
        onError: (error) => console.log('error: ', error),
    });
    return { clientSettings: data?.issuerSettings, error, loading, refetch };
};
