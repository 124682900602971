import { useMutation } from '@apollo/client';
import { UPDATE_SETTLEMENT_ACCOUNT_PLAN_TO_CURRENT } from '../mutations/updateSettlementAccountPlanToCurrent';
import GET_SETTLEMENT_OVERVIEW from '../queries/getSettlementOverview';
import GET_SETTLEMENT_DATA from '../queries/getSettlementData';

export const useUpdateSettlementAccountPlanToCurrent = ({ onCompleted, onError }) => {
    const [updateSettlementAccountPlanToCurrent, { data, loading, error }] = useMutation(UPDATE_SETTLEMENT_ACCOUNT_PLAN_TO_CURRENT, {
        onCompleted: onCompleted,
        onError: onError,
        refetchQueries: [GET_SETTLEMENT_OVERVIEW, GET_SETTLEMENT_DATA],
        awaitRefetchQueries: true,
    });

    return { updateSettlementAccountPlanToCurrent, data, loading, error };
};
