import * as React from 'react';
import styled from 'styled-components';
import { VerticalSpacer } from '../Spacers';
import { theme } from '../../theme';

const Container = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.color};
    border-bottom: ${(props) => `1.5px solid ${props.color}`};
    cursor: pointer;
    background-color: transparent;

    &:hover {
        box-shadow: ${(props) => theme.shadow.cardShadow};
        filter: opacity(0.6);
    }

    &:active {
        box-shadow: none;
        transform: translateY(1px);
    }
`;

const DownloadButton = ({ icon, onClick, title, color = theme.colors.text }) => {
    return (
        <Container onClick={onClick} color={color}>
            {icon && icon}
            <VerticalSpacer spacing={0.55} />
            {title}
        </Container>
    );
};

export default DownloadButton;
