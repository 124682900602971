import * as React from 'react';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/Spacers';
import { Row, Col, Visible } from 'react-grid-system';
import Subtitle from '../atoms/typography/Subtitle';
import DefaultButton from '../atoms/buttons/DefaultButton';
import { EditIcon } from '../atoms/icons/EditIcon';
import Body from '../atoms/typography/Body';
import AutobookEditAccount from './AutobookEditAccount';
import TextField from '../atoms/inputs/TextField';
import Tooltip from './Tooltip';
import TextLinkButton from '../atoms/buttons/TextLinkButton';
import { InfoIcon } from '../atoms/icons/InfoIcon';
import GradientButtonLight from '../atoms/buttons/GradientButtonLight';
import { useAccountPlan } from '../graphql/hooks/useAccountPlan';
import { useDefaultAccountPlan } from '../graphql/hooks/useDefaultAccountPlan';
import { getInitialAccountPlan } from '../helpers';
import { useUpdateAccountPlan } from '../graphql/hooks/useUpdateAccountPlan';
import Spinner from '../global/Spinner';
import { useCreateAccountPlan } from '../graphql/hooks/useCreateAccountPlan';

const SelectAccountsComponent = () => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [accounts, setAccounts] = React.useState([]);
    const [receiptSeries, setReceiptSeries] = React.useState('FG');

    const commitment = JSON.parse(localStorage.getItem('commitment') ?? '{}');
    const clientIdentifier = commitment.organisationNumber ?? '';
    const { accountPlan, error, loading } = useAccountPlan(clientIdentifier);
    const { defaultAccountPlan, error: defaultAccountError, loading: defaultAccountLoading } = useDefaultAccountPlan();

    React.useEffect(() => {
        if (accountPlan && defaultAccountPlan) {
            setAccounts(getInitialAccountPlan(accountPlan, defaultAccountPlan));
            setReceiptSeries(accountPlan.receiptSeries);
        }
    }, [accountPlan, defaultAccountPlan]);

    const onChangeAccountValue = (id, value) => {
        const updatedAccounts = accounts.map((account) => (account.id === id ? { ...account, value } : account));
        setAccounts(updatedAccounts);
    };

    const onResetAccount = (id) => {
        const updatedAccounts = accounts.map((account) => (account.id === id ? { ...account, value: account.resetValue } : account));
        setAccounts(updatedAccounts);
    };

    const onCompletedUpdateAccountPlan = () => {
        setIsEditing(false);
    };
    const onErrorUpdateAccountPlan = () => {
        console.log('Error updating account plan');
    };
    const {
        updateAccountPlan,
        error: updateAccountsError,
        loading: updateAccountsLoading,
    } = useUpdateAccountPlan({ onCompleted: onCompletedUpdateAccountPlan, onError: onErrorUpdateAccountPlan });
    const {
        createAccountPlan,
        error: createAccountsError,
        loading: createAccountsLoading,
    } = useCreateAccountPlan({ onCompleted: onCompletedUpdateAccountPlan, onError: onErrorUpdateAccountPlan });

    const onClickSave = async () => {
        if (accountPlan.id) {
            const submission = {
                accountPlanId: accountPlan?.id,
                businessAccount: accounts.find((account) => account.id === 'businessAccount')?.value,
                accountsReceivable: accounts.find((account) => account.id === 'accountsRecievable')?.value,
                clearingAccountFactoring: accounts.find((account) => account.id === 'clearingAccountFactoring')?.value,
                clearingAccountPledgedReceivable: accounts.find((account) => account.id === 'clearingAccountPledgedReceivable')?.value,
                factoringCharges: accounts.find((account) => account.id === 'factoringCharges')?.value,
                inputVat: accounts.find((account) => account.id === 'inputVat')?.value,
                interestExpenseToCreditInstitutions: accounts.find((account) => account.id === 'interestExpenseToCreditInstitutions')?.value,
                otherCurrentLiabilities: accounts.find((account) => account.id === 'otherCurrentLiabilities')?.value,
                otherExternalServices: accounts.find((account) => account.id === 'otherExternalServices')?.value,
                pledgedReceivable: accounts.find((account) => account.id === 'pledgedReceivable')?.value,
                receiptSeries: receiptSeries,
                roundingOff: accounts.find((account) => account.id === 'roundingOff')?.value,
                suspenseAccount: accounts.find((account) => account.id === 'suspenseAccount')?.value,
            };

            try {
                updateAccountPlan({ variables: { input: submission, clientIdentifier: clientIdentifier } });
            } catch (error) {
                console.log('Error updating account plan', error);
            }
        } else {
            const submission = {
                businessAccount: accounts.find((account) => account.id === 'businessAccount')?.value,
                accountsReceivable: accounts.find((account) => account.id === 'accountsRecievable')?.value,
                clearingAccountFactoring: accounts.find((account) => account.id === 'clearingAccountFactoring')?.value,
                clearingAccountPledgedReceivable: accounts.find((account) => account.id === 'clearingAccountPledgedReceivable')?.value,
                factoringCharges: accounts.find((account) => account.id === 'factoringCharges')?.value,
                inputVat: accounts.find((account) => account.id === 'inputVat')?.value,
                interestExpenseToCreditInstitutions: accounts.find((account) => account.id === 'interestExpenseToCreditInstitutions')?.value,
                otherCurrentLiabilities: accounts.find((account) => account.id === 'otherCurrentLiabilities')?.value,
                otherExternalServices: accounts.find((account) => account.id === 'otherExternalServices')?.value,
                pledgedReceivable: accounts.find((account) => account.id === 'pledgedReceivable')?.value,
                receiptSeries: receiptSeries,
                roundingOff: accounts.find((account) => account.id === 'roundingOff')?.value,
                suspenseAccount: accounts.find((account) => account.id === 'suspenseAccount')?.value,
            };

            try {
                createAccountPlan({ variables: { input: submission, clientIdentifier: clientIdentifier } });
            } catch (error) {
                console.log('Error updating account plan', error);
            }
        }
    };

    if (loading || defaultAccountLoading) {
        return <div>Loading...</div>;
    }

    if (error || defaultAccountError) {
        return <div>Error...</div>;
    }

    return (
        <div>
            <HorizontalSpacer spacing={3} />
            <Row align="center" nogutter justify="between">
                <Subtitle color="white">Inställningar för kontoplan</Subtitle>
                <Visible xs>
                    <HorizontalSpacer spacing={1} />
                </Visible>
                {isEditing ? (
                    <DefaultButton title="Avbryt" onClick={() => setIsEditing(false)} />
                ) : (
                    <DefaultButton title="Redigera" icon={<EditIcon />} onClick={() => setIsEditing(true)} />
                )}
            </Row>
            <HorizontalSpacer spacing={1} />
            <Body color="white">
                Här väljer du vilka konton du vill använda för din bokföring. Gäller när du laddar ner SIE-fil, samt om du har{' '}
                <a href="/autobook" style={{ textDecoration: 'underline' }}>
                    automatisk bokföring.
                </a>
            </Body>
            <HorizontalSpacer spacing={1} />
            <Body color="white">
                Vi har listat våra föreslagna konton för bokföringen, även om du ändrar har du alltid möjlighet att återgå till de rekommenderade
                grundinställningarna med ett knapptryck när du redigerar kontoplanen.
            </Body>

            <HorizontalSpacer spacing={2} />

            {accounts.map((account) => (
                <div key={account.id}>
                    <AutobookEditAccount
                        value={account.value}
                        onChangeValue={(value) => onChangeAccountValue(account.id, value)}
                        onClickReset={() => onResetAccount(account.id)}
                        name={account.name}
                        description={account.description}
                        isEditing={isEditing}
                        options={account.accountOptions}
                        resetValue={account.resetValue}
                    />
                    <HorizontalSpacer spacing={2} />
                </div>
            ))}

            <Row align="center">
                <Col lg={6}>
                    <TextField
                        label="Verifikationsserie"
                        onChange={(e) => setReceiptSeries(e)}
                        color="white"
                        placeholder="FG"
                        disabled={!isEditing}
                        value={receiptSeries}
                    />
                </Col>
                <VerticalSpacer spacing={1} />
                {isEditing && (
                    <div>
                        <TextLinkButton
                            title="Återställ till grundinställningar"
                            color="white"
                            onClick={() => setReceiptSeries(defaultAccountPlan.receiptSeries)}
                        />
                        <VerticalSpacer spacing={1} />
                    </div>
                )}
                <Tooltip text="Verifikationsserie för transaktioner som går via Factoringgruppen">
                    <InfoIcon color="white" />
                </Tooltip>
            </Row>

            <HorizontalSpacer spacing={2} />

            <HorizontalSpacer spacing={2} />
            {isEditing && <>{updateAccountsLoading || createAccountsLoading ? <Spinner /> : <GradientButtonLight title="Spara" onClick={onClickSave} />}</>}
            {(updateAccountsError || createAccountsError) && <Body color="red">Din kontoplan kunde inte sparas. Testa igen eller kontakta support.</Body>}
        </div>
    );
};

export default SelectAccountsComponent;
