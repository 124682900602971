import { motion } from 'framer-motion';
import * as React from 'react';
import styled from 'styled-components';

const BackdropStyle = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    background: ${(props) => props.backdropcolor};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    overflow: hidden;
`;

const Backdrop = ({ children, onClick, backdropcolor }) => {
    return (
        <BackdropStyle onClick={onClick} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} backdropcolor={backdropcolor}>
            {children}
        </BackdropStyle>
    );
};

export default Backdrop;
