import * as React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Backdrop from '../atoms/Backdrop';
import { CrossIcon } from '../atoms/icons/CrossIcon';
import { MediaQuery } from '../helpers';

const CrossIconSection = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

const CrossIconButton = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        filter: brightness(40%);
    }
`;

const ModalStyle = styled(motion.div)`
    background-color: white;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 32px;
    border-radius: 12px;
    ${({ fixedWidth }) => fixedWidth && 'width: 50%;'}
    max-width: 1170px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    height: ${({ height }) => height ?? 'auto'};
    max-height: 90vh;
    overflow-y: auto;

    ${MediaQuery.XS} {
        max-width: 80vw;
        max-height: 80vh;
        min-width: 340px;
    }
`;

const dropIn = {
    hidden: {
        y: '-100%',
        opacity: 0,
    },
    visible: { y: 0, opacity: 1 },
    exit: {
        y: '100%',
        opacity: 0,
    },
};

const Modal = ({ children, onClose, open, closeIcon, backdropColor, fixedWidth = true, height }) => {
    return (
        <>
            <Backdrop onClick={onClose} backdropcolor={backdropColor ?? '#0000007f'}>
                <ModalStyle
                    onClick={(e) => e.stopPropagation()}
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    fixedWidth={fixedWidth}
                    height={height}
                >
                    {closeIcon && (
                        <CrossIconSection id="close">
                            <CrossIconButton onClick={onClose}>
                                <CrossIcon color="#141B34" />
                            </CrossIconButton>
                        </CrossIconSection>
                    )}
                    {children}
                </ModalStyle>
            </Backdrop>
        </>
    );
};

export default Modal;
