import * as React from 'react';
import Modal from '../molecules/Modal';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import BackButton from '../atoms/buttons/BackButton';
import Title from '../atoms/typography/Title';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/Spacers';
import Body from '../atoms/typography/Body';
import GradientButtonDark from '../atoms/buttons/GradientButtonDark';
import { RecipientIllustration, RecipientIllustrationSmall } from '../atoms/illustrations/RecipientIllustration';
import { ProgrammerIllustration, ProgrammerIllustrationSmall } from '../atoms/illustrations/ProgrammerIllustration';
import { CompletionIllustration, CompletionIllustrationSmall } from '../atoms/illustrations/CompletionIllustration';
import { CircleActivityIndicator } from '../atoms/icons/CircleActivityIndicator';
import { theme } from '../theme';
import ForwardButton from '../atoms/buttons/ForwardButton';

const AutobookInfoModalContent = ({ open, onClose, onClickActivateAutobook }) => {
    const [slideIndex, setSlideIndex] = React.useState(0);

    const onClickModalSlideBack = () => {
        if (slideIndex === 0) {
            setSlideIndex(2);
        } else {
            setSlideIndex(slideIndex - 1);
        }
    };
    const onClickModalSlideForward = () => {
        if (slideIndex === 2) {
            setSlideIndex(0);
        } else {
            setSlideIndex(slideIndex + 1);
        }
    };

    return (
        <div>
            <Modal open={open} onClose={onClose} closeIcon fixedWidth={false}>
                <Visible xs>
                    <Row justify="center">
                        {slideIndex === 0 && <RecipientIllustrationSmall />}
                        {slideIndex === 1 && <ProgrammerIllustrationSmall />}
                        {slideIndex === 2 && <CompletionIllustrationSmall />}
                    </Row>
                </Visible>
                <Row align="center">
                    <Hidden xs>
                        <Col lg={1}>
                            <BackButton onClick={onClickModalSlideBack} />
                        </Col>
                    </Hidden>
                    {slideIndex === 0 && (
                        <>
                            <Col lg={5}>
                                <Title>Vad är autobook?</Title>
                                <HorizontalSpacer spacing={1} />
                                <Body>
                                    Autobook är en tjänst som möjliggör automatisk bokföring i ditt bokföringssystem. Genom att aktivera Autobook kommer
                                    fakturorna som du har sålt till Factoringgruppen att bokföras automatiskt.
                                    <br />
                                    <br />
                                    Autobook är helt enkelt ett sätt att slippa stress och krångel genom att minska ditt administrativa arbete.{' '}
                                </Body>
                            </Col>
                            <Hidden xs>
                                <Col lg={5}>
                                    <RecipientIllustration />
                                </Col>
                            </Hidden>
                        </>
                    )}
                    {slideIndex === 1 && (
                        <>
                            <Col lg={5}>
                                <Title>Hur fungerar det?</Title>
                                <HorizontalSpacer spacing={1} />
                                <Body>
                                    Autobook kopplas till ditt bokföringssystem och bokför automatiskt fakturorna som du säljer till Factoringgruppen. Du kan
                                    följa dina fakturor och avräkningar på Mina sidor. Om du vill att de ska bokföras på andra konton än de förvalda så kan du
                                    ändra det under Inställningar.  
                                </Body>
                            </Col>
                            <Hidden xs>
                                <Col lg={5}>
                                    <ProgrammerIllustration />
                                </Col>
                            </Hidden>
                        </>
                    )}
                    {slideIndex === 2 && (
                        <>
                            <Col lg={5}>
                                <Title>Aktivera Autobook!</Title>
                                <HorizontalSpacer spacing={1} />
                                <Body>
                                    Så här gör du för att aktivera Autobook: 
                                    <ul>
                                        <li>Logga in på Mina sidor. </li>
                                        <li>Gå till "Autobook" och välj "Aktivera automatisk bokföring".  </li>
                                        <li>Följ anvisningarna för att koppla ditt bokföringssystem.</li>
                                        <li>
                                            När tjänsten är aktiverad bokförs dina fakturor automatiskt. Om något inte kan bokföras, får du ett meddelande och
                                            kan enkelt lösa det.
                                        </li>
                                    </ul>
                                </Body>

                                <HorizontalSpacer spacing={1} />
                                {onClickActivateAutobook && <GradientButtonDark title="Aktivera automatisk bokföring" onClick={onClickActivateAutobook} />}
                            </Col>
                            <Hidden xs>
                                <Col lg={5}>
                                    <CompletionIllustration />
                                </Col>
                            </Hidden>
                        </>
                    )}
                    <Hidden xs>
                        <Col lg={1}>
                            <ForwardButton onClick={onClickModalSlideForward} />
                        </Col>
                    </Hidden>
                </Row>
                <HorizontalSpacer spacing={1} />
                <Visible xs>
                    <Row justify="center">
                        <BackButton onClick={onClickModalSlideBack} />
                        <VerticalSpacer spacing={1} />
                        <ForwardButton onClick={onClickModalSlideForward} />
                    </Row>
                    <HorizontalSpacer spacing={1} />
                </Visible>
                <Row justify="center">
                    <CircleActivityIndicator small color={slideIndex === 0 ? theme.colors.black : theme.colors.textMuted} />
                    <VerticalSpacer spacing={1} />
                    <CircleActivityIndicator small color={slideIndex === 1 ? theme.colors.black : theme.colors.textMuted} />
                    <VerticalSpacer spacing={1} />
                    <CircleActivityIndicator small color={slideIndex === 2 ? theme.colors.black : theme.colors.textMuted} />
                </Row>
            </Modal>
        </div>
    );
};

export default AutobookInfoModalContent;
