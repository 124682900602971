import * as React from 'react';
import Title from '../atoms/typography/Title';
import Body from '../atoms/typography/Body';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/Spacers';
import { Hidden, Row, Visible } from 'react-grid-system';
import { AutobookStatus } from './Settlement';
import GradientButtonDark from '../atoms/buttons/GradientButtonDark';
import GradientButtonLight from '../atoms/buttons/GradientButtonLight';
import Checkbox from '../atoms/inputs/Checkbox';
import Tooltip from '../molecules/Tooltip';
import { InfoIcon } from '../atoms/icons/InfoIcon';
import BodySmall from '../atoms/typography/BodySmall';
import { theme } from '../theme';
import { formatPrice } from '../helpers';
import dayjs from 'dayjs';
import { DownloadIcon } from '../atoms/icons/DownloadIcon';
import { trackMixpanelEvent } from '../App';
import { useSettlementDocument } from '../graphql/hooks/useSettlementDocument';
import { useSieFile } from '../graphql/hooks/useSieFile';
import { useMarkSettlementAsManuallyBooked } from '../graphql/hooks/useMarkSettlementAsManuallyBooked';
import Spinner from '../global/Spinner';
import SettlementDataAccounting from '../molecules/SettlementDataAccounting';
import { useResyncFailedSettlement } from '../graphql/hooks/useResyncFailedSettlement';

const SettlementContent = ({ settlementId, autobookActivated, settlement }) => {
    const [bookedManually, setBookedManually] = React.useState(settlement.exportStatus === 'MANUALLY_BOOKED');

    const commitment = JSON.parse(localStorage.getItem('commitment') ?? '{}');
    const clientIdentifier = commitment.organisationNumber ?? '';

    const onClickDownloadSie = async () => {
        if (!sieFile?.data) {
            console.error('No file data available');
            return;
        }

        trackMixpanelEvent('Downloaded SIE', { settlementId });

        //Convert Base64 to a Blob
        const byteCharacters = atob(sieFile.data);
        const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });

        //Create a temporary download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Avräkning-${settlement.factoringSystemSettlementId}.si`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
    };

    const onClickDownloadPdf = () => {
        if (!settlementDocument?.data) {
            console.error('No PDF data available');
            return;
        }

        trackMixpanelEvent('Downloaded PDF', { settlementId });

        //Convert Base64 to a Blob
        const byteCharacters = atob(settlementDocument.data);
        const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        //Create a temporary download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Avräkning-${settlement.factoringSystemSettlementId}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        URL.revokeObjectURL(url);
    };

    const { settlementDocument, error, loading } = useSettlementDocument(clientIdentifier, settlementId);
    const { sieFile, error: sieFileError, loading: sieFileLoading } = useSieFile(clientIdentifier, settlementId);

    const onCompletedManuallyBooked = () => {
        if (data?.success) {
            setBookedManually(!bookedManually);
        }
    };
    const onErrorManuallyBooked = () => {
        console.log('Something went wrong');
    };
    const {
        markSettlementAsManuallyBooked,
        data,
        error: manuallyBookedError,
        loading: manuallyBookedLoading,
    } = useMarkSettlementAsManuallyBooked({ onCompleted: onCompletedManuallyBooked, onError: onErrorManuallyBooked });

    const onMarkAsManuallyBooked = async () => {
        try {
            await markSettlementAsManuallyBooked({
                variables: {
                    input: {
                        settlementId: settlementId,
                        clientIdentifier: clientIdentifier,
                    },
                },
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    const onCompletedResyncFailedSettlement = () => {
        if (resyncData?.success) {
            console.log('uppdaterades framgångsrikt');
        }
    };
    const onErrorResyncFailedSettlement = () => {
        console.log('error');
    };
    const {
        resyncFailedSettlement,
        data: resyncData,
        error: resyncError,
        loading: resyncLoading,
    } = useResyncFailedSettlement({ onCompleted: onCompletedResyncFailedSettlement, onError: onErrorResyncFailedSettlement });

    const onResyncFailedSettlement = async () => {
        try {
            await resyncFailedSettlement({
                variables: {
                    input: {
                        settlementId: settlementId,
                        clientIdentifier: clientIdentifier,
                    },
                },
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    console.log(settlement);

    return (
        <div>
            <div>
                <Title color="white">{formatPrice(settlement?.payoutAmount)} kr</Title>
                <Body color="white">{dayjs(settlement?.createdAt).format('YYYY-MM-DD')}</Body>
                <HorizontalSpacer spacing={1} />
                <Visible xs>
                    {settlement?.exportStatus === 'Autobooked' && (
                        <div>
                            <AutobookStatus status={settlement?.exportStatus} />
                            <HorizontalSpacer spacing={1} />
                        </div>
                    )}
                </Visible>
                <Row nogutter align="center">
                    <Hidden xs>
                        <GradientButtonDark title="Ladda ner PDF" icon={<DownloadIcon color="white" />} onClick={onClickDownloadPdf} />
                        <VerticalSpacer spacing={1} />
                        <GradientButtonDark title="Ladda ner SIE" icon={<DownloadIcon color="white" />} onClick={onClickDownloadSie} />
                        <VerticalSpacer spacing={1} />
                        <Visible sm>
                            <HorizontalSpacer spacing={1} />
                        </Visible>
                        {settlement?.exportStatus === 'EXPORT_FAILED' && settlement?.failedExportCount < 3 && (
                            <GradientButtonLight title="Testa synka igen" onClick={onResyncFailedSettlement} />
                        )}
                        <Visible md lg>
                            <HorizontalSpacer spacing={1} />
                        </Visible>
                        {settlement?.exportStatus === 'EXPORTED' && <AutobookStatus status={settlement?.exportStatus} />}
                        {settlement?.exportStatus === 'MANUALLY_BOOKED' && <AutobookStatus status={settlement?.exportStatus} />}
                        {settlement?.exportStatus === 'EXPORT_FAILED' && <AutobookStatus status={settlement?.exportStatus} />}
                        {resyncLoading && <BodySmall color={theme.colors.textSecondary}>Synkar...</BodySmall>}
                        <VerticalSpacer spacing={1} />
                    </Hidden>
                    <Visible xs>
                        <GradientButtonDark title="PDF" icon={<DownloadIcon color="white" />} onClick={onClickDownloadPdf} />
                        <VerticalSpacer spacing={1} />
                        <GradientButtonDark title="SIE" icon={<DownloadIcon color="white" />} onClick={onClickDownloadSie} />
                    </Visible>
                </Row>
                {!sieFile?.isBalanced && <BodySmall color={theme.colors.warning}>OBS! Avräkningen är inte balanserad.</BodySmall>}
                <Visible xs>
                    <HorizontalSpacer spacing={1} />
                    {settlement?.exportStatus === 'EXPORT_FAILED' && <AutobookStatus status={settlement?.exportStatus} />}
                    {settlement?.exportStatus === 'EXPORTED' && <AutobookStatus status={settlement?.exportStatus} />}
                    {settlement?.exportStatus === 'MANUALLY_BOOKED' && <AutobookStatus status={settlement?.exportStatus} />}
                    <HorizontalSpacer spacing={1} />
                    {settlement?.exportStatus === 'EXPORT_FAILED' && settlement?.failedExportCount < 3 && (
                        <GradientButtonLight title="Testa synka igen" onClick={onResyncFailedSettlement} />
                    )}
                    {resyncLoading && <BodySmall color={theme.colors.textSecondary}>Synkar...</BodySmall>}
                </Visible>
                {settlement?.exportStatus !== 'EXPORTED' && settlement?.exportStatus !== 'MANUALLY_BOOKED' && (
                    <div>
                        <HorizontalSpacer spacing={2} />
                        <Row nogutter align="center">
                            {manuallyBookedLoading ? (
                                <Spinner />
                            ) : (
                                <Checkbox
                                    title="Markera som manuellt bokförd"
                                    checked={bookedManually}
                                    onChange={onMarkAsManuallyBooked}
                                    color="white"
                                    disabled={bookedManually}
                                />
                            )}
                            <VerticalSpacer spacing={2} />
                            <Tooltip text="När du bokfört avräkningen i ditt bokföringssystem kan du markera den som manuellt bokförd här för att ta bort varningen om att avräkningen inte kunde bokföras.">
                                <InfoIcon color="white" />{' '}
                            </Tooltip>
                        </Row>
                    </div>
                )}

                <SettlementDataAccounting settlementId={settlementId} clientIdentifier={clientIdentifier} />

                <HorizontalSpacer spacing={1} />
                {autobookActivated && (
                    <BodySmall color={theme.colors.textSecondary}>
                        Du har aktiverat automatisk bokföring. Det innebär att dina fakturor bokförs automatiskt när vi betalar ut pengarna till dig. Ladda ned
                        filerna för att se underlag och status på dina avräkningar. Du kan ändra dina inställningar för automatisk bokföring{' '}
                        <u>
                            <a href="/autobook">
                                <b>här.</b>
                            </a>
                        </u>
                         
                    </BodySmall>
                )}
            </div>
        </div>
    );
};

export default SettlementContent;
