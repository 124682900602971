import { of, Subject, empty, concat } from 'rxjs';
import { map, switchMap, mergeMap, merge, catchError } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { InvoiceRecipientPairing } from '../global/InvoiceRecipientPairing';
import {
    // Upload
    UPLOAD_UPLOAD_FILE,
    UPLOAD_REMOVE_FROM_LIST,
    UPLOAD_FIND_EXISTING_UPLOADS,
    UPLOAD_GET_RESULT,
    UPLOAD_UPLOAD_SUCCESS,
    UPLOAD_UPLOAD_ATTACHMENT,
    UPLOAD_REMOVE_ATTACHMENT,
    UPLOAD_INVOICES_TRANSFER,
    uploadUpdate,
    uploadUploadProgress,
    uploadUploadFailure,
    uploadUploadSuccess,
    uploadUpdatedFileList,
    uploadGetResult,
    uploadInvoicesTransferFailure,
    // Recipient
    RECIPIENTS_UPDATE,
    uploadAddAttachment,
    uploadRemovedAttachment,
} from '../actions';

// Private
let url = `${window.location.origin}/`;
if (process.env.NODE_ENV === 'development') {
    url = process.env.REACT_APP_INTERPRETER_API_URL;
}

// Finds index of particular file in store based on interpretationID
const findIndex = (files, interpretationId) => {
    let index = files.findIndex((fileObj) => {
        if (fileObj.invoice && fileObj.invoice.interpretationId) {
            return fileObj.invoice.interpretationId === interpretationId;
        }
        if (fileObj.invoice && fileObj.invoice.id) {
            return fileObj.invoice.id === interpretationId;
        }
        if (fileObj.interpretationId) {
            return fileObj.interpretationId === interpretationId;
        }
        if (fileObj.id) {
            return fileObj.id === interpretationId;
        }
        return false;
    });

    return index;
};

// Public
export const uploadUploadFileListEpic = (action$, store) =>
    action$.ofType(UPLOAD_UPLOAD_FILE).pipe(
        map(({ payload }) => {
            return payload;
        }),
        mergeMap((payload) => {
            let fileObj = payload;
            let fileExtension = fileObj.file.name.split('.').pop();
            let fileName = fileObj.file.name.replace(fileExtension, fileExtension.toLowerCase());
            fileObj.isHandled = true;

            Object.defineProperty(fileObj.file, 'name', {
                value: fileName,
                writable: true,
            });

            // Create a subject in order to trigger progress events for ajax observable
            const progressSubscriber = new Subject();
            // Setup ajax basics in request
            const request = ajax({
                method: 'POST',
                url: `${url}api/interpreter-service/upload/submit`,
                body: fileObj.file,
                headers: {
                    Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                    'organisation-number': store.value.organisation.organisationNumber,
                    'product-type': store.value.organisation.productType,
                    'Content-Type': 'application/octet-stream',
                    'mime-type': fileObj.file.type,
                    'file-name': encodeURI(fileObj.file.name),
                },
                progressSubscriber, // This is triggered as a progress event
            }).pipe(
                catchError((error) =>
                    of(
                        uploadUploadFailure({
                            ...fileObj,
                            percentage: 100,
                            uploadDone: true,
                            success: false,
                            error: error,
                        })
                    )
                )
            );

            // Define what happens when file has been successfully uploaded, i.e. tell store that file has been uploaded and everything is good...
            const requestObservable = request.pipe(
                map((response) => response.response),
                switchMap((response) => {
                    return of(
                        uploadUploadSuccess({
                            ...fileObj,
                            percentage: 100,
                            uploadDone: false,
                            invoice: { ...response },
                            interpretationId: response.interpretationId,
                            success: true,
                            error: false,
                            sell: true,
                            email: '',
                            comment: '',
                            transfer: true,
                        })
                    );
                }),
                catchError((error) => {
                    // ... or everything is bad.
                    return of(
                        uploadUploadFailure({
                            ...fileObj,
                            percentage: 100,
                            uploadDone: true,
                            success: false,
                            error: error,
                        })
                    );
                })
            );
            // This epic should return the subject though merged with the request itself.
            // Use this as a progress event, so map it to what we need and tell store what's happening.
            // On fast connections this won't be noticed, but on slow ones it sure will. Progress bars for the win!
            return progressSubscriber.pipe(
                map((e) => ({ ...fileObj, percentage: Math.floor((e.loaded / e.total) * 100) })),
                map((updatedFileObj) => {
                    return uploadUploadProgress({ ...updatedFileObj });
                }),
                catchError((error) =>
                    of(
                        uploadUploadFailure({
                            ...fileObj,
                            percentage: 100,
                            uploadDone: true,
                            success: false,
                            error: error,
                        })
                    )
                ),
                merge(requestObservable)
            );
        }),
        catchError((error) => of(uploadUploadFailure({ success: false, error: error })))
    );

export const uploadUploadAttachmentListEpic = (action$, store) =>
    action$.ofType(UPLOAD_UPLOAD_ATTACHMENT).pipe(
        map(({ payload }) => {
            return payload;
        }),
        mergeMap((payload) => {
            let fileObj = payload;
            let fileExtension = fileObj.file.name.split('.').pop();
            let fileName = fileObj.file.name.replace(fileExtension, fileExtension.toLowerCase());

            Object.defineProperty(fileObj.file, 'name', {
                value: fileName,
                writable: true,
            });

            // Setup ajax basics in request
            const request = ajax({
                method: 'POST',
                url: `${url}api/interpreter-service/upload/attachment`,
                body: fileObj.file,
                headers: {
                    Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                    'organisation-number': store.value.organisation.organisationNumber,
                    'product-type': store.value.organisation.productType,
                    'Content-Type': 'application/octet-stream',
                    'mime-type': fileObj.file.type,
                    'file-name': encodeURI(fileObj.file.name),
                    id: payload.id,
                }, // This is triggered as a progress event
            }).pipe(
                catchError((error) =>
                    of(
                        uploadAddAttachment({
                            attachmentErrorText: 'Ett fel inträffade vid uppladdning',
                        })
                    )
                )
            );

            return request.pipe(
                map((response) => response),
                switchMap((response) => {
                    return of(
                        uploadAddAttachment({
                            ...fileObj,
                        })
                    );
                }),
                catchError((error) => {
                    // ... or everything is bad.
                    return of(
                        uploadAddAttachment({
                            attachmentErrorText: 'Ett fel inträffade vid uppladdning',
                        })
                    );
                })
            );
        }),
        catchError((error) => of(uploadAddAttachment({ attachmentErrorText: 'Ett fel inträffade vid uppladdning' })))
    );

export const uploadUploadRemoveAttachmentEpic = (action$, store) =>
    action$.ofType(UPLOAD_REMOVE_ATTACHMENT).pipe(
        map(({ payload }) => {
            return payload;
        }),
        mergeMap((payload) => {
            // Setup ajax basics in request
            const request = ajax({
                method: 'POST',
                url: `${url}api/interpreter-service/upload/removeattachment`,
                body: { interpretationId: payload.interpretationId, fileName: payload.fileName },
                headers: {
                    Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                    'organisation-number': store.value.organisation.organisationNumber,
                    'Content-Type': 'application/json',
                },
            }).pipe(
                catchError((error) =>
                    of(
                        uploadAddAttachment({
                            attachmentErrorText: 'Ett fel inträffade vid borttagning',
                        })
                    )
                )
            );

            return request.pipe(
                map((response) => response.response),
                switchMap((response) => {
                    return of(
                        uploadRemovedAttachment({
                            ...payload,
                        })
                    );
                }),
                catchError((error) => {
                    // ... or everything is bad.
                    return of(
                        uploadAddAttachment({
                            attachmentErrorText: 'Ett fel inträffade vid borttagning',
                        })
                    );
                })
            );
        }),
        catchError((error) => of(uploadUploadFailure({ success: false, error: error })))
    );

export const uploadFindExistingUploadsEpic = (action$, store) =>
    action$.ofType(UPLOAD_FIND_EXISTING_UPLOADS).pipe(
        mergeMap((action) => {
            // Gets all existing uploads and creates getters of result/details of those files
            return concat(
                of(uploadUpdate({ isLoading: true })),
                ajax({
                    method: 'GET',
                    url: `${url}api/interpreter-service/upload/getinvoicedrafts`,
                    headers: {
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    },
                }).pipe(
                    map((response) => response.response),
                    switchMap((response) => {
                        let resultGetters = [];
                        if (response) {
                            // For each file in response...
                            response = response.map((file) => {
                                // Find index and see if we already have that file in our list
                                let index = findIndex(store.value.upload.files, file.interpretationRequestId);
                                let alredyExists = index > -1;
                                let existingFile = {};
                                if (!alredyExists) {
                                    // This is a new file, schedule a getter to find out more about it
                                    resultGetters.push(of(uploadGetResult(file.interpretationRequestId)));
                                } else {
                                    // Nothing of interest. Return the file as is.
                                    existingFile = store.value.upload.files[index];
                                    return existingFile;
                                }
                                // Create a file object for the new file and give it the data it needs in order to be displayed properly
                                return {
                                    file: {
                                        name: file.fileName,
                                    },
                                    timestamp: new Date(file.timestamp),
                                    createdBy: file.createdBy,
                                    percentage: 100,
                                    uploadDone: alredyExists,
                                    interpretationId: file.interpretationRequestId,
                                    success: true,
                                    sell: true,
                                    email: '',
                                    comment: '',
                                    transfer: true,
                                    attachments: file.attachments,
                                };
                            });
                            // Go get everything we need for new files, and tell store about updates.
                            return concat(...resultGetters, of(uploadUpdate({ isLoading: false })), of(uploadUpdatedFileList([...response])));
                        }
                        return concat(of(uploadUpdate({ isLoading: false })));
                    }),
                    catchError((error) => {
                        return of(uploadUpdate({ isLoading: false }));
                    })
                )
            );
        })
    );

export const uploadGetResultEpic = (action$, store) =>
    action$.ofType(UPLOAD_GET_RESULT).pipe(
        map(({ payload }) => payload),
        mergeMap((pollId) => {
            // Simple getter of file information.
            return ajax
                .get(`${url}api/interpreter-service/upload/result/${pollId}`, {
                    Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                    'organisation-number': store.value.organisation.organisationNumber,
                    'Content-Type': 'application/json',
                })
                .pipe(
                    mergeMap((response) => {
                        let index = findIndex(store.value.upload.files, pollId);
                        if (response.status === 204 || !response.response || !store.value.upload.files[index]) {
                            return empty();
                        }

                        // Set cache keys to new array and populate them with the ones from response
                        index = findIndex(store.value.upload.files, pollId);
                        store.value.upload.files[index].cacheKeys = [];
                        if (response.response.cacheKeys && response.response.cacheKeys.length > 0) {
                            store.value.upload.files[index].cacheKeys = [...response.response.cacheKeys];
                        }
                        store.value.upload.files[index].createdBy = response.response.createdBy;

                        return concat(
                            of(
                                uploadUploadSuccess({
                                    ...store.value.upload.files[index],
                                    uploadDone: true,
                                    isLoading: false,
                                    success: true,
                                    sell: true,
                                    email: '',
                                    comment: '',
                                    transfer: true,
                                })
                            )
                        );
                    }),
                    catchError((error) => {
                        // Something went wrong. Display error and message
                        let index = findIndex(store.value.upload.files, pollId);
                        return concat(
                            of(
                                uploadUploadFailure({
                                    ...store.value.upload.files[index],
                                    uploadDone: true,
                                    isLoading: false,
                                    success: false,
                                    error: error,
                                    errorText:
                                        'Kunde inte hämta information om fakturan. Detta kan bero på nätverksproblem eller att våra servrar inte svarar. Ta bort faktura och testa att ladda upp den igen, eller kontakta oss på kund@fg.se för mer hjälp.',
                                    loadingText: undefined,
                                })
                            )
                        );
                    })
                );
        })
    );

export const uploadRemoveFromListEpic = (action$, store) =>
    action$.ofType(UPLOAD_REMOVE_FROM_LIST).pipe(
        map(({ payload }) => {
            return payload;
        }),
        mergeMap((payload) => {
            // Triggers removal of item in list of uploaded file.
            let index = payload.index;
            let interpretationId = null;
            if (payload.file) {
                interpretationId = payload.file.interpretationId ? payload.file.interpretationId : payload.file.invoice ? payload.file.invoice.id : null;
                if (interpretationId) {
                    index = findIndex(store.value.upload.files, interpretationId);
                }
            }

            store.value.upload.files.splice(index, 1);
            if (!interpretationId) {
                return concat(of(uploadUpdatedFileList(store.value.upload.files)));
            }

            return concat(
                of(uploadUpdatedFileList(store.value.upload.files)),
                ajax
                    .post(
                        `${url}api/interpreter-service/upload/remove`,
                        {
                            invoiceInterpretationId: interpretationId,
                        },
                        {
                            Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                            'organisation-number': store.value.organisation.organisationNumber,
                            'Content-Type': 'application/json',
                        }
                    )
                    .pipe(
                        switchMap((response) => {
                            return empty();
                        }),
                        catchError((error) => {
                            // TODO: Handle error!
                            return empty();
                        })
                    )
            );
        })
    );

export const uploadUploadSuccessEpic = (action$, store) =>
    action$.ofType(UPLOAD_UPLOAD_SUCCESS, RECIPIENTS_UPDATE).pipe(
        mergeMap((action) => {
            store = InvoiceRecipientPairing(store);
            return of(uploadUpdate(store.value.upload));
        })
    );

export const uploadInvoicesTransferEpic = (action$, store) =>
    action$.ofType(UPLOAD_INVOICES_TRANSFER).pipe(
        switchMap((action) => {
            let remainingfiles = [...store.value.upload.files];
            const files = action.payload.map((f) => {
                return {
                    InterpretationId: f.interpretationId,
                    Comment: f.comment,
                    EmailRecipient: f.email,
                    Sell: f.sell,
                };
            });
            const request = {
                OrganizationNumber: store.value.organisation.organisationNumber,
                InvoiceDocuments: files,
            };
            files.forEach((file) => {
                let index = findIndex(remainingfiles, file.InterpretationId);
                remainingfiles.splice(index, 1);
            });
            return concat(
                of(
                    uploadUpdate({
                        transfer: { transfering: true, success: false },
                    })
                ),
                ajax
                    .post(`${url}api/interpreter-service/upload/saveAndResume`, request, {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${store.value.oidc.user.access_token}`,
                        'organisation-number': store.value.organisation.organisationNumber,
                    })
                    .pipe(
                        switchMap((response) => {
                            return concat(of(uploadUpdatedFileList(remainingfiles)), of(uploadUpdate({ transfer: { transfering: false, success: true } })));
                        })
                    )
            );
        }),
        catchError((error) => {
            return concat(
                of(
                    uploadInvoicesTransferFailure({
                        transfering: false,
                        success: false,
                        error: {
                            message:
                                'Något gick fel när fakturorna skulle överföras. Detta kan bero på nätverksfel eller att något gick fel i våra system. Vänligen försök igen.',
                            type: 'HANDLETRANSFERINVOICEERROR',
                        },
                    })
                )
            );
        })
    );
