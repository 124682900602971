import { useMutation } from '@apollo/client';
import { UPDATE_CLIENT_SETTINGS } from '../mutations/updateClientSettings';
import GET_ISSUER_SETTINGS from '../queries/getClientIssuerSettings';

export const useUpdateClientSettings = ({ onCompleted, onError }) => {
    const [updateClientSettings, { data, loading, error }] = useMutation(UPDATE_CLIENT_SETTINGS, {
        onCompleted: onCompleted,
        onError: onError,
        refetchQueries: [GET_ISSUER_SETTINGS],
        awaitRefetchQueries: true,
    });

    return { updateClientSettings, data, loading, error };
};
