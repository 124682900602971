import { useQuery } from '@apollo/client';
import GET_ACCOUNT_PLAN from '../queries/getAccountPlan';

export const useAccountPlan = (clientIdentifier) => {
    const { data, error, loading, refetch } = useQuery(GET_ACCOUNT_PLAN, {
        variables: { clientIdentifier: clientIdentifier },
        onError: (error) => console.log('error: ', error),
    });
    return { accountPlan: data?.accountPlan, error, loading, refetch };
};
