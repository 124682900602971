import React, { Component } from 'react';
import { connect } from 'react-redux';

import Spinner from '../../global/Spinner';

import NotificationSettingsItem from './components/NotificationSettingsItem';
import UserSettingsDetail from './components/UserSettingsDetail';
import PushNotification from '../../global/modals/PushNotification';

import { getUserSettings, getNotificationSettings, getIntegrationSettings, saveIntegrationSettings, modalTrigger } from '../../actions';
import SelectAccountsComponent from '../../molecules/SelectAccountsComponent';
import { HorizontalSpacer } from '../../atoms/Spacers';
import { Container } from 'react-grid-system';
import FgBookBanner from '../../molecules/FgBookBanner';

export class UserSettingsPage extends Component {
    constructor(props) {
        super(props);

        props.getUserSettings();
        props.getNotificationSettings();
        props.getIntegrationSettings();
    }

    componentDidUpdate(prevProps) {
        if (this.props.organisation && prevProps.organisation && this.props.organisation.name !== prevProps.organisation.name) {
            this.props.getNotificationSettings();
            this.props.getUserSettings();
            this.props.getIntegrationSettings();
        }
    }

    activatePush() {
        this.props.modalTrigger(true, PushNotification, true);
    }

    setEmailIntegration(value) {
        this.props.saveIntegrationSettings({
            chosenIntegrationType: 'Email',
            chosenAction: value,
        });
    }

    render() {
        return (
            <>
                <div className="content__body settings">
                    <div className="content__main">
                        <Container style={{ padding: 16 }}>
                            <HorizontalSpacer spacing={2} />
                            <FgBookBanner />
                        </Container>
                        <div className="content__wrapper content__wrapper--stuffing">
                            <h1 className="title title--h1">Mina inställningar för {this.props.organisation.name}</h1>
                            <UserSettingsDetail user={this.props.settings.user} />
                            {this.props.settings.integration.partnerType === 'Bygglet' &&
                            (this.props.settings.integration.activeIntegration === 'Unknown' ||
                                this.props.settings.integration.activeIntegration === 'Email') ? (
                                <div className="settings__email-integration">
                                    <h2 className="title title--h3">Inställningar för e-post integration</h2>
                                    {this.props.settings.integration.isLoading ? (
                                        <Spinner />
                                    ) : (
                                        <div className="integration">
                                            <p className="caption">
                                                Här kan du ändra hur FG ska hantera fakturor som du skickar till oss via våran e-post integration. Vill du veta
                                                mer om hur tjänsten fungerar så kontakta oss på{' '}
                                                <a href="mailto:onboarding@fg.se" className="link">
                                                    onboarding@fg.se
                                                </a>{' '}
                                                eller ring{' '}
                                                <a href="tel:0317199920" className="link">
                                                    031-719 99 20
                                                </a>
                                            </p>

                                            <div className="selection-list">
                                                <div
                                                    className={`selection-content ${
                                                        this.props.settings.integration.chosenAction === 'Manual' ? 'selected' : ''
                                                    }`}
                                                    onClick={this.setEmailIntegration.bind(this, 'Manual')}
                                                >
                                                    <div className="content">
                                                        <div className="inner-content">
                                                            <h4 className="title title--h4">Välj själv</h4>
                                                            <div className="caption">
                                                                När du mailat din faktura från ditt affärssystem så finns den här i mina sidor under "Sälj
                                                                faktura/Uppladdade fakturor". Du väljer själv om den skall finansieras eller bara bevakas. Du
                                                                kan även ta bort de fakturor du vill hantera helt själv.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`selection-content ${
                                                        this.props.settings.integration.chosenAction === 'AutopilotSell' ? 'selected' : ''
                                                    }`}
                                                    onClick={this.setEmailIntegration.bind(this, 'AutopilotSell')}
                                                >
                                                    <div className="content">
                                                        <div className="inner-content">
                                                            <h4 className="title title--h4">Sälj</h4>
                                                            <div className="caption">
                                                                Luta dig tillbaka och var säker på att inga fakturor missas. Så snart din faktura är mailad till
                                                                oss börjar den hanteras. För detta alternativ behöver du <b>inte</b> logga in på mina sidor för
                                                                att göra ytterligare val då vi kommer försöka finansiera dessa per automatik.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : null}
                            <h2 className="title title--h3">Inställningar för notifieringar</h2>
                            {this.props.push.hasSavedUserToken ? null : (
                                <div className="settings__ask-notification">
                                    <p className="caption">
                                        {this.props.push.mutePush
                                            ? 'Vi stödjer inte notifieringar för din enhet'
                                            : 'Du har inte aktiverat notifieringar på den här enheten'}
                                    </p>
                                    <button
                                        disabled={this.props.push.mutePush}
                                        className={
                                            this.props.push.mutePush
                                                ? 'button button--right button--disabled button--function'
                                                : 'button button--right button--function'
                                        }
                                        onClick={this.activatePush.bind(this)}
                                    >
                                        Aktivera
                                    </button>
                                </div>
                            )}
                            {this.props.settings.notification.isLoading ? (
                                <div className="settings__list">
                                    <Spinner />
                                </div>
                            ) : (
                                <ul className="settings__list">
                                    {this.props.settings.notification.list
                                        ? this.props.settings.notification.list.map((setting, index) => {
                                              return <NotificationSettingsItem key={`${index}`} setting={setting} index={index} />;
                                          })
                                        : null}
                                </ul>
                            )}

                            <SelectAccountsComponent id="selectAccount" />

                            <HorizontalSpacer spacing={2} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.settings,
        organisation: state.organisation,
        push: state.pushNotification,
    };
};

const mapDispatchToProps = { getUserSettings, getNotificationSettings, getIntegrationSettings, saveIntegrationSettings, modalTrigger };

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsPage);
