import React, { useMemo, useCallback, useState } from 'react';
import { Hidden, Row, Visible } from 'react-grid-system';
import styled from 'styled-components';
import { theme } from '../theme';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/Spacers';
import BodySmall from '../atoms/typography/BodySmall';
import Small from '../atoms/typography/Small';
import MonthSelect from '../atoms/inputs/MonthSelect';
import DayPickerInput from '../global/inputs/DayPickerInput';
import { ChevronDownIcon, ChevronUpIcon } from '../atoms/icons/Chevrons';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';

dayjs.locale('sv');

const DateCircle = React.memo(({ date, isActive, hasFailedSettlement, hasSuccessfulSettlement, onClick }) => (
    <StyledDateCircle
        active={isActive}
        onClick={onClick}
        hasFailedSettlement={hasFailedSettlement}
        hasSuccessfulSettlement={hasSuccessfulSettlement}
    >
        <BodySmall color="white">{date.date()}</BodySmall>
    </StyledDateCircle>
));

const StyledDateCircle = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ active }) => (active ? theme.colors.success : 'transparent')};
    border: ${({ hasSuccessfulSettlement, hasFailedSettlement }) =>
        hasSuccessfulSettlement ? `2px solid ${theme.colors.success}` : hasFailedSettlement ? `2px dashed ${theme.colors.error}` : undefined};
    margin: 3px;

    &:hover {
        cursor: pointer;
        filter: opacity(0.6);
    }
`;

const SmallDateCircle = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'active' && prop !== 'hasFailedSettlement' && prop !== 'hasSuccessfulSettlement',
})`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ active }) => (active ? theme.colors.success : 'transparent')};
    border: ${({ hasSuccessfulSettlement, hasFailedSettlement }) =>
    hasSuccessfulSettlement ? `2px solid ${theme.colors.success}` : hasFailedSettlement ? `2px dashed ${theme.colors.error}` : undefined};
    margin: 2px 4px;

    @media (max-width: 768px) {
        margin-left: 0;
    }
`;

const DayPickerWrapper = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;

    @media (max-width: 576px) {
        width: 100%;
    }
`;

const ResponsiveRow = styled(Row)`
    justify-content: flex-end !important;

    @media (max-width: 768px) {
        justify-content: center !important;
    }
`;

const InfoContainer = styled(Row)`
    justify-content: flex-end !important;

    @media (max-width: 768px) {
        justify-content: left !important;
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    
    @media (max-width: 768px) {
        margin-bottom: 4px;
    }
`;

const FilterButton = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colors.primary};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 16px;
`;

const FilterContent = styled.div`
    transition: max-height 0.3s ease, opacity 0.3s ease;
    max-height: ${({ isVisible }) => (isVisible ? '1000px' : '0')};
    opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
    overflow: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')}; /* Allow content to overflow when visible */
`;

const SettlementsDateFilter = ({ settlements, selectedMonth, selectedDate, onDateChange, onMonthChange }) => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const numberOfDaysInMonth = selectedMonth.daysInMonth();

    const dateToString = (date) => date.format('YYYY-MM-DD');
    
    const settlementStatuses = useMemo(() => {
        return Array.from({ length: numberOfDaysInMonth }).map((_, index) => {
            const dayOfMonth = index + 1;
            const date = selectedMonth.date(dayOfMonth);
            const settlementsForDate = settlements.filter(
                (settlement) => dateToString(dayjs(settlement.createdAt)) === dateToString(date)
            );
            const hasFailedSettlement = settlementsForDate.some(
                (settlement) => settlement.exportStatus === 'EXPORT_FAILED'
            );
            const hasSuccessfulSettlement = !hasFailedSettlement && settlementsForDate.length > 0;

            return {
                date,
                hasFailedSettlement,
                hasSuccessfulSettlement,
            };
        });
    }, [selectedMonth, settlements]);

    const handleDateCircleClick = useCallback((date) => {
        onDateChange(date);
        setIsFilterVisible(false);
    }, [onDateChange]);

    return (
        <div>
            <Visible xs>
                <FilterButton onClick={() => setIsFilterVisible(!isFilterVisible)}>
                    Välj datum
                    <VerticalSpacer spacing={0.5} />
                    {isFilterVisible ? <ChevronUpIcon color='white' /> : <ChevronDownIcon color='white' />}
                </FilterButton>
            </Visible>
            <Visible xs>
                <FilterContent isVisible={isFilterVisible}>
                    <ResponsiveRow nogutter>
                        <DayPickerWrapper>
                            <DayPickerInput
                                defaultDate={selectedDate?.toDate()}
                                callBack={(input) => { 
                                    onDateChange(dayjs(input.selectedDay));
                                    setIsFilterVisible(false);
                                }}
                            />
                        </DayPickerWrapper>
                        <Hidden xs>
                            <VerticalSpacer spacing={2} />
                        </Hidden>
                        <MonthSelect
                            selectedDate={selectedMonth}
                            onMonthChange={(month) => onMonthChange(dayjs(month))}
                        />
                    </ResponsiveRow>
                    <HorizontalSpacer spacing={1} />
                    <Row nogutter>
                        {settlementStatuses.map(({ date, hasFailedSettlement, hasSuccessfulSettlement }) => (
                            <DateCircle
                                key={date.toString()}
                                date={date}
                                isActive={selectedDate && date.isSame(selectedDate, 'day')}
                                hasFailedSettlement={hasFailedSettlement}
                                hasSuccessfulSettlement={hasSuccessfulSettlement}
                                onClick={() => handleDateCircleClick(date)}
                            />
                        ))}
                    </Row>
                    <HorizontalSpacer spacing={2} />
                    <InfoContainer nogutter>
                        <InfoWrapper>
                            <SmallDateCircle hasSuccessfulSettlement="true"></SmallDateCircle>
                            <Small color="white">Datum med avräkningar</Small>
                        </InfoWrapper>
                        <Hidden xs>
                            <VerticalSpacer spacing={1} />
                        </Hidden>
                        <InfoWrapper>
                            <SmallDateCircle hasFailedSettlement="true"></SmallDateCircle>
                            <Small color="white">Datum med ej automatiskt bokförda avräkningar</Small>
                        </InfoWrapper>
                        <Hidden xs>
                            <VerticalSpacer spacing={1} />
                        </Hidden>
                        <InfoWrapper>
                            <SmallDateCircle active="true"></SmallDateCircle>
                            <Small color="white">Vald dag</Small>
                        </InfoWrapper>
                    </InfoContainer>
                    <HorizontalSpacer spacing={2} />
                </FilterContent>
            </Visible>
            <Hidden xs>            
                <>
                    <ResponsiveRow nogutter>
                        <DayPickerWrapper>
                            <DayPickerInput
                                defaultDate={selectedDate?.toDate()}
                                callBack={(input) => onDateChange(dayjs(input.selectedDay))}
                            />
                        </DayPickerWrapper>
                        <Hidden xs>
                            <VerticalSpacer spacing={2} />
                        </Hidden>
                        <MonthSelect
                            selectedDate={selectedMonth}
                            onMonthChange={(month) => onMonthChange(dayjs(month))}
                        />
                    </ResponsiveRow>
                    <HorizontalSpacer spacing={1} />
                    <Row nogutter>
                        {settlementStatuses.map(({ date, hasFailedSettlement, hasSuccessfulSettlement }) => (
                            <DateCircle
                                key={date.toString()}
                                date={date}
                                isActive={selectedDate && date.isSame(selectedDate, 'day')}
                                hasFailedSettlement={hasFailedSettlement}
                                hasSuccessfulSettlement={hasSuccessfulSettlement}
                                onClick={() => handleDateCircleClick(date)}
                            />
                        ))}
                    </Row>
                    <HorizontalSpacer spacing={2} />
                    <InfoContainer nogutter>
                        <InfoWrapper>
                            <SmallDateCircle hasSuccessfulSettlement="true"></SmallDateCircle>
                            <Small color="white">Datum med avräkningar</Small>
                        </InfoWrapper>
                        <Hidden xs>
                            <VerticalSpacer spacing={1} />
                        </Hidden>
                        <InfoWrapper>
                            <SmallDateCircle hasFailedSettlement="true"></SmallDateCircle>
                            <Small color="white">Datum med ej automatiskt bokförda avräkningar</Small>
                        </InfoWrapper>
                        <Hidden xs>
                            <VerticalSpacer spacing={1} />
                        </Hidden>
                        <InfoWrapper>
                            <SmallDateCircle active="true"></SmallDateCircle>
                            <Small color="white">Vald dag</Small>
                        </InfoWrapper>
                    </InfoContainer>
                    <HorizontalSpacer spacing={2} />
                </>
            </Hidden>
        </div>
    );
};

export default SettlementsDateFilter;