import * as React from 'react';

export const CheckmarkIcon = ({ color = '#4d4d4d' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.33527 9.33337L5.6686 11.6667L12.6686 4.33337" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const CheckmarkIconLarge = ({ color = '#4d4d4d' }) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 21L12.75 26.25L28.5 9.75" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
