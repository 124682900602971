export const formatPrice = (price) => {
    return new Intl.NumberFormat('sv-SE', {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: 2, // Ensure at least 2 decimal places
        maximumFractionDigits: 2, // Prevent excessive decimal places
    }).format(price);
};

export const MediaQuery = {
    XS: '@media (max-width: 540px)',
    SM: '@media (min-width: 740px)',
    MD: '@media (min-width: 960px)',
    LG: '@media (min-width: 1340px)',
    XL: '@media (min-width: 1400px)',
    XXL: '@media (min-width: 1400px)',
};

const accountsRecievableAccountOptions = [
    { value: '1500', label: '1500' },
    { value: '1501', label: '1501' },
    { value: '1502', label: '1502' },
    { value: '1503', label: '1503' },
    { value: '1504', label: '1504' },
    { value: '1505', label: '1505' },
    { value: '1506', label: '1506' },
    { value: '1507', label: '1507' },
    { value: '1508', label: '1508' },
    { value: '1509', label: '1509' },
    { value: '1510', label: '1510' },
    { value: '1511', label: '1511' },
    { value: '1512', label: '1512' },
    { value: '1513', label: '1513' },
    { value: '1514', label: '1514' },
    { value: '1515', label: '1515' },
    { value: '1516', label: '1516' },
    { value: '1517', label: '1517' },
    { value: '1518', label: '1518' },
    { value: '1519', label: '1519' },
    { value: '1520', label: '1520' },
    { value: '1521', label: '1521' },
    { value: '1522', label: '1522' },
    { value: '1523', label: '1523' },
    { value: '1524', label: '1524' },
    { value: '1525', label: '1525' },
    { value: '1526', label: '1526' },
    { value: '1527', label: '1527' },
    { value: '1528', label: '1528' },
    { value: '1529', label: '1529' },
    { value: '1530', label: '1530' },
    { value: '1531', label: '1531' },
    { value: '1532', label: '1532' },
    { value: '1533', label: '1533' },
    { value: '1534', label: '1534' },
    { value: '1535', label: '1535' },
    { value: '1536', label: '1536' },
    { value: '1537', label: '1537' },
    { value: '1538', label: '1538' },
    { value: '1539', label: '1539' },
    { value: '1540', label: '1540' },
    { value: '1541', label: '1541' },
    { value: '1542', label: '1542' },
    { value: '1543', label: '1543' },
    { value: '1544', label: '1544' },
    { value: '1545', label: '1545' },
    { value: '1546', label: '1546' },
    { value: '1547', label: '1547' },
    { value: '1548', label: '1548' },
    { value: '1549', label: '1549' },
    { value: '1550', label: '1550' },
    { value: '1551', label: '1551' },
    { value: '1552', label: '1552' },
    { value: '1553', label: '1553' },
    { value: '1554', label: '1554' },
    { value: '1555', label: '1555' },
    { value: '1556', label: '1556' },
    { value: '1557', label: '1557' },
    { value: '1558', label: '1558' },
    { value: '1559', label: '1559' },
    { value: '1560', label: '1560' },
    { value: '1561', label: '1561' },
    { value: '1562', label: '1562' },
    { value: '1563', label: '1563' },
    { value: '1564', label: '1564' },
    { value: '1565', label: '1565' },
    { value: '1566', label: '1566' },
    { value: '1567', label: '1567' },
    { value: '1568', label: '1568' },
    { value: '1569', label: '1569' },
    { value: '1570', label: '1570' },
    { value: '1571', label: '1571' },
    { value: '1572', label: '1572' },
    { value: '1573', label: '1573' },
    { value: '1574', label: '1574' },
    { value: '1575', label: '1575' },
    { value: '1576', label: '1576' },
    { value: '1577', label: '1577' },
    { value: '1578', label: '1578' },
    { value: '1579', label: '1579' },
    { value: '1580', label: '1580' },
    { value: '1581', label: '1581' },
    { value: '1582', label: '1582' },
    { value: '1583', label: '1583' },
    { value: '1584', label: '1584' },
    { value: '1585', label: '1585' },
    { value: '1586', label: '1586' },
    { value: '1587', label: '1587' },
    { value: '1588', label: '1588' },
    { value: '1589', label: '1589' },
    { value: '1590', label: '1590' },
    { value: '1591', label: '1591' },
    { value: '1592', label: '1592' },
    { value: '1593', label: '1593' },
    { value: '1594', label: '1594' },
    { value: '1595', label: '1595' },
    { value: '1596', label: '1596' },
    { value: '1597', label: '1597' },
    { value: '1598', label: '1598' },
    { value: '1599', label: '1599' },
];

const businessAccountAccountOptions = [
    { value: '1900', label: '1900' },
    { value: '1901', label: '1901' },
    { value: '1902', label: '1902' },
    { value: '1903', label: '1903' },
    { value: '1904', label: '1904' },
    { value: '1905', label: '1905' },
    { value: '1906', label: '1906' },
    { value: '1907', label: '1907' },
    { value: '1908', label: '1908' },
    { value: '1909', label: '1909' },
    { value: '1910', label: '1910' },
    { value: '1911', label: '1911' },
    { value: '1912', label: '1912' },
    { value: '1913', label: '1913' },
    { value: '1914', label: '1914' },
    { value: '1915', label: '1915' },
    { value: '1916', label: '1916' },
    { value: '1917', label: '1917' },
    { value: '1918', label: '1918' },
    { value: '1919', label: '1919' },
    { value: '1920', label: '1920' },
    { value: '1921', label: '1921' },
    { value: '1922', label: '1922' },
    { value: '1923', label: '1923' },
    { value: '1924', label: '1924' },
    { value: '1925', label: '1925' },
    { value: '1926', label: '1926' },
    { value: '1927', label: '1927' },
    { value: '1928', label: '1928' },
    { value: '1929', label: '1929' },
    { value: '1930', label: '1930' },
    { value: '1931', label: '1931' },
    { value: '1932', label: '1932' },
    { value: '1933', label: '1933' },
    { value: '1934', label: '1934' },
    { value: '1935', label: '1935' },
    { value: '1936', label: '1936' },
    { value: '1937', label: '1937' },
    { value: '1938', label: '1938' },
    { value: '1939', label: '1939' },
    { value: '1940', label: '1940' },
    { value: '1941', label: '1941' },
    { value: '1942', label: '1942' },
    { value: '1943', label: '1943' },
    { value: '1944', label: '1944' },
    { value: '1945', label: '1945' },
    { value: '1946', label: '1946' },
    { value: '1947', label: '1947' },
    { value: '1948', label: '1948' },
    { value: '1949', label: '1949' },
    { value: '1950', label: '1950' },
    { value: '1951', label: '1951' },
    { value: '1952', label: '1952' },
    { value: '1953', label: '1953' },
    { value: '1954', label: '1954' },
    { value: '1955', label: '1955' },
    { value: '1956', label: '1956' },
    { value: '1957', label: '1957' },
    { value: '1958', label: '1958' },
    { value: '1959', label: '1959' },
    { value: '1960', label: '1960' },
    { value: '1961', label: '1961' },
    { value: '1962', label: '1962' },
    { value: '1963', label: '1963' },
    { value: '1964', label: '1964' },
    { value: '1965', label: '1965' },
    { value: '1966', label: '1966' },
    { value: '1967', label: '1967' },
    { value: '1968', label: '1968' },
    { value: '1969', label: '1969' },
    { value: '1970', label: '1970' },
    { value: '1971', label: '1971' },
    { value: '1972', label: '1972' },
    { value: '1973', label: '1973' },
    { value: '1974', label: '1974' },
    { value: '1975', label: '1975' },
    { value: '1976', label: '1976' },
    { value: '1977', label: '1977' },
    { value: '1978', label: '1978' },
    { value: '1979', label: '1979' },
    { value: '1980', label: '1980' },
    { value: '1981', label: '1981' },
    { value: '1982', label: '1982' },
    { value: '1983', label: '1983' },
    { value: '1984', label: '1984' },
    { value: '1985', label: '1985' },
    { value: '1986', label: '1986' },
    { value: '1987', label: '1987' },
    { value: '1988', label: '1988' },
    { value: '1989', label: '1989' },
    { value: '1990', label: '1990' },
    { value: '1991', label: '1991' },
    { value: '1992', label: '1992' },
    { value: '1993', label: '1993' },
    { value: '1994', label: '1994' },
    { value: '1995', label: '1995 🥳' },
    { value: '1996', label: '1996' },
    { value: '1997', label: '1997' },
    { value: '1998', label: '1998' },
    { value: '1999', label: '1999' },
];

const clearingAccountFactoringAccountOptions = [
    { value: '2800', label: '2800' },
    { value: '2801', label: '2801' },
    { value: '2802', label: '2802' },
    { value: '2803', label: '2803' },
    { value: '2804', label: '2804' },
    { value: '2805', label: '2805' },
    { value: '2806', label: '2806' },
    { value: '2807', label: '2807' },
    { value: '2808', label: '2808' },
    { value: '2809', label: '2809' },
    { value: '2810', label: '2810' },
    { value: '2811', label: '2811' },
    { value: '2812', label: '2812' },
    { value: '2813', label: '2813' },
    { value: '2814', label: '2814' },
    { value: '2815', label: '2815' },
    { value: '2816', label: '2816' },
    { value: '2817', label: '2817' },
    { value: '2818', label: '2818' },
    { value: '2819', label: '2819' },
    { value: '2820', label: '2820' },
    { value: '2821', label: '2821' },
    { value: '2822', label: '2822' },
    { value: '2823', label: '2823' },
    { value: '2824', label: '2824' },
    { value: '2825', label: '2825' },
    { value: '2826', label: '2826' },
    { value: '2827', label: '2827' },
    { value: '2828', label: '2828' },
    { value: '2829', label: '2829' },
    { value: '2830', label: '2830' },
    { value: '2831', label: '2831' },
    { value: '2832', label: '2832' },
    { value: '2833', label: '2833' },
    { value: '2834', label: '2834' },
    { value: '2835', label: '2835' },
    { value: '2836', label: '2836' },
    { value: '2837', label: '2837' },
    { value: '2838', label: '2838' },
    { value: '2839', label: '2839' },
    { value: '2840', label: '2840' },
    { value: '2841', label: '2841' },
    { value: '2842', label: '2842' },
    { value: '2843', label: '2843' },
    { value: '2844', label: '2844' },
    { value: '2845', label: '2845' },
    { value: '2846', label: '2846' },
    { value: '2847', label: '2847' },
    { value: '2848', label: '2848' },
    { value: '2849', label: '2849' },
    { value: '2850', label: '2850' },
    { value: '2851', label: '2851' },
    { value: '2852', label: '2852' },
    { value: '2853', label: '2853' },
    { value: '2854', label: '2854' },
    { value: '2855', label: '2855' },
    { value: '2856', label: '2856' },
    { value: '2857', label: '2857' },
    { value: '2858', label: '2858' },
    { value: '2859', label: '2859' },
    { value: '2860', label: '2860' },
    { value: '2861', label: '2861' },
    { value: '2862', label: '2862' },
    { value: '2863', label: '2863' },
    { value: '2864', label: '2864' },
    { value: '2865', label: '2865' },
    { value: '2866', label: '2866' },
    { value: '2867', label: '2867' },
    { value: '2868', label: '2868' },
    { value: '2869', label: '2869' },
    { value: '2870', label: '2870' },
    { value: '2871', label: '2871' },
    { value: '2872', label: '2872' },
    { value: '2873', label: '2873' },
    { value: '2874', label: '2874' },
    { value: '2875', label: '2875' },
    { value: '2876', label: '2876' },
    { value: '2877', label: '2877' },
    { value: '2878', label: '2878' },
    { value: '2879', label: '2879' },
    { value: '2880', label: '2880' },
    { value: '2881', label: '2881' },
    { value: '2882', label: '2882' },
    { value: '2883', label: '2883' },
    { value: '2884', label: '2884' },
    { value: '2885', label: '2885' },
    { value: '2886', label: '2886' },
    { value: '2887', label: '2887' },
    { value: '2888', label: '2888' },
    { value: '2889', label: '2889' },
    { value: '2890', label: '2890' },
    { value: '2891', label: '2891' },
    { value: '2892', label: '2892' },
    { value: '2893', label: '2893' },
    { value: '2894', label: '2894' },
    { value: '2895', label: '2895' },
    { value: '2896', label: '2896' },
    { value: '2897', label: '2897' },
    { value: '2898', label: '2898' },
    { value: '2899', label: '2899' },
];

const factoringChargesAccountOptions = [
    { value: '6000', label: '6000' },
    { value: '6001', label: '6001' },
    { value: '6002', label: '6002' },
    { value: '6003', label: '6003' },
    { value: '6004', label: '6004' },
    { value: '6005', label: '6005' },
    { value: '6006', label: '6006' },
    { value: '6007', label: '6007' },
    { value: '6008', label: '6008' },
    { value: '6009', label: '6009' },
    { value: '6010', label: '6010' },
    { value: '6011', label: '6011' },
    { value: '6012', label: '6012' },
    { value: '6013', label: '6013' },
    { value: '6014', label: '6014' },
    { value: '6015', label: '6015' },
    { value: '6016', label: '6016' },
    { value: '6017', label: '6017' },
    { value: '6018', label: '6018' },
    { value: '6019', label: '6019' },
    { value: '6020', label: '6020' },
    { value: '6021', label: '6021' },
    { value: '6022', label: '6022' },
    { value: '6023', label: '6023' },
    { value: '6024', label: '6024' },
    { value: '6025', label: '6025' },
    { value: '6026', label: '6026' },
    { value: '6027', label: '6027' },
    { value: '6028', label: '6028' },
    { value: '6029', label: '6029' },
    { value: '6030', label: '6030' },
    { value: '6031', label: '6031' },
    { value: '6032', label: '6032' },
    { value: '6033', label: '6033' },
    { value: '6034', label: '6034' },
    { value: '6035', label: '6035' },
    { value: '6036', label: '6036' },
    { value: '6037', label: '6037' },
    { value: '6038', label: '6038' },
    { value: '6039', label: '6039' },
    { value: '6040', label: '6040' },
    { value: '6041', label: '6041' },
    { value: '6042', label: '6042' },
    { value: '6043', label: '6043' },
    { value: '6044', label: '6044' },
    { value: '6045', label: '6045' },
    { value: '6046', label: '6046' },
    { value: '6047', label: '6047' },
    { value: '6048', label: '6048' },
    { value: '6049', label: '6049' },
    { value: '6050', label: '6050' },
    { value: '6051', label: '6051' },
    { value: '6052', label: '6052' },
    { value: '6053', label: '6053' },
    { value: '6054', label: '6054' },
    { value: '6055', label: '6055' },
    { value: '6056', label: '6056' },
    { value: '6057', label: '6057' },
    { value: '6058', label: '6058' },
    { value: '6059', label: '6059' },
    { value: '6060', label: '6060' },
    { value: '6061', label: '6061' },
    { value: '6062', label: '6062' },
    { value: '6063', label: '6063' },
    { value: '6064', label: '6064' },
    { value: '6065', label: '6065' },
    { value: '6066', label: '6066' },
    { value: '6067', label: '6067' },
    { value: '6068', label: '6068' },
    { value: '6069', label: '6069' },
    { value: '6070', label: '6070' },
    { value: '6071', label: '6071' },
    { value: '6072', label: '6072' },
    { value: '6073', label: '6073' },
    { value: '6074', label: '6074' },
    { value: '6075', label: '6075' },
    { value: '6076', label: '6076' },
    { value: '6077', label: '6077' },
    { value: '6078', label: '6078' },
    { value: '6079', label: '6079' },
    { value: '6080', label: '6080' },
    { value: '6081', label: '6081' },
    { value: '6082', label: '6082' },
    { value: '6083', label: '6083' },
    { value: '6084', label: '6084' },
    { value: '6085', label: '6085' },
    { value: '6086', label: '6086' },
    { value: '6087', label: '6087' },
    { value: '6088', label: '6088' },
    { value: '6089', label: '6089' },
    { value: '6090', label: '6090' },
    { value: '6091', label: '6091' },
    { value: '6092', label: '6092' },
    { value: '6093', label: '6093' },
    { value: '6094', label: '6094' },
    { value: '6095', label: '6095' },
    { value: '6096', label: '6096' },
    { value: '6097', label: '6097' },
    { value: '6098', label: '6098' },
    { value: '6099', label: '6099' },
];

const interestExpenseToCreditInstitutionsAccountOptions = [
    { value: '8400', label: '8400' },
    { value: '8401', label: '8401' },
    { value: '8402', label: '8402' },
    { value: '8403', label: '8403' },
    { value: '8404', label: '8404' },
    { value: '8405', label: '8405' },
    { value: '8406', label: '8406' },
    { value: '8407', label: '8407' },
    { value: '8408', label: '8408' },
    { value: '8409', label: '8409' },
    { value: '8410', label: '8410' },
    { value: '8411', label: '8411' },
    { value: '8412', label: '8412' },
    { value: '8413', label: '8413' },
    { value: '8414', label: '8414' },
    { value: '8415', label: '8415' },
    { value: '8416', label: '8416' },
    { value: '8417', label: '8417' },
    { value: '8418', label: '8418' },
    { value: '8419', label: '8419' },
    { value: '8420', label: '8420' },
    { value: '8421', label: '8421' },
    { value: '8422', label: '8422' },
    { value: '8423', label: '8423' },
    { value: '8424', label: '8424' },
    { value: '8425', label: '8425' },
    { value: '8426', label: '8426' },
    { value: '8427', label: '8427' },
    { value: '8428', label: '8428' },
    { value: '8429', label: '8429' },
    { value: '8430', label: '8430' },
    { value: '8431', label: '8431' },
    { value: '8432', label: '8432' },
    { value: '8433', label: '8433' },
    { value: '8434', label: '8434' },
    { value: '8435', label: '8435' },
    { value: '8436', label: '8436' },
    { value: '8437', label: '8437' },
    { value: '8438', label: '8438' },
    { value: '8439', label: '8439' },
    { value: '8440', label: '8440' },
    { value: '8441', label: '8441' },
    { value: '8442', label: '8442' },
    { value: '8443', label: '8443' },
    { value: '8444', label: '8444' },
    { value: '8445', label: '8445' },
    { value: '8446', label: '8446' },
    { value: '8447', label: '8447' },
    { value: '8448', label: '8448' },
    { value: '8449', label: '8449' },
    { value: '8450', label: '8450' },
    { value: '8451', label: '8451' },
    { value: '8452', label: '8452' },
    { value: '8453', label: '8453' },
    { value: '8454', label: '8454' },
    { value: '8455', label: '8455' },
    { value: '8456', label: '8456' },
    { value: '8457', label: '8457' },
    { value: '8458', label: '8458' },
    { value: '8459', label: '8459' },
    { value: '8460', label: '8460' },
    { value: '8461', label: '8461' },
    { value: '8462', label: '8462' },
    { value: '8463', label: '8463' },
    { value: '8464', label: '8464' },
    { value: '8465', label: '8465' },
    { value: '8466', label: '8466' },
    { value: '8467', label: '8467' },
    { value: '8468', label: '8468' },
    { value: '8469', label: '8469' },
    { value: '8470', label: '8470' },
    { value: '8471', label: '8471' },
    { value: '8472', label: '8472' },
    { value: '8473', label: '8473' },
    { value: '8474', label: '8474' },
    { value: '8475', label: '8475' },
    { value: '8476', label: '8476' },
    { value: '8477', label: '8477' },
    { value: '8478', label: '8478' },
    { value: '8479', label: '8479' },
    { value: '8480', label: '8480' },
    { value: '8481', label: '8481' },
    { value: '8482', label: '8482' },
    { value: '8483', label: '8483' },
    { value: '8484', label: '8484' },
    { value: '8485', label: '8485' },
    { value: '8486', label: '8486' },
    { value: '8487', label: '8487' },
    { value: '8488', label: '8488' },
    { value: '8489', label: '8489' },
    { value: '8490', label: '8490' },
    { value: '8491', label: '8491' },
    { value: '8492', label: '8492' },
    { value: '8493', label: '8493' },
    { value: '8494', label: '8494' },
    { value: '8495', label: '8495' },
    { value: '8496', label: '8496' },
    { value: '8497', label: '8497' },
    { value: '8498', label: '8498' },
    { value: '8499', label: '8499' },
];

const otherCurrentLiabilitiesAccountOptions = [
    { value: '2400', label: '2400' },
    { value: '2401', label: '2401' },
    { value: '2402', label: '2402' },
    { value: '2403', label: '2403' },
    { value: '2404', label: '2404' },
    { value: '2405', label: '2405' },
    { value: '2406', label: '2406' },
    { value: '2407', label: '2407' },
    { value: '2408', label: '2408' },
    { value: '2409', label: '2409' },
    { value: '2410', label: '2410' },
    { value: '2411', label: '2411' },
    { value: '2412', label: '2412' },
    { value: '2413', label: '2413' },
    { value: '2414', label: '2414' },
    { value: '2415', label: '2415' },
    { value: '2416', label: '2416' },
    { value: '2417', label: '2417' },
    { value: '2418', label: '2418' },
    { value: '2419', label: '2419' },
    { value: '2420', label: '2420' },
    { value: '2421', label: '2421' },
    { value: '2422', label: '2422' },
    { value: '2423', label: '2423' },
    { value: '2424', label: '2424' },
    { value: '2425', label: '2425' },
    { value: '2426', label: '2426' },
    { value: '2427', label: '2427' },
    { value: '2428', label: '2428' },
    { value: '2429', label: '2429' },
    { value: '2430', label: '2430' },
    { value: '2431', label: '2431' },
    { value: '2432', label: '2432' },
    { value: '2433', label: '2433' },
    { value: '2434', label: '2434' },
    { value: '2435', label: '2435' },
    { value: '2436', label: '2436' },
    { value: '2437', label: '2437' },
    { value: '2438', label: '2438' },
    { value: '2439', label: '2439' },
    { value: '2440', label: '2440' },
    { value: '2441', label: '2441' },
    { value: '2442', label: '2442' },
    { value: '2443', label: '2443' },
    { value: '2444', label: '2444' },
    { value: '2445', label: '2445' },
    { value: '2446', label: '2446' },
    { value: '2447', label: '2447' },
    { value: '2448', label: '2448' },
    { value: '2449', label: '2449' },
    { value: '2450', label: '2450' },
    { value: '2451', label: '2451' },
    { value: '2452', label: '2452' },
    { value: '2453', label: '2453' },
    { value: '2454', label: '2454' },
    { value: '2455', label: '2455' },
    { value: '2456', label: '2456' },
    { value: '2457', label: '2457' },
    { value: '2458', label: '2458' },
    { value: '2459', label: '2459' },
    { value: '2460', label: '2460' },
    { value: '2461', label: '2461' },
    { value: '2462', label: '2462' },
    { value: '2463', label: '2463' },
    { value: '2464', label: '2464' },
    { value: '2465', label: '2465' },
    { value: '2466', label: '2466' },
    { value: '2467', label: '2467' },
    { value: '2468', label: '2468' },
    { value: '2469', label: '2469' },
    { value: '2470', label: '2470' },
    { value: '2471', label: '2471' },
    { value: '2472', label: '2472' },
    { value: '2473', label: '2473' },
    { value: '2474', label: '2474' },
    { value: '2475', label: '2475' },
    { value: '2476', label: '2476' },
    { value: '2477', label: '2477' },
    { value: '2478', label: '2478' },
    { value: '2479', label: '2479' },
    { value: '2480', label: '2480' },
    { value: '2481', label: '2481' },
    { value: '2482', label: '2482' },
    { value: '2483', label: '2483' },
    { value: '2424', label: '2424' },
    { value: '2485', label: '2485' },
    { value: '2486', label: '2486' },
    { value: '2487', label: '2487' },
    { value: '2488', label: '2488' },
    { value: '2489', label: '2489' },
    { value: '2490', label: '2490' },
    { value: '2491', label: '2491' },
    { value: '2492', label: '2492' },
    { value: '2493', label: '2493' },
    { value: '2494', label: '2494' },
    { value: '2495', label: '2495' },
    { value: '2496', label: '2496' },
    { value: '2497', label: '2497' },
    { value: '2498', label: '2498' },
    { value: '2499', label: '2499' },
];
const otherExternalServicesAccountOptions = [
    { value: '6500', label: '6500' },
    { value: '6501', label: '6501' },
    { value: '6502', label: '6502' },
    { value: '6503', label: '6503' },
    { value: '6504', label: '6504' },
    { value: '6505', label: '6505' },
    { value: '6506', label: '6506' },
    { value: '6507', label: '6507' },
    { value: '6508', label: '6508' },
    { value: '6509', label: '6509' },
    { value: '6510', label: '6510' },
    { value: '6511', label: '6511' },
    { value: '6512', label: '6512' },
    { value: '6513', label: '6513' },
    { value: '6514', label: '6514' },
    { value: '6515', label: '6515' },
    { value: '6516', label: '6516' },
    { value: '6517', label: '6517' },
    { value: '6518', label: '6518' },
    { value: '6519', label: '6519' },
    { value: '6520', label: '6520' },
    { value: '6521', label: '6521' },
    { value: '6522', label: '6522' },
    { value: '6523', label: '6523' },
    { value: '6524', label: '6524' },
    { value: '6525', label: '6525' },
    { value: '6526', label: '6526' },
    { value: '6527', label: '6527' },
    { value: '6528', label: '6528' },
    { value: '6529', label: '6529' },
    { value: '6530', label: '6530' },
    { value: '6531', label: '6531' },
    { value: '6532', label: '6532' },
    { value: '6533', label: '6533' },
    { value: '6534', label: '6534' },
    { value: '6535', label: '6535' },
    { value: '6536', label: '6536' },
    { value: '6537', label: '6537' },
    { value: '6538', label: '6538' },
    { value: '6539', label: '6539' },
    { value: '6540', label: '6540' },
    { value: '6541', label: '6541' },
    { value: '6542', label: '6542' },
    { value: '6543', label: '6543' },
    { value: '6544', label: '6544' },
    { value: '6545', label: '6545' },
    { value: '6546', label: '6546' },
    { value: '6547', label: '6547' },
    { value: '6548', label: '6548' },
    { value: '6549', label: '6549' },
    { value: '6550', label: '6550' },
    { value: '6551', label: '6551' },
    { value: '6552', label: '6552' },
    { value: '6553', label: '6553' },
    { value: '6554', label: '6554' },
    { value: '6555', label: '6555' },
    { value: '6556', label: '6556' },
    { value: '6557', label: '6557' },
    { value: '6558', label: '6558' },
    { value: '6559', label: '6559' },
    { value: '6560', label: '6560' },
    { value: '6561', label: '6561' },
    { value: '6562', label: '6562' },
    { value: '6563', label: '6563' },
    { value: '6564', label: '6564' },
    { value: '6565', label: '6565' },
    { value: '6566', label: '6566' },
    { value: '6567', label: '6567' },
    { value: '6568', label: '6568' },
    { value: '6569', label: '6569' },
    { value: '6570', label: '6570' },
    { value: '6571', label: '6571' },
    { value: '6572', label: '6572' },
    { value: '6573', label: '6573' },
    { value: '6574', label: '6574' },
    { value: '6575', label: '6575' },
    { value: '6576', label: '6576' },
    { value: '6577', label: '6577' },
    { value: '6578', label: '6578' },
    { value: '6579', label: '6579' },
    { value: '6580', label: '6580' },
    { value: '6581', label: '6581' },
    { value: '6582', label: '6582' },
    { value: '6583', label: '6583' },
    { value: '6565', label: '6565' },
    { value: '6585', label: '6585' },
    { value: '6586', label: '6586' },
    { value: '6587', label: '6587' },
    { value: '6588', label: '6588' },
    { value: '6589', label: '6589' },
    { value: '6590', label: '6590' },
    { value: '6591', label: '6591' },
    { value: '6592', label: '6592' },
    { value: '6593', label: '6593' },
    { value: '6594', label: '6594' },
    { value: '6595', label: '6595' },
    { value: '6596', label: '6596' },
    { value: '6597', label: '6597' },
    { value: '6598', label: '6598' },
    { value: '6599', label: '6599' },
];

export const getInitialAccountPlan = (currentAccounts, defaultAccounts) => {
    if (currentAccounts && defaultAccounts) {
        return [
            {
                id: 'accountsRecievable',
                name: 'Kundfordringar',
                description: 'Avser ej överlåtna kundfordringar (defaultkonto: 1510)',
                value: currentAccounts.accountsReceivable,
                resetValue: defaultAccounts.accountsReceivable,
                accountOptions: accountsRecievableAccountOptions,
            },
            {
                id: 'businessAccount',
                name: 'Bankgiro, Bank',
                description: 'Avser transaktioner från Factoringgruppen till ert bankkonto (defaultkonto: 1930)',
                value: currentAccounts.businessAccount,
                resetValue: defaultAccounts.businessAccount,
                accountOptions: businessAccountAccountOptions,
            },
            {
                id: 'clearingAccountFactoring',
                name: 'Avräkning factoring',
                description: 'Avser skuld till Factoringgruppen för överlåtna kundfordringar (defaultkonto: 2811)',
                value: currentAccounts.clearingAccountFactoring,
                resetValue: defaultAccounts.clearingAccountFactoring,
                accountOptions: clearingAccountFactoringAccountOptions,
            },
            {
                id: 'clearingAccountPledgedReceivable',
                name: 'Belånade kundfordringar',
                description: 'Avser kundfordringar som är överlåtna till Factoringgruppen (defaultkonto: 1512)',
                value: currentAccounts.clearingAccountPledgedReceivable,
                resetValue: defaultAccounts.clearingAccountPledgedReceivable,
                accountOptions: accountsRecievableAccountOptions,
            },
            {
                id: 'factoringCharges',
                name: 'Factoringavgifter',
                description: 'Avser avgifter för factoring (defaultkonto: 6064)',
                value: currentAccounts.factoringCharges,
                resetValue: defaultAccounts.factoringCharges,
                accountOptions: factoringChargesAccountOptions,
            },
            {
                id: 'inputVat',
                name: 'Ingående moms SE',
                description: 'Avser moms för köp av tjänst',
                value: currentAccounts.inputVat,
                resetValue: defaultAccounts.inputVat,
                accountOptions: [],
            },
            {
                id: 'interestExpenseToCreditInstitutions',
                name: 'Räntekostnader för kortfristiga skulder',
                description: 'Avser räntekostnader på lån och krediter (defaultkonto: 8421)',
                value: currentAccounts.interestExpenseToCreditInstitutions,
                resetValue: defaultAccounts.interestExpenseToCreditInstitutions,
                accountOptions: interestExpenseToCreditInstitutionsAccountOptions,
            },
            {
                id: 'otherCurrentLiabilities',
                name: 'Övriga kortfristiga skulder',
                description: 'Avser låneskulder från Factoringgruppen (defaultkonto: 2410)',
                value: currentAccounts.otherCurrentLiabilities,
                resetValue: defaultAccounts.otherCurrentLiabilities,
                accountOptions: otherCurrentLiabilitiesAccountOptions,
            },
            {
                id: 'otherExternalServices',
                name: 'Övriga externa tjänster',
                description: 'Avser kostnader som inte tillhör andra specifika kostnadskonton (defaultkonto: 6590)',
                value: currentAccounts.otherExternalServices,
                resetValue: defaultAccounts.otherExternalServices,
                accountOptions: otherExternalServicesAccountOptions,
            },
            {
                id: 'pledgedReceivable',
                name: 'Factoringgruppen deposition',
                description: 'Avser kundfordringar sålda till factoringbolag',
                value: currentAccounts.pledgedReceivable,
                resetValue: defaultAccounts.pledgedReceivable,
                accountOptions: [],
            },
            {
                id: 'roundingOff',
                name: 'Öresavrundning',
                description: 'Avser avrundningen av ören vid utbetalningar',
                value: currentAccounts.roundingOff,
                resetValue: defaultAccounts.roundingOff,
                accountOptions: [],
            },
            {
                id: 'suspenseAccount',
                name: 'OBS-konto (Observationskonto)',
                description: 'Avser ospecifierade eller oklara skulder och periodiseringar innan de kan bokföras på rätt konto',
                value: currentAccounts.suspenseAccount,
                resetValue: defaultAccounts.suspenseAccount,
                accountOptions: [],
            },
        ];
    }
};
