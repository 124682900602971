import { useQuery, gql } from '@apollo/client';

const GET_SETTLEMENT_DATA = gql`
    query getSettlementData($clientIdentifier: String!, $settlementId: String) {
        settlementData(clientIdentifier: $clientIdentifier, settlementId: $settlementId) {
            accounting {
                accountsReceivable {
                    accountNumber
                    amount
                }
                businessAccount {
                    accountNumber
                    amount
                }
                clearingAccountFactoring {
                    accountNumber
                    amount
                }
                clearingAccountPledgedReceivable {
                    accountNumber
                    amount
                }
                factoringCharges {
                    accountNumber
                    amount
                }
                inputVat {
                    accountNumber
                    amount
                }
                interestExpenseToCreditInstitutions {
                    accountNumber
                    amount
                }
                otherCurrentLiabilities {
                    accountNumber
                    amount
                }
                otherExternalServices {
                    accountNumber
                    amount
                }
                pledgedReceivable {
                    accountNumber
                    amount
                }
                roundingOff {
                    accountNumber
                    amount
                }
                suspenseAccount {
                    accountNumber
                    amount
                }
            }
            createdAt
            exportStatus
            settlementAccountPlanIsOutdated
            settlementId
            totalAmount
        }
    }
`;

export default GET_SETTLEMENT_DATA;
