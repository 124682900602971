import { useMutation } from '@apollo/client';
import GET_ACCOUNT_PLAN from '../queries/getAccountPlan';
import { UPDATE_ACCOUNT_PLAN } from '../mutations/updateAccountPlan';

export const useUpdateAccountPlan = ({ onCompleted, onError }) => {
    const [updateAccountPlan, { data, loading, error }] = useMutation(UPDATE_ACCOUNT_PLAN, {
        onCompleted: onCompleted,
        onError: onError,
        refetchQueries: [GET_ACCOUNT_PLAN],
        awaitRefetchQueries: true,
    });

    return { updateAccountPlan, data, loading, error };
};
