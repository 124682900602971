import * as React from 'react';

export const CrossIcon = ({ color = 'white' }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.6689 3.33325L3.33557 12.6666M3.33557 3.33325L12.6689 12.6666"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
