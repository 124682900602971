import * as React from 'react';
import Modal from '../molecules/Modal';
import Subtitle from '../atoms/typography/Subtitle';
import { HorizontalSpacer } from '../atoms/Spacers';
import Body from '../atoms/typography/Body';
import GradientButtonDark from '../atoms/buttons/GradientButtonDark';
import TextLinkButton from '../atoms/buttons/TextLinkButton';
import { Row } from 'react-grid-system';
import TextField from '../atoms/inputs/TextField';
import { theme } from '../theme';
import DefaultButton from '../atoms/buttons/DefaultButton';
import { CrossIcon } from '../atoms/icons/CrossIcon';
import ActionButton from '../atoms/buttons/ActionButton';
import { trackMixpanelEvent } from '../App';

const MissingIntegrationModalContent = ({ open, onClose }) => {
    const [interest, setInterest] = React.useState('');
    const [successfulSubmit, setsuccessfulSubmit] = React.useState(false);

    const commitment = JSON.parse(localStorage.getItem('commitment') ?? '{}');
    const clientIdentifier = commitment.organisationNumber ?? '';

    const onSumbitInterest = () => {
        trackMixpanelEvent('Autobook Interest', { system: interest, clientIdentifier: clientIdentifier });
        setsuccessfulSubmit(true);
        setTimeout(() => {
            onClose();
        }, 2500);
    };

    return (
        <Modal open={open} onClose={onClose} closeIcon>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <Subtitle>Vi hittar ingen aktiv integration för ditt bolag</Subtitle>
                <HorizontalSpacer spacing={2} />
                <Body>För att aktivera Automatisk bokföring krävs en integration med Fortnox.</Body>
                <HorizontalSpacer spacing={2} />
                <a href="/inställningar/integrationer">
                    <GradientButtonDark title="Skapa integration med Fortnox" />
                </a>
                <HorizontalSpacer spacing={1} />
                <a href="/inställningar/integrationer">
                    <TextLinkButton color={theme.colors.primary} title="Jag vill veta mer om hur integrationen funkar" />
                </a>
                <HorizontalSpacer spacing={2} />
            </div>
            <Body>Har du ett annat bokföringssystem? Fyll i intresseanmälan nedan så meddelar vi dig när du kan ta del av tjänsten 💚.</Body>
            <HorizontalSpacer spacing={1} />
            <Row nogutter justify="between" align="end">
                <div style={{ width: '70%', height: '100%' }}>
                    <TextField label="Bokföringssystem" placeholder="Bokio, Visma, Wint etc." onChange={(e) => setInterest(e)} />
                </div>
                <ActionButton color={theme.colors.info} title="Skicka intresseanmälan" onClick={onSumbitInterest} />
            </Row>
            {successfulSubmit && (
                <div>
                    <HorizontalSpacer spacing={1} />
                    <Body color={theme.colors.success}>Tack för ditt intresse! Vi återkommer till dig när vi stödjer ditt bokföringssystem.</Body>
                </div>
            )}

            <HorizontalSpacer spacing={2} />
            <Row justify="center">
                <DefaultButton title="Avbryt" onClick={onClose} icon={<CrossIcon color={theme.colors.text} />} />
            </Row>
        </Modal>
    );
};

export default MissingIntegrationModalContent;
