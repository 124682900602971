import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../theme';

const TooltipWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const TooltipContent = styled.div`
    visibility: hidden;
    min-width: 120px;
    max-width: 500px;
    background-color: ${theme.colors.black};
    color: white;
    text-align: center;
    border-radius: 8px;
    padding: 8px;
    position: absolute;
    z-index: 3;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;

    ${TooltipWrapper}:hover & {
        visibility: visible;
        opacity: 1;
    }
`;

const Tooltip = ({ content, children, text }) => {
    return (
        <TooltipWrapper>
            {children}
            <TooltipContent>{text ? text : content}</TooltipContent>
        </TooltipWrapper>
    );
};

export default Tooltip;
