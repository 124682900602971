import * as React from 'react';
import styled from 'styled-components';
import { VerticalSpacer } from '../Spacers';

const ButtonContainer = styled.button`
    background-color: ${(props) => (props.disabled ? '#E1E5EB' : '#edecec')};
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
    color: ${(props) => (props.disabled ? 'white' : '#4d4d4d')};
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: Inter;
    justify-content: center;
    border: none;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    }

    &:active {
        box-shadow: none;
        background-color: #eef0f2;
    }
`;

const DefaultButton = ({ onClick, disabled, title, icon }) => {
    return (
        <ButtonContainer onClick={onClick} disabled={disabled}>
            {icon && (
                <>
                    {icon} <VerticalSpacer spacing={0.5} />
                </>
            )}
            {title}
        </ButtonContainer>
    );
};

export default DefaultButton;
