import { useQuery, gql } from '@apollo/client';

const GET_ACCOUNT_PLAN = gql`
    query getAccountPlan($clientIdentifier: String!) {
        accountPlan(clientIdentifier: $clientIdentifier) {
            accountsReceivable
            businessAccount
            clearingAccountFactoring
            clearingAccountPledgedReceivable
            factoringCharges
            id
            inputVat
            interestExpenseToCreditInstitutions
            otherCurrentLiabilities
            otherExternalServices
            pledgedReceivable
            receiptSeries
            roundingOff
            suspenseAccount
        }
    }
`;

export default GET_ACCOUNT_PLAN;
