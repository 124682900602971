import { useMutation } from '@apollo/client';
import { MARK_SETTLEMENT_AS_MANUALLY_BOOKED } from '../mutations/markSettlementAsManuallyBooked';
import GET_SETTLEMENT_OVERVIEW from '../queries/getSettlementOverview';

export const useMarkSettlementAsManuallyBooked = ({ onCompleted, onError }) => {
    const [markSettlementAsManuallyBooked, { data, loading, error }] = useMutation(MARK_SETTLEMENT_AS_MANUALLY_BOOKED, {
        onCompleted: onCompleted,
        onError: onError,
        refetchQueries: [GET_SETTLEMENT_OVERVIEW],
        awaitRefetchQueries: true,
    });

    return { markSettlementAsManuallyBooked, data, loading, error };
};
