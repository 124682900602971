import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const Small = styled.div`
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: ${(props) => props.color ?? theme.colors.text};
    text-align: ${(props) => props.textalign ?? 'left'};
    font-weight: 500;
`;

export default Small;
