import React from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon, ChevronRightIcon } from '../icons/Chevrons';
import Body from '../typography/Body';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';

dayjs.locale('sv'); 

const MonthSelector = styled.div`
    margin-top: 6px;
    display: flex;
`;

const MonthSelectorItem = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'isDisabled',
})`
    cursor: pointer;
    width: 24px;
    height: 24px;
    ${({ isDisabled }) =>
        isDisabled &&
        `
        visibility: hidden;
        width: 24px;
        height: 24px;
    `}
`;

const MonthSelectorText = styled(Body)`
    width: 150px;
    text-align: center;
`;

const MonthSelect = ({ selectedDate, onMonthChange }) => {
 
    const handleChangeMonth = (action) => {
        const date = dayjs(selectedDate).add(action === 'next' ? 1 : -1, 'month');
        if (onMonthChange) onMonthChange(date);
    };

    return (
        <MonthSelector>
            <MonthSelectorItem onClick={() => handleChangeMonth('prev')}>
                <ChevronLeftIcon color="white" />
            </MonthSelectorItem>
            {selectedDate && (
                <MonthSelectorText color="white">
                    {dayjs(selectedDate).format('MMMM YYYY').charAt(0).toUpperCase() + dayjs(selectedDate).format('MMMM YYYY').slice(1)}
                </MonthSelectorText>
            )}
            <MonthSelectorItem onClick={() => handleChangeMonth('next')}>
                <ChevronRightIcon color="white" />
            </MonthSelectorItem>
        </MonthSelector>
    );
};

export default MonthSelect;
