import { theme } from '../../theme';

export const PhoneIcon = ({ color = theme.colors.text }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.74233 14.3685L6.69546 12.9816C7.2464 12.5904 7.805 12.3282 8.44229 12.1585C9.17396 11.9636 9.50195 11.5644 9.50195 10.711C9.50195 8.54629 14.502 8.31595 14.502 10.711C14.502 11.5644 14.83 11.9636 15.5617 12.1585C16.204 12.3295 16.7619 12.5934 17.3085 12.9816L19.2616 14.3685C20.1454 14.9961 20.5567 15.2995 20.7862 15.7819C21.002 16.2358 21.002 16.768 21.002 17.8324C21.002 19.7461 21.002 20.703 20.4662 21.3164C19.8172 22.0593 18.13 21.9955 17.0937 21.9955H6.91028C5.87392 21.9955 4.22104 22.0986 3.53775 21.3164C3.00195 20.703 3.00195 19.7461 3.00195 17.8324C3.00195 16.768 3.00195 16.2358 3.21779 15.7819C3.44721 15.2995 3.85857 14.9961 4.74233 14.3685Z"
            stroke={color}
            strokeWidth="1.5"
        />
        <path
            d="M14.002 17C14.002 18.1046 13.1066 19 12.002 19C10.8974 19 10.002 18.1046 10.002 17C10.002 15.8954 10.8974 15 12.002 15C13.1066 15 14.002 15.8954 14.002 17Z"
            stroke={color}
            strokeWidth="1.5"
        />
        <path
            d="M6.96209 3.69772C5.64365 4.07415 4.69579 4.54112 3.8284 5.10455C2.45513 5.9966 1.86638 7.60404 2.02802 9.15513C2.09634 9.81068 2.62259 10.1241 3.23284 9.95455C3.69646 9.82571 4.16083 9.7003 4.62156 9.56364C5.96901 9.16397 6.28594 8.67812 6.47319 7.29885L6.96209 3.69772ZM6.96209 3.69772C10.2206 2.76743 13.7834 2.76743 17.0419 3.69772M17.0419 3.69772C18.3603 4.07415 19.3082 4.54112 20.1755 5.10455C21.5488 5.9966 22.1376 7.60404 21.9759 9.15513C21.9076 9.81068 21.3814 10.1241 20.7711 9.95455C20.3075 9.82571 19.8431 9.7003 19.3824 9.56364C18.0349 9.16397 17.718 8.67812 17.5308 7.29885L17.0419 3.69772Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinejoin="round"
        />
    </svg>
);
