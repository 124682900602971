import { useQuery, gql } from '@apollo/client';

const GET_SETTLEMENT_DOCUMENT = gql`
    query getSettlementDocument($clientIdentifier: String!, $settlementId: String) {
        settlementDocument(clientIdentifier: $clientIdentifier, settlementId: $settlementId) {
            id
            data
        }
    }
`;

export default GET_SETTLEMENT_DOCUMENT;
