import { useQuery, gql } from '@apollo/client';

const GET_SETTLEMENT_OVERVIEW = gql`
    query getSettlementOverview($clientIdentifier: String!, $createdFrom: Date, $createdTo: Date, $skip: Int, $take: Int) {
        settlementOverview(clientIdentifier: $clientIdentifier, createdFrom: $createdFrom, createdTo: $createdTo, skip: $skip, take: $take) {
            items {
                createdAt
                payoutAmount
                failedExportCount
                exportStatus
                factoringSystemSettlementId
                settlementId
            }
        }
    }
`;

export default GET_SETTLEMENT_OVERVIEW;
