import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

const Body = styled.div`
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: ${(props) => props.color ?? theme.colors.text};
    text-align: ${(props) => props.textalign ?? 'left'};
    line-height: 1.5rem;
    font-weight: ${(props) => props.fontweight ?? '500'};
`;

export default Body;
