import * as React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export const SlideOutHeight = ({ children, delay, isVisible }) => {
    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    transition={{ duration: 0.5, ease: 'easeInOut', delay: delay ?? 0 }}
                    exit={{ height: 0, opacity: 0 }}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
};
