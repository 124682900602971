import { useMutation } from '@apollo/client';
import GET_ACCOUNT_PLAN from '../queries/getAccountPlan';
import { CREATE_ACCOUNT_PLAN } from '../mutations/createAccountPlan';

export const useCreateAccountPlan = ({ onCompleted, onError }) => {
    const [createAccountPlan, { data, loading, error }] = useMutation(CREATE_ACCOUNT_PLAN, {
        onCompleted: onCompleted,
        onError: onError,
        refetchQueries: [GET_ACCOUNT_PLAN],
        awaitRefetchQueries: true,
    });

    return { createAccountPlan, data, loading, error };
};
