import { useQuery } from '@apollo/client';
import GET_SETTLEMENT_DATA from '../queries/getSettlementData';

export const useSettlementData = (clientIdentifier, settlementId) => {
    const { data, error, loading, refetch } = useQuery(GET_SETTLEMENT_DATA, {
        variables: { clientIdentifier: clientIdentifier, settlementId: settlementId },
        onError: (error) => console.log('error: ', error),
    });
    return { settlementData: data?.settlementData, error, loading, refetch };
};
