import React from 'react';
import styled from 'styled-components';

export const HorizontalSpacer = styled.div`
    width: 100%;
    height: ${(props) => `${props.spacing * 16}px`};
`;

export const VerticalSpacer = styled.div`
    width: ${(props) => `${props.spacing * 16}px`};
    height: auto;
`;
