import * as React from 'react';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import styled from 'styled-components';
import { theme } from '../theme';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/Spacers';
import Body from '../atoms/typography/Body';
import { CheckmarkIcon } from '../atoms/icons/CheckmarkIcon';
import { CrossIcon } from '../atoms/icons/CrossIcon';
import DownloadButton from '../atoms/buttons/DownloadButton';
import { DownloadIcon } from '../atoms/icons/DownloadIcon';
import { ChevronDownIcon, ChevronUpIcon } from '../atoms/icons/Chevrons';
import { formatPrice } from '../helpers';
import { SlideOutHeight } from '../atoms/animations/SlideOutHeight';
import SettlementContent from './SettlementContent';

const SettlementSummaryContainer = styled.div`
    border-left: ${(props) => (props.failed ? `4px solid ${theme.colors.error}` : `4px solid ${theme.colors.success}`)};
    padding: 1rem;

    @media (max-width: 768px) {
        padding: 0.5rem 0;
        border-left: none;
        border-bottom: ${(props) => (props.failed ? `2px solid ${theme.colors.error}` : `2px solid ${theme.colors.success}`)};
    }
`;

const SettlementRow = styled(Row)`
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background-color: ${theme.colors.primary};
    }

    @media (max-width: 768px) { 
        align-items: flex-start !important;
    }
    
`;

const ChevronWrapper = styled.div`
    text-align: right;

   
`;

export const AutobookStatus = ({ status }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {status === 'EXPORTED' && (
                <>
                    <CheckmarkIcon color={theme.colors.success} />
                    <VerticalSpacer spacing={0.5} />
                    <Body color={theme.colors.success} fontweight={'700'}>
                        Automatiskt bokförd
                    </Body>
                </>
            )}

            {status === 'EXPORT_FAILED' && (
                <>
                    <CrossIcon color={theme.colors.error} />
                    <VerticalSpacer spacing={0.5} />
                    <Body color={theme.colors.error} fontweight={'700'}>
                        Automatisk bokföring misslyckades
                    </Body>
                </>
            )}
            {status === 'MANUALLY_BOOKED' && (
                <>
                    <CheckmarkIcon color={theme.colors.success} />
                    <VerticalSpacer spacing={0.5} />
                    <Body color={theme.colors.success} fontweight={'700'}>
                        Manuellt bokförd
                    </Body>
                </>
            )}
        </div>
    );
};

const Settlement = ({ settlement, autobookActivated, settlementName }) => {
    const [open, setOpen] = React.useState(false);
    
    return (
        <div>
            <SettlementSummaryContainer failed={settlement.exportStatus === 'EXPORT_FAILED'}>
                <SettlementRow nogutter justify="between" onClick={() => setOpen(!open)} align='center'>
                    <Col lg={2} md={2} xs={11}>
                        <Body color="white">
                            <b>{settlementName}</b>
                        </Body>
                    </Col>
                    <Visible xs sm style={{ textAlign: 'right' }}>
                        <Col xs={1}>
                            <ChevronWrapper>
                                {open ? <ChevronUpIcon color="white" /> : <ChevronDownIcon color={theme.colors.white} />}
                            </ChevronWrapper>
                        </Col>
                    </Visible>
                    {!open && (
                        <>
                            <Col lg={2} md={2} xs={11}>
                                <Body color="white">{formatPrice(settlement?.payoutAmount)} kr</Body>
                            </Col>
                            <Hidden xs sm>
                                <Col md={4} lg={4}>
                                    <AutobookStatus status={settlement.exportStatus} />
                                </Col>
                            </Hidden>
                            <Hidden xs sm>
                                <Col md={1} lg={1}>
                                    <DownloadButton title="PDF" icon={<DownloadIcon color="white" />} color="white" />
                                </Col>
                            </Hidden>
                            <Hidden xs sm>
                                <Col md={1} lg={1}>
                                    <DownloadButton title="SIE" icon={<DownloadIcon color="white" />} color="white" />
                                </Col>
                            </Hidden>
                        </>
                    )}
                    <Hidden xs sm style={{ textAlign: 'right' }}>
                        {open ? <ChevronUpIcon color="white" /> : <ChevronDownIcon color={theme.colors.white} />}
                    </Hidden>

                </SettlementRow>
                <SlideOutHeight isVisible={open}>
                    <SettlementContent settlementId={settlement.settlementId} autobookActivated={autobookActivated} settlement={settlement} />
                </SlideOutHeight>
            </SettlementSummaryContainer>

            <HorizontalSpacer spacing={2} />
        </div>
    );
};

export default Settlement;
