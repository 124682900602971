import * as React from 'react';

export const RecipientIllustration = () => {
    return (
        <svg width="400" height="358" viewBox="0 0 400 358" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1999_12474)">
                <path
                    d="M2.24065 80.302C1.45739 80.5223 0.79349 81.0443 0.394592 81.7535C-0.00430592 82.4626 -0.105634 83.3011 0.112842 84.0848L51.4185 267.314C51.6388 268.097 52.1608 268.761 52.8699 269.16C53.5791 269.559 54.4175 269.66 55.2013 269.442L244.34 216.481C245.123 216.261 245.787 215.739 246.186 215.03C246.585 214.321 246.686 213.482 246.468 212.698L195.162 29.4694C194.942 28.6862 194.42 28.0223 193.711 27.6234C193.001 27.2245 192.163 27.1231 191.379 27.3416L2.24065 80.302Z"
                    fill="#E6E6E6"
                />
                <path d="M57.5788 260.789L237.816 210.322L189.003 35.9939L8.76562 86.4618L57.5788 260.789Z" fill="white" />
                <path
                    d="M32.596 109.74C31.3935 110.076 30.8977 112.074 31.4909 114.192C32.084 116.31 33.5451 117.76 34.7476 117.423L116.951 94.4057C118.154 94.069 118.649 92.0715 118.056 89.9531C117.463 87.8348 116.002 86.3852 114.8 86.7219L32.596 109.74Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M39.2152 133.382C38.0127 133.719 37.5169 135.716 38.11 137.834C38.7032 139.953 40.1642 141.402 41.3667 141.066L123.57 118.048C124.773 117.711 125.269 115.714 124.675 113.595C124.082 111.477 122.621 110.028 121.419 110.364L39.2152 133.382Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M45.7601 156.752C44.5576 157.089 44.0618 159.086 44.655 161.204C45.2481 163.323 46.7091 164.772 47.9116 164.436L130.115 141.418C131.318 141.081 131.813 139.084 131.22 136.965C130.627 134.847 129.166 133.397 127.964 133.734L45.7601 156.752Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M52.3792 180.394C51.1767 180.731 50.6809 182.728 51.2741 184.847C51.8673 186.965 53.3283 188.415 54.5308 188.078L136.734 165.06C137.937 164.723 138.433 162.726 137.839 160.608C137.246 158.489 135.785 157.04 134.583 157.376L52.3792 180.394Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M156.516 75.3134C156.011 75.4542 155.539 75.6931 155.126 76.0166C154.713 76.3401 154.368 76.7417 154.111 77.1985C153.854 77.6554 153.689 78.1585 153.626 78.679C153.564 79.1995 153.604 79.7274 153.745 80.2322C153.887 80.7371 154.126 81.2092 154.45 81.6215C154.774 82.0338 155.176 82.3781 155.633 82.6349C156.09 82.8917 156.594 83.0558 157.114 83.118C157.635 83.1802 158.163 83.1391 158.667 82.9971L174.035 78.6941C175.054 78.4088 175.918 77.7304 176.437 76.8082C176.955 75.8859 177.086 74.7954 176.801 73.7765C176.516 72.7575 175.837 71.8937 174.915 71.3749C173.993 70.8562 172.902 70.725 171.883 71.0103L156.516 75.3134Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M163.008 98.4974C162.503 98.6382 162.031 98.8772 161.618 99.2007C161.205 99.5242 160.861 99.9258 160.603 100.383C160.346 100.839 160.181 101.343 160.119 101.863C160.056 102.384 160.096 102.911 160.238 103.416C160.379 103.921 160.618 104.393 160.942 104.806C161.266 105.218 161.668 105.562 162.126 105.819C162.583 106.076 163.086 106.24 163.607 106.302C164.127 106.364 164.655 106.323 165.16 106.181L180.527 101.878C181.546 101.593 182.41 100.914 182.929 99.9922C183.447 99.07 183.579 97.9795 183.293 96.9605C183.008 95.9416 182.33 95.0777 181.407 94.559C180.485 94.0402 179.395 93.9091 178.376 94.1944L163.008 98.4974Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M169.5 121.682C168.995 121.823 168.524 122.062 168.111 122.386C167.699 122.709 167.355 123.111 167.098 123.567C166.841 124.024 166.677 124.527 166.614 125.047C166.552 125.567 166.592 126.095 166.734 126.599C166.875 127.104 167.114 127.575 167.438 127.988C167.761 128.4 168.163 128.744 168.62 129.001C169.076 129.258 169.579 129.422 170.099 129.485C170.619 129.547 171.147 129.507 171.651 129.365L187.019 125.062C188.038 124.777 188.902 124.099 189.42 123.176C189.939 122.254 190.07 121.164 189.785 120.145C189.5 119.126 188.821 118.262 187.899 117.743C186.977 117.224 185.886 117.093 184.867 117.378L169.5 121.682Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M175.992 144.866C174.974 145.152 174.111 145.83 173.593 146.752C173.075 147.674 172.945 148.764 173.23 149.782C173.515 150.8 174.192 151.664 175.114 152.182C176.035 152.701 177.125 152.833 178.143 152.549L193.511 148.246C194.528 147.96 195.391 147.281 195.909 146.36C196.427 145.438 196.557 144.348 196.272 143.33C195.987 142.312 195.31 141.448 194.388 140.93C193.467 140.411 192.378 140.279 191.359 140.563L175.992 144.866Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M182.193 185.051C181.55 185.231 180.948 185.535 180.422 185.947C179.896 186.36 179.457 186.871 179.129 187.454C178.801 188.036 178.591 188.677 178.511 189.34C178.431 190.003 178.483 190.676 178.663 191.319C178.843 191.962 179.148 192.564 179.561 193.089C179.974 193.615 180.486 194.053 181.069 194.381C181.651 194.708 182.292 194.917 182.956 194.996C183.619 195.075 184.291 195.023 184.935 194.842L204.516 189.359C205.814 188.996 206.915 188.131 207.576 186.956C208.237 185.781 208.404 184.391 208.041 183.093C207.677 181.795 206.813 180.694 205.638 180.033C204.463 179.372 203.073 179.205 201.775 179.568L182.193 185.051Z"
                    fill="#0F5156"
                />
                <path d="M202.346 165.278L57.4453 205.852L57.806 207.14L202.706 166.566L202.346 165.278Z" fill="#E6E6E6" />
                <path d="M347.896 338.447L336.275 341.256L319.908 297.769L337.06 293.622L347.896 338.447Z" fill="#A0616A" />
                <path
                    d="M316.226 357.869L316.147 357.544C315.226 353.716 315.861 349.678 317.913 346.318C319.965 342.957 323.267 340.547 327.093 339.618L349.831 334.121L353.399 348.883L316.226 357.869Z"
                    fill="#2F2E41"
                />
                <path d="M296.45 346.364L284.495 346.363L278.807 300.247L296.452 300.248L296.45 346.364Z" fill="#A0616A" />
                <path
                    d="M299.346 357.8L261.102 357.798V357.464C261.102 353.525 262.667 349.747 265.452 346.962C268.237 344.177 272.015 342.612 275.954 342.612H275.955L299.347 342.613L299.346 357.8Z"
                    fill="#2F2E41"
                />
                <path
                    d="M278.798 328.361L267.189 190.011L315.245 181.153L315.435 181.119L329.5 190.16L324.604 241.077L347.145 320.459L327.682 325.581L305.106 251.958L299.939 229.57L297.291 258.662L299.257 330.406L278.798 328.361Z"
                    fill="#2F2E41"
                />
                <path
                    d="M265.701 193.149L265.574 192.988L282.18 67.956L282.202 67.7922L282.319 67.675C282.563 67.4303 288.382 61.6809 294.366 61.6809C295.231 61.6809 296.054 61.659 296.843 61.639C301.422 61.5199 305.04 61.4247 309.339 65.7246C313.719 70.1045 328.011 86.0985 328.011 108.004C328.011 129.206 329.942 195.096 329.961 195.759L329.989 196.69L329.208 196.182C329.016 196.058 309.793 183.819 293.672 195.196C288.634 198.753 284.108 199.999 280.238 199.999C271.231 199.999 265.778 193.247 265.701 193.149Z"
                    fill="#0F5156"
                />
                <path
                    d="M303.312 51.3643C314.919 45.0191 319.185 30.4657 312.84 18.8582C306.494 7.25078 291.941 2.98488 280.334 9.33008C268.726 15.6753 264.46 30.2288 270.805 41.8362C277.151 53.4437 291.704 57.7095 303.312 51.3643Z"
                    fill="#A0616A"
                />
                <path
                    d="M255.157 220.019C256.29 219.141 257.217 218.025 257.872 216.75C258.527 215.475 258.895 214.071 258.949 212.639C259.004 211.206 258.743 209.779 258.186 208.458C257.629 207.137 256.789 205.954 255.726 204.993L269.328 172.908L251.518 176.186L241.331 206.152C239.835 208.045 239.085 210.422 239.223 212.832C239.362 215.242 240.38 217.517 242.084 219.226C243.788 220.936 246.06 221.961 248.47 222.107C250.879 222.253 253.258 221.51 255.157 220.019Z"
                    fill="#A0616A"
                />
                <path
                    d="M242.016 195.061L246.396 185.814C246.125 185.651 245.902 185.42 245.749 185.143C241.657 178.051 266.376 119.154 268.023 115.247C267.772 113.123 265.177 90.607 267.074 83.0176C269.308 74.0835 273.894 69.9197 282.409 69.095C291.8 68.2131 294.333 80.9652 294.357 81.0948L294.366 113.878L283.589 151.615L259.01 201.755L242.016 195.061Z"
                    fill="#0F5156"
                />
                <path
                    d="M294.885 39.3713C292.014 39.7448 289.848 36.8071 288.844 34.0914C287.839 31.3758 287.074 28.2142 284.577 26.7473C281.167 24.7431 276.803 27.1536 272.903 26.4923C268.498 25.7456 265.634 21.0777 265.41 16.6159C265.185 12.1541 266.961 7.86293 268.703 3.74925L269.311 8.86162C269.23 7.12891 269.595 5.4043 270.37 3.85278C271.146 2.30125 272.307 0.974696 273.742 0L272.958 7.50162C273.449 3.29903 277.977 0.0425814 282.117 0.91349L281.994 5.38322C287.081 4.7783 292.213 4.17253 297.315 4.63339C302.418 5.09425 307.555 6.70859 311.423 10.0688C317.207 15.0953 319.32 23.3718 318.611 31.0023C317.901 38.6327 314.751 45.8009 311.469 52.7255C310.643 54.4677 309.5 56.4337 307.585 56.6556C305.864 56.855 304.289 55.4165 303.754 53.7687C303.219 52.121 303.48 50.3309 303.801 48.6283C304.285 46.0564 304.895 43.4275 304.44 40.8503C303.985 38.2731 302.131 35.7263 299.533 35.4105C296.935 35.0946 294.277 38.0642 295.526 40.364L294.885 39.3713Z"
                    fill="#2F2E41"
                />
                <path d="M399.745 357.854H227.377V356.446H400L399.745 357.854Z" fill="#3F3D56" />
            </g>
            <defs>
                <clipPath id="clip0_1999_12474">
                    <rect width="400" height="357.869" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const RecipientIllustrationSmall = () => (
    <svg width="151" height="135" viewBox="0 0 151 135" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3933_22658)">
            <path
                d="M1.34049 30.1134C1.04677 30.196 0.797803 30.3918 0.648216 30.6577C0.498629 30.9236 0.460632 31.2381 0.54256 31.532L19.7822 100.243C19.8648 100.537 20.0605 100.786 20.3265 100.935C20.5924 101.085 20.9068 101.123 21.2007 101.041L92.1277 81.1806C92.4214 81.098 92.6704 80.9022 92.82 80.6363C92.9696 80.3704 93.0076 80.056 92.9256 79.762L73.686 11.0512C73.6034 10.7575 73.4077 10.5085 73.1417 10.3589C72.8758 10.2093 72.5614 10.1713 72.2675 10.2533L1.34049 30.1134Z"
                fill="#E6E6E6"
            />
            <path d="M22.0921 97.7964L89.681 78.8709L71.3761 13.498L3.78711 32.4235L22.0921 97.7964Z" fill="white" />
            <path
                d="M12.7233 41.1518C12.2723 41.2781 12.0864 42.0271 12.3088 42.8215C12.5313 43.6159 13.0791 44.1595 13.5301 44.0332L44.3564 35.4016C44.8074 35.2753 44.9933 34.5262 44.7708 33.7319C44.5484 32.9375 44.0005 32.3939 43.5496 32.5202L12.7233 41.1518Z"
                fill="#E6E6E6"
            />
            <path
                d="M15.2059 50.019C14.755 50.1453 14.5691 50.8943 14.7915 51.6887C15.0139 52.4831 15.5618 53.0267 16.0128 52.9004L46.8391 44.2688C47.29 44.1425 47.4759 43.3934 47.2535 42.5991C47.0311 41.8047 46.4832 41.2611 46.0323 41.3874L15.2059 50.019Z"
                fill="#E6E6E6"
            />
            <path
                d="M17.6598 58.7827C17.2089 58.9089 17.0229 59.658 17.2454 60.4524C17.4678 61.2467 18.0157 61.7903 18.4666 61.6641L49.2929 53.0324C49.7439 52.9062 49.9298 52.1571 49.7074 51.3627C49.4849 50.5684 48.9371 50.0248 48.4861 50.151L17.6598 58.7827Z"
                fill="#E6E6E6"
            />
            <path
                d="M20.1422 67.6479C19.6913 67.7742 19.5054 68.5232 19.7278 69.3176C19.9502 70.112 20.4981 70.6556 20.949 70.5293L51.7754 61.8977C52.2263 61.7714 52.4122 61.0223 52.1898 60.228C51.9674 59.4336 51.4195 58.89 50.9685 59.0163L20.1422 67.6479Z"
                fill="#E6E6E6"
            />
            <path
                d="M59.1937 28.2433C59.0043 28.2961 58.8272 28.3857 58.6725 28.507C58.5177 28.6283 58.3884 28.7789 58.2919 28.9502C58.1954 29.1215 58.1337 29.3102 58.1101 29.5054C58.0866 29.7006 58.1018 29.8985 58.1548 30.0879C58.2078 30.2772 58.2976 30.4542 58.4191 30.6088C58.5406 30.7634 58.6913 30.8926 58.8627 30.9889C59.0342 31.0851 59.2229 31.1467 59.4181 31.17C59.6134 31.1933 59.8113 31.1779 60.0005 31.1247L65.7634 29.5111C66.1455 29.4041 66.4694 29.1497 66.6639 28.8038C66.8585 28.458 66.9077 28.049 66.8007 27.6669C66.6937 27.2848 66.4393 26.9609 66.0934 26.7664C65.7476 26.5718 65.3386 26.5226 64.9565 26.6296L59.1937 28.2433Z"
                fill="#E6E6E6"
            />
            <path
                d="M61.6281 36.9366C61.4387 36.9894 61.2615 37.079 61.1068 37.2003C60.9521 37.3217 60.8227 37.4723 60.7262 37.6436C60.6298 37.8149 60.568 38.0035 60.5444 38.1987C60.5209 38.394 60.5361 38.5919 60.5891 38.7812C60.6421 38.9706 60.7319 39.1476 60.8534 39.3022C60.9749 39.4568 61.1257 39.5859 61.2971 39.6822C61.4685 39.7785 61.6572 39.8401 61.8525 39.8634C62.0477 39.8867 62.2456 39.8713 62.4349 39.818L68.1977 38.2044C68.5798 38.0974 68.9037 37.843 69.0983 37.4972C69.2928 37.1513 69.342 36.7424 69.235 36.3603C69.128 35.9782 68.8736 35.6542 68.5278 35.4597C68.1819 35.2652 67.773 35.216 67.3909 35.323L61.6281 36.9366Z"
                fill="#E6E6E6"
            />
            <path
                d="M64.0624 45.63C63.8732 45.683 63.6963 45.7727 63.5418 45.894C63.3873 46.0154 63.2582 46.166 63.1618 46.3372C63.0655 46.5085 63.0039 46.697 62.9804 46.8921C62.957 47.0871 62.9722 47.2849 63.0251 47.4741C63.0781 47.6633 63.1678 47.8402 63.2892 47.9947C63.4105 48.1493 63.5611 48.2784 63.7324 48.3747C63.9036 48.471 64.0921 48.5327 64.2872 48.5561C64.4823 48.5796 64.6801 48.5644 64.8692 48.5114L70.6321 46.8978C71.0142 46.7908 71.3381 46.5364 71.5326 46.1905C71.7272 45.8447 71.7764 45.4358 71.6694 45.0537C71.5624 44.6716 71.308 44.3476 70.9621 44.1531C70.6163 43.9585 70.2073 43.9094 69.8252 44.0164L64.0624 45.63Z"
                fill="#E6E6E6"
            />
            <path
                d="M66.4968 54.3248C66.1151 54.4322 65.7917 54.6867 65.5975 55.0324C65.4033 55.3781 65.3543 55.7867 65.4612 56.1685C65.5681 56.5503 65.8222 56.8741 66.1677 57.0687C66.5131 57.2632 66.9217 57.3127 67.3036 57.2063L73.0665 55.5926C73.4482 55.4853 73.7716 55.2308 73.9658 54.8851C74.16 54.5394 74.209 54.1308 74.1021 53.749C73.9952 53.3671 73.7411 53.0434 73.3956 52.8488C73.0502 52.6542 72.6416 52.6047 72.2596 52.7112L66.4968 54.3248Z"
                fill="#E6E6E6"
            />
            <path
                d="M68.8227 69.3943C68.5813 69.4616 68.3556 69.5758 68.1585 69.7303C67.9613 69.8849 67.7965 70.0768 67.6736 70.2951C67.5506 70.5134 67.4719 70.7538 67.4419 71.0025C67.4119 71.2512 67.4313 71.5034 67.4988 71.7447C67.5664 71.9859 67.6808 72.2115 67.8356 72.4085C67.9904 72.6055 68.1825 72.7701 68.4009 72.8928C68.6194 73.0155 68.8598 73.0939 69.1086 73.1236C69.3574 73.1533 69.6095 73.1337 69.8507 73.0658L77.1938 71.0097C77.6807 70.8734 78.0934 70.5492 78.3413 70.1086C78.5892 69.6679 78.6519 69.1468 78.5155 68.6599C78.3792 68.1731 78.055 67.7603 77.6144 67.5124C77.1737 67.2645 76.6526 67.2019 76.1657 67.3382L68.8227 69.3943Z"
                fill="#0F5156"
            />
            <path d="M76.3799 61.9803L22.0422 77.1953L22.1775 77.6783L76.5151 62.4633L76.3799 61.9803Z" fill="#E6E6E6" />
            <path d="M130.961 126.917L126.603 127.97L120.466 111.662L126.898 110.107L130.961 126.917Z" fill="#A0616A" />
            <path
                d="M119.085 134.201L119.055 134.079C118.71 132.643 118.948 131.129 119.717 129.869C120.487 128.608 121.725 127.705 123.16 127.356L131.686 125.295L133.025 130.831L119.085 134.201Z"
                fill="#2F2E41"
            />
            <path d="M111.669 129.885L107.186 129.885L105.053 112.592L111.67 112.592L111.669 129.885Z" fill="#A0616A" />
            <path
                d="M112.755 134.176L98.4133 134.175V134.05C98.4134 132.573 99.0002 131.156 100.045 130.112C101.089 129.067 102.506 128.48 103.983 128.48H103.983L112.755 128.481L112.755 134.176Z"
                fill="#2F2E41"
            />
            <path
                d="M105.049 123.136L100.696 71.2545L118.717 67.9328L118.788 67.9199L124.062 71.3106L122.226 90.4041L130.679 120.172L123.381 122.093L114.914 94.4848L112.977 86.0892L111.984 96.9986L112.721 123.903L105.049 123.136Z"
                fill="#2F2E41"
            />
            <path
                d="M100.138 72.4318L100.09 72.3714L106.317 25.4845L106.325 25.423L106.369 25.3791C106.461 25.2873 108.643 23.1313 110.887 23.1313C111.211 23.1313 111.52 23.1231 111.816 23.1156C113.533 23.0709 114.89 23.0352 116.502 24.6477C118.145 26.2902 123.504 32.2879 123.504 40.5024C123.504 48.4533 124.228 73.1621 124.235 73.4107L124.246 73.7596L123.953 73.5693C123.881 73.5228 116.672 68.9329 110.627 73.1996C108.738 74.5332 107.04 75.0007 105.589 75.0007C102.211 75.0007 100.167 72.4686 100.138 72.4318Z"
                fill="#0F5156"
            />
            <path
                d="M114.242 19.2611C118.595 16.8817 120.195 11.4241 117.815 7.07131C115.436 2.71852 109.978 1.11881 105.625 3.49826C101.273 5.87771 99.6728 11.3353 102.052 15.6881C104.432 20.0409 109.889 21.6406 114.242 19.2611Z"
                fill="#A0616A"
            />
            <path
                d="M96.1838 82.5066C96.6087 82.1773 96.9563 81.7588 97.202 81.2807C97.4477 80.8025 97.5856 80.2762 97.606 79.739C97.6263 79.2018 97.5286 78.6666 97.3198 78.1712C97.1109 77.6758 96.796 77.2322 96.3971 76.8717L101.498 64.8398L94.8192 66.0691L90.9992 77.3063C90.438 78.0164 90.1567 78.9078 90.2088 79.8115C90.2608 80.7151 90.6425 81.5683 91.2816 82.2093C91.9207 82.8503 92.7727 83.2347 93.6762 83.2895C94.5797 83.3443 95.4719 83.0657 96.1838 82.5066Z"
                fill="#A0616A"
            />
            <path
                d="M91.2556 73.1482L92.8982 69.6804C92.7967 69.6194 92.713 69.5326 92.6556 69.429C91.121 66.7694 100.391 44.6831 101.008 43.2181C100.914 42.4213 99.9412 33.9779 100.653 31.1319C101.49 27.7816 103.21 26.2202 106.403 25.9109C109.925 25.5802 110.875 30.3623 110.884 30.4108L110.887 42.7045L106.846 56.8561L97.6286 75.6586L91.2556 73.1482Z"
                fill="#0F5156"
            />
            <path
                d="M111.082 14.7642C110.005 14.9043 109.193 13.8027 108.817 12.7843C108.44 11.7659 108.153 10.5803 107.217 10.0302C105.938 9.27867 104.301 10.1826 102.839 9.93463C101.187 9.6546 100.113 7.90412 100.029 6.23096C99.9448 4.55781 100.611 2.9486 101.264 1.40597L101.492 3.32311C101.462 2.67334 101.598 2.02661 101.889 1.44479C102.18 0.862969 102.615 0.365511 103.153 0L102.859 2.81311C103.044 1.23713 104.742 0.015968 106.294 0.342559L106.248 2.01871C108.156 1.79186 110.08 1.5647 111.993 1.73752C113.907 1.91034 115.834 2.51572 117.284 3.77581C119.453 5.66072 120.245 8.76444 119.979 11.6258C119.713 14.4873 118.532 17.1753 117.301 19.772C116.991 20.4254 116.563 21.1626 115.845 21.2459C115.199 21.3206 114.609 20.7812 114.408 20.1633C114.208 19.5454 114.305 18.8741 114.426 18.2356C114.607 17.2711 114.836 16.2853 114.665 15.3189C114.495 14.3524 113.799 13.3974 112.825 13.2789C111.851 13.1605 110.854 14.2741 111.322 15.1365L111.082 14.7642Z"
                fill="#2F2E41"
            />
            <path d="M150.404 134.196H85.7661V133.668H150.5L150.404 134.196Z" fill="#3F3D56" />
        </g>
        <defs>
            <clipPath id="clip0_3933_22658">
                <rect width="150" height="134.201" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
);
