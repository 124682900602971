export const theme = {
    colors: {
        success: '#30AA6E',
        success20: '#CFFDDE',
        warning: '#FFBE5C',
        warning20: '#FFEBC6',
        error: '#E9033A',
        error20: '#FFD9E1',
        info: '#348BDC',
        info20: '#EBF1FF',
        purple: '#844DBB',
        text: '#4D4D4D',
        textSecondary: '#6A7789',
        textMuted: '#A1ACBC',
        disabled: '#E1E5EB',
        primary: '#0F5156',
        black: '#000000',
        white: '#FFFFFF',
        grey1: '#C3CED7',
        grey2: '#EEF0F2',
        grey3: '#EDECEC',
        grey4: '#FBFBFB',
    },
    shadow: {
        cardShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
};
