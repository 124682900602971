import * as React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';
import { VerticalSpacer } from '../Spacers';

const Container = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 14px;
    color: ${(props) => props.color};
    border-bottom: 1px solid ${theme.colors.text};
    background-color: transparent;
    cursor: pointer;

    &:hover {
        border-bottom: ${(props) => (props.color !== theme.colors.text ? `1px solid ${theme.colors.grey2}` : `1px solid ${theme.colors.black}`)};
        box-shadow: ${(props) => theme.shadow.cardShadow};
        color: ${(props) => (props.color !== theme.colors.text ? `1px solid ${theme.colors.grey2}` : `1px solid ${theme.colors.black}`)};
    }

    &:active {
        border-bottom: 1px solid ${theme.colors.black};
        color: black;
        box-shadow: none;
    }
`;

const TextLinkButton = ({ icon, onClick, title, color = theme.colors.text }) => {
    return (
        <Container onClick={onClick} color={color}>
            {title}
            <VerticalSpacer spacing={0.55} />
            {icon && icon}
        </Container>
    );
};

export default TextLinkButton;
