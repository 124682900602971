import * as React from 'react';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import TextField from '../atoms/inputs/TextField';
import AutocompleteSelect from '../atoms/inputs/AutocompleteSelect';
import Tooltip from './Tooltip';
import { InfoIcon } from '../atoms/icons/InfoIcon';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/Spacers';
import TextLinkButton from '../atoms/buttons/TextLinkButton';

const AutobookEditAccount = ({ value, options, onChangeValue, name, onClickReset, description, isEditing, resetValue }) => {
    const [accountValue, setAccountValue] = React.useState(value);

    React.useEffect(() => {
        setAccountValue(value);
    }, [value]);

    const onClickValue = (e) => {
        setAccountValue(e);
        onChangeValue(e);
    };

    const onClickResetValue = () => {
        setAccountValue(resetValue);
        onChangeValue(resetValue);
        onClickReset();
    };

    return (
        <div>
            <Row align="center">
                <Col lg={3} md={5} xs={3}>
                    <AutocompleteSelect
                        label="Konto"
                        options={options}
                        onChange={(e) => onClickValue(e)}
                        color="white"
                        disabled={!isEditing || options.length === 0}
                        value={accountValue}
                    />
                </Col>
                <Col lg={5} md={6} xs={8}>
                    <TextField label="Benämning" color="white" placeholder={name} disabled value={name} />
                </Col>
                <Visible xs>
                    <Tooltip text={description}>
                        <InfoIcon color="white" />
                    </Tooltip>
                </Visible>
                <Col lg={4}>
                    <Visible xs md>
                        <HorizontalSpacer spacing={0.5} />
                    </Visible>
                    <Row align="center" nogutter>
                        {isEditing && (
                            <div>
                                <TextLinkButton title="Återställ till grundinställningar" color="white" onClick={onClickResetValue} />
                                <VerticalSpacer spacing={1} />
                            </div>
                        )}
                        <Hidden xs>
                            <Tooltip text={description}>
                                <InfoIcon color="white" />
                            </Tooltip>
                        </Hidden>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default AutobookEditAccount;
