import * as React from 'react';
import { Hidden, Visible } from 'react-grid-system';
import styled from 'styled-components';
import Body from '../atoms/typography/Body';
import Title from '../atoms/typography/Title';
import { HorizontalSpacer } from '../atoms/Spacers';
import GradientButtonLight from '../atoms/buttons/GradientButtonLight';
import { MediaQuery } from '../helpers';
import GreenSmokeImage from '../img/loginBG.jpg';
import AutobookInfoModalContent from '../organisms/AutobookInfoModalContent';
import Subtitle from '../atoms/typography/Subtitle';

const BackgroundContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(${GreenSmokeImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 32px;
    box-sizing: border-box;
    border-radius: 12px;

    ${MediaQuery.XS} {
        padding: 16px;
    }
`;

const StyledTitle = styled(Title)`
    font-size: 60;
    color: white;

    ${MediaQuery.XS} {
        font-size: 24px;
    }
`;

const AutobookBanner = () => {
    const [modalOpen, setModalOpen] = React.useState(false);

    return (
        <>
            <BackgroundContainer>
                <Hidden xs>
                    <Subtitle color="#C7D6D4">Nyhet!</Subtitle>
                </Hidden>
                <Visible xs>
                    <Body color="#C7D6D4">
                        <b>Nyhet!</b>
                    </Body>
                    <HorizontalSpacer spacing={0.25} />
                </Visible>
                <StyledTitle>Automatisk Bokföring</StyledTitle>
                <HorizontalSpacer spacing={1} />
                <GradientButtonLight title="Läs mer" onClick={() => setModalOpen(true)} />
                <HorizontalSpacer spacing={2} />
                <Body color="white">Tillgängligt för dig med direktintegration mot Fortnox</Body>
            </BackgroundContainer>
            {modalOpen && <AutobookInfoModalContent open={modalOpen} onClose={() => setModalOpen(false)} />}
        </>
    );
};

export default AutobookBanner;
